<template>
  <div class="buy_content">
    <div class="castnft_title">
      <!-- <img src="../../assets/image/castnft_title.png" alt="" @click="$router.go(-1)"> -->
      <p>{{$t("lang.wodeNFT")}}</p>
    </div>
    <div class="nft_list_content">
      <div class="nft_list_item nft_list_item_title">
        <p>NAME</p>
        <p>NFT ID</p>
        <p>IPFS</p>
      </div>
      <div class="loading" v-if="loading">
        <modalLoading />
      </div>
      <div v-else>
        <div style="min-height: 350px">
          <div class="nft_list_item nft_list_item_content" v-for="(item,index) in nftList" :key="index">
            <p>NEZHA</p>
            <p>#{{item.id}}</p>
            <p><span style="width: 55px;display:block;margin:0 auto">{{item.IPFS}}</span></p>
          </div>
        </div>
        <a class="openseaBtn" href="https://opensea.io/account" target="_blank" v-show="!!nftList.length">OpenSea</a>
      </div>
    </div>
    <!-- modal loading -->
    
    <!-- nft get success -->
    
  </div>
</template>
<script>

import modalLoading from "../../components/loadingIcon.vue";
import axios from 'axios';
// 交易hash
export default {
  name: "detail",
  components: {
    modalLoading
  },
  data() {
    return {
      getNFTModal: false,
      showModal: false,
      modalTitle: '',
      modalType: 1,
      nftList: [],
      loading: false
    };
  },
  computed: {
    
  },
  watch: {
    '$store.state.NFTList'() {
      this.getList();
    }
  },
  methods: {
    async getList() {
      this.loading = true;
      const { NFT_contract } = this.$store.state;
      const list = this.$store.state.NFTList;
      const result = []
      try {
        if(list.length) {
          for(let i = 0; i<list.length ; i++) {
            const imgData  = await NFT_contract.methods.tokenURI(list[i]).call();
            const jsonRes = await axios.get(imgData);
            result.push({ IPFS: jsonRes.data.image, id: list[i]})
          }
        }
      } catch (error) {
        
      }
      this.loading = false;
      this.nftList = result;
    }
  },
  mounted() {
    this.getList()
  }
};
</script>

<style scoped >
  .buy_content {
    width: 100%;
    background: url('../../assets/image/dropnftbg_phone.png') no-repeat;
    background-color: rgba(255, 255, 255, 0.2);
    min-height: 100vh;
    background-position-y: 30px;
    background-size: 100% auto;
    border-radius: 10px;
    padding: 22px 13px;
    box-sizing: border-box;
    margin-top: 20px;
  }
  .castnft_title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  /* .castnft_title img {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    cursor: pointer;
  } */
  .castnft_title p {
    font-size: 17px;
    font-weight: bold;
    color: #26493E;
    cursor: pointer;
  }

  .nft_list_content {
    margin-top: 50px;
    width: 100%;
    min-height: 500px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 25px 11px;
  }
  .nft_list_item {
    display: flex;
    align-items: center; 
  }
  .nft_list_item p {
    flex: 1;
    text-align: center;
    word-break: break-all;
  }
  .nft_list_item_title {
    font-size: 12px;
    font-weight: 800;
    color: #000000;
  }
  .nft_list_item_content {
    width: 100%;
    min-height: 100px;
    border-bottom: 1px solid #FFFFFF;
    font-size: 12px;
    font-weight: 600;
    color: #26493E;
    padding: 20px 0;
  }

  .openseaBtn {
    display: block;
    width: 254px;
    height: 48px;
    background: #4A4A4A;
    box-shadow: 0px 0px 23px 0px rgba(111,202,160,0.5);
    border-radius: 63px;
    line-height: 48px;
    text-align: center;
    font-size: 19px;
    font-weight: bold;
    color: #FFFFFF;
    margin: auto;
    margin-top: 20px;
    cursor: pointer;
  }
  
  
</style>
<style>
.get_nft_modal {
  width: 100%;
  min-height: 13rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2.3rem;
}
.get_nft_modal p {
  font-size: 1rem;
  color: #8B8B8B;
  text-align: left;
  padding-left: 2rem;
  margin-bottom: 5px;
}
.get_nft_modal div {
  width: 100%;
  height: 3rem;
  background: #6FCAA0;
  border-radius: 8px;
  text-align: center;
  line-height: 3rem;
  color: #FFFFFF;
  font-size: 0.8rem;
  cursor: pointer;
  margin-top: 4rem;
}

</style>