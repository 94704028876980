<template>
  <div class="progressBg" ref="allProgress" :style="proStyle">
    <div class="progressValue" :style="{'width':`${valueWidth}px`}"></div>
  </div>
</template>

<script>
export default {
  name: "okprogress",
  props: [ 'value', 'proStyle'],
  data() {
    return {
      valueWidth: 0
    }
  },
  watch: {
    value() {
      this.valueWidth = this.percentValue()
    } 
  },
  methods: {
    percentValue() {
      const targetWidth = (this.$refs.allProgress&&this.$refs.allProgress.offsetWidth);
      // // console.log(this.$refs)
      const data = this.value*1;
      const value = targetWidth * ((data>100?100:data)/100)
      if(value>0 && value < 1) {
        return this.$refs.allProgress.offsetWidth * 0.01
      }
      
      return value
    }
  },
  mounted() {
    this.valueWidth = this.percentValue();
    //this.cutDownFn(this.$props.time);
  },
  
};
</script>

<style>
.progressBg {
  width: 100%;
  background: #242426;
  border-radius: 16px;
  position: relative;
}
.progressValue {
  background: #4F8AE7;
  height: 100%;
  border-radius: 16px;
  position: absolute;
  left: 0;
  top: 0;
}
</style>
