<template>
  <div class="pcContainer">

    <div class="header">
      <img src="./../../assets/image/logo.png" alt="logo" class="header_left" @click="$router.replace('/home')">
      <div class="header_mid">
        <p :class="tab==1 && 'choose'" @click="clickTab(1)">{{$t("lang.shouye")}}</p>
        <p :class="tab==2 && 'choose'" @click="clickTab(2)">{{$t("lang.rengou")}}</p>
        <p :class="tab==3 && 'choose'" @click="clickTab(3)">{{$t("lang.zhiya")}}</p>
        <p :class="tab==4 && 'choose'" @click="clickTab(4)">NFT</p>
        <p><a href="https://uniswap.la/" target="_blank">Uniswap</a></p>
      </div>
      <div class="header_right">
        <div class="lang_view">
          <div class="lang_btn_view"><img src="./../../assets/image/lang.png" alt="lang" class="lang_btn" @click="showLang = !showLang"></div>
          <div class="lang_select animated bounceOutRight" v-if="showLang">
            <div class="lang_select_item" v-for="(item,index) in langList" :key="index" @click="changeLang(item)">
              <p :style="item.value === langSelectValue && {fontWeight:'bold'}">{{item.name}}</p>
              <img src="./../../assets/image/ok.png" alt="ok" v-show="item.value === langSelectValue">
            </div>
          </div>
          <!-- lang 遮罩层 -->
          <div class="lang_select_bg" v-if="showLang" @click="showLang=false"></div>
        </div>
        <div class="wallet_view">
          <!--  -->
          <div class="link_btn" v-if="!$store.state.userAddress" @click="openDialog">
            <img src="./../../assets/image/wallet_icon.png" alt="link_wallet" style="margin-right:0.5rem;width:1rem" v-if="!connecting">
            <p>{{ connecting ? 'loading' :$t("lang.lianjieqianbao")}}</p>
          </div>
          <p class="address" v-else @click="openDraw">
            {{$store.state.userAddress.slice(0,4)}}...{{$store.state.userAddress.slice(-4)}}
            
          </p>
          <span class="copy" v-if="$store.state.userAddress" @click="openInvite"></span>
        </div>
      </div>
    </div>

    <div class="routerView">
      <router-view @openLinkWallet="openDialog"/>  
    </div>

    <div class="footer_view">
      <div class="social_view" :style="![1,2,3].includes(tab) && {background: 'none'}">
        <p class="footer_title">{{$t("lang.shequn")}}</p>
        <div class="footer_list">
          <a class="footer_list_item" href="https://twitter.com/umiDAOHQ" target="_blank">
            <img src="./../../assets/image/twitter.png" alt="">
            <p>Twitter</p>
          </a>
          <a class="footer_list_item" href="https://github.com/umidao/" target="_blank">
            <img src="./../../assets/image/githup.png" alt="">
            <p>Github</p>
          </a>
          <a class="footer_list_item" href="https://discord.gg/gfug4sTDqB" target="_blank">
            <img src="./../../assets/image/discord.png" alt="">
            <p>Discord</p>
          </a>
          <a class="footer_list_item" href="https://umidao.medium.com" target="_blank">
            <img src="./../../assets/image/face.png" alt="">
            <p>Medium</p>
          </a>
          <a class="footer_list_item" href="https://doc.umidao.com" target="_blank">
            <img src="./../../assets/image/doc.png" alt="">
            <p>Docs</p>
          </a>
        </div>
        <p class="footer_copyright">© 2022 umidao.com All rights reserved.</p>
      </div>
    </div>
    <!-- 链接钱包弹窗 -->
    <div v-show="showConnectWallet" class="connectWalletBg" @click="closeDialog">
      <div class="connect_wallet_view">
        <div class="connect_wallet_view_item">
          <img src="./../../assets/image/fox.png" alt="" @click="linkWallet('MetaMask')">
          <p>MetaMask</p>
          <p>{{$t("lang.lianjieMetamask")}}</p>
        </div>
        <div class="connect_wallet_view_item" @click="linkWallet('WalletConnect')">
          <img src="./../../assets/image/wallet.png" alt="">
          <p>WalletConnect</p>
          <p>{{$t("lang.lianjieWalletConnect")}}</p>
        </div>
      </div>
    </div>
    <!-- 钱包信息弹窗 -->
    <div class="wallet_info_draw" v-show="showDraw">
      <div class="draw_view">
        <p class="draw_title">{{$t("lang.wodeqianbao")}}</p>
        <!-- <p class="draw_dollar">$0.000</p> -->
        <div class="draw_coin">
          <div class="draw_coin_icon"><img src="./../../assets/image/coin_UMI.png" alt=""></div>
          <p class="draw_coin_name">UMI</p>
          <div class="draw_coin_value">
            <p>{{($store.state.balance.UMIBalance*1).toFixed(3) || '0.000'}}</p>
            <!-- <p>${{($store.state.balance.UMIBalance * $store.state.UMI_USDC).toFixed(3)}}</p> -->
          </div>
          <img src="./../../assets/image/fox.png" class="add_wallet" alt="fox" @click="addCoin('umi')">
        </div>
        <div class="draw_coin">
          <div class="draw_coin_icon"><img src="./../../assets/image/coin_USDC.png" alt=""></div>
          <p class="draw_coin_name">USDC</p>
          <div class="draw_coin_value">
            <p>{{($store.state.balance.USDCBalance*1).toFixed(3) || '0.000'}}</p>
            <!-- <p>${{($store.state.balance.USDCBalance*1).toFixed(3)}}</p> -->
          </div>
          <div class="add_wallet"></div>
        </div>
        <div class="draw_coin">
          <div class="draw_coin_icon"><img src="./../../assets/image/coin_ETH.png" alt=""></div>
          <p class="draw_coin_name">ETH</p>
          <div class="draw_coin_value">
            <p>{{($store.state.balance.ETHBalance*1).toFixed(3) || '0.000'}}</p>
            <!-- <p>${{($store.state.balance.ETHBalance && $store.state.USDC_ETH) ? ($store.state.balance.ETHBalance / $store.state.USDC_ETH).toFixed(3): '0.000'}}</p> -->
          </div>
          <div class="add_wallet"></div>
        </div>
        <div class="draw_coin">
          <div class="draw_coin_icon"><img src="./../../assets/image/coin_LP.png" alt=""></div>
          <p class="draw_coin_name">UMI-USDC LP</p>
          <div class="draw_coin_value">
            <p>{{$store.state.balance.LPBalacnce || '0'}} LP</p>
            <p></p>
          </div>
          <div class="add_wallet"></div>
        </div>
        <div class="my_nft_btn" @click="goNft">
          <p>{{$t("lang.wodeNFT")}}</p>
          <img src="./../../assets/image/right_white.png" alt="right">
        </div>
        <div style="height:5rem"></div>
      </div>
      <!-- close -->
      <div class="close_draw" @click="closeDraw">
        <img src="./../../assets/image/close.png" alt="">
      </div>
      <!-- disconnect -->
      <div class="draw_disconnect" @click="disconnect">
        {{$t("lang.duankailianjie")}}
      </div>
    </div>
    
      <PcModal v-if="modalStatus" @closeModal="closeModal" :title="modalTitle">
      <!-- <p>Switch to the Ethereum NetWork</p> -->
        <div slot="content" v-if="modalType==1">
          <p class="switchNetwork_text">{{$t("lang.qingqiehuanwang")}}</p>
          <p class="switchNetwork_btn" @click="changeNetwork">{{$t("lang.qiehuanwangluo")}}</p>
        </div>
        <div slot="content" v-if="modalType==2">
          <p class="switchNetwork_text">连接请求被拒绝</p>
          <p class="switchNetwork_btn" @click="closeModal">关闭</p>
        </div>

        <div slot="content" v-if="modalType==3">
          <div class="copyContent">
            <span>https://umidao.com/#/home?from={{this.dealAddr()}}</span>
            <img src="../../assets/image/copybtn.png" alt="" @click="copyUrl">
          </div>
          <p class="switchNetwork_btn" @click="copyUrl">{{$t("lang.fuzhi")}}</p>
        </div>

      </PcModal>
  </div>
</template>

<script>
import API from "@/assets/js/API";
import { Message} from 'element-ui';
import Vue from 'vue';
import Web3 from "web3";
import PcModal from "./../../components/PcModal.vue";
// import Web3 from "web3";
// import WalletConnect from "@walletconnect/client";
// import QRCodeModal from "@walletconnect/qrcode-modal";
export default {
  name: "index",
  components: {
    PcModal
  },
  data() {
    return {
      tab: 1, // 控制顶部导航选中状态
      showConnectWallet: false, // 连接钱包弹窗
      showDraw: false, // 右侧个人中心
      // 钱包地址
      address: '',
      langList: [
        {name: 'English', value: 'en'},
        {name: 'Dansk', value: 'dm'},
        {name: 'Español', value: 'xby'},
        {name: 'Italiano', value: 'ydl'},
        {name: '简体中文', value: 'zh'},
        {name: '日本语', value: 'jp'},
        {name: '한국어', value: 'ko'},
        {name: 'Português', value: 'pty'},
        {name: 'Pусский', value: 'e'},
      ],
      langSelectValue: '', // 语言选择的值
      showLang: false, // 语言显示
      modalStatus: false, // 弹窗显示
      modalType: 1, // 弹窗内容类型
      modalTitle: '', // 弹窗标题
      connecting: false, // 连接钱包loading
      footerBg: true,
    }
  },
  computed: {
    dealAddress() {
      return `${this.address.slice(0,4)}...${this.address.slice(-4)}`
    }
  },
  watch: {
    showLang(val){
      if(val) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    },
    showDraw(val) {
      if(val) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    },
    "$route.path"() {
      const path = this.$route.path;
      if(path == '/home') {
        this.tab = 1;
      }
      if(path == '/purchase') {
        this.tab = 2;
      }
      if(path == '/state') {
        this.tab = 3;
      }
      if(path == '/nft') {
        this.tab = 4;
        this.footerBg = false;
      }
    }
  },
  methods: {
    dealAddr() {
      const addr = this.$store.state.userAddress;
      const str = addr.slice(2,addr.length)
      if(!addr) {
        return ''
      } 
      return '0x'+str.slice(0,4)+str.slice(-4)
    },
    openInvite() {
      if(!this.$store.state.userAddress) {
        this.$emit('openLinkWallet');
        return 
      }
      this.modalTitle = this.$t("lang.yaoqinglianjie")
      this.modalStatus = true;
      this.modalType = 3;
    },
    copyUrl() {
      const Url2 = `https://umidao.com/#/home?from=${this.dealAddr()}`
      var input = document.createElement('input'); // 创建input标签，只有input标签选中可以
      input.setAttribute('id', 'copyInput'); // 给input一个id属性
      input.setAttribute('value', Url2); // 给input一个value属性，属性值是变量span的文本
      document.getElementsByTagName('body')[0].appendChild(input); // body里面添加input实体
      document.getElementById('copyInput').select(); // 使用js去通过id找到并执行input实体的全部选中
      document.execCommand("Copy"); //原生copy方法执行浏览器复制命令
      document.getElementById('copyInput').remove(); // 为避免下次页面添加copy方法 所以这里copy完之后要移除input实体
      this.$message({
      type: 'success',
      message: this.$t("lang.fuzhichenggong")
      })
    },

    closeModal() {
      this.modalTitle = '';
      this.modalStatus = false;
    },
    changeNetwork() {
      this.$utils.switchNetwork(this.$api.chainId)
    },
    addCoin(type) {
      this.$utils.addUMIUSDC(type);

    },
    goNft() {
      this.tab = 0
      this.$router.push('/mynft');
      this.closeDraw();
    },
    clickTab(val) {
      this.tab = val;
      this.closeDraw(); // 关闭 user draw
      if(val==1) {
        this.$router.replace('/home')
      } else if(val==2) {
        this.$router.replace('/purchase')
      } else if(val==3) {
        this.$router.replace('/stake')
      } else if(val== 4) {
        this.$router.replace('/nft')
        this.footerBg = false;
      }
    },
    closeDialog(e) {
      if(e.target.className == 'connectWalletBg') {
        this.showConnectWallet = false;
      }
    },
    openDialog() {
      this.showConnectWallet = true;
    },
    openDraw() {
      this.showDraw = true;
    },
    closeDraw() {
      this.showDraw = false;
    },
    disconnect() {
      this.showConnectWallet = false;
      this.address = '';
      sessionStorage.clear();
      this.$store.commit('setUseraddress',null);
      this.closeDraw();
    },
    linkWallet(type) {
      if(type == "MetaMask") {
        if(!window.ethereum) {
          // 弹窗提示 安装 钱包
          // this.$alert("No MateMask,Please Install It!")
          return 
        }
      }
      this.connecting = true;
      this.$utils.linkWallet({
        type,
        successFn: async (data)=>{
          // this.address = data[0];
          this.showConnectWallet = false;
          const web3Object = new Web3(window.ethereum);
          const chainId = await web3Object.eth.getChainId();
          this.connecting = false;
          if(chainId != this.$api.chainId) {
            this.showConnectWallet = false;
            
            if(type=='MetaMask') {
              this.modalType = 1;
              this.modalTitle = this.$t("lang.wanluocuowu");
              this.modalStatus = true;
            } else {
              this.$alert('Please switch wallet network', 'Network Error!');
            }
            return 
          }
          Vue.prototype.web3js = web3Object;
          this.showConnectWallet = false;
          sessionStorage.setItem('walletType',type);
          const params = {
            web3js: web3Object,
            userAddr: data[0]
          }
          this.$store.commit('setUseraddress',data[0])
          this.$store.dispatch("initContartInfo", params)
          // sessionStorage.getItem('',)
        },
        errorFn: ()=>{
          // Message
          this.showConnectWallet = false;
          this.modalType = 2;
          this.modalTitle = "连接失败";
          this.modalStatus = true;
          this.connecting = false;
        }
      });
    },
    
    changeLang(item) {
      this.langSelectValue = item.value;
      this.$i18n.locale = item.value;
      this.showLang = false;
    }
  },
  mounted() {
    const walletType = sessionStorage.getItem('walletType');
    if(walletType) {
      this.linkWallet(walletType);
    }
    const routeName = this.$route.name;
    if(routeName.includes('purchase')) {
      this.footerBg = true;
      this.tab = 2;
    } else if(routeName.includes('stake')) {
      this.footerBg = true;
      this.tab = 3;
    } else if (routeName.includes('mynft')) {
      this.tab = 0
    } else if (routeName.includes('nft')) {
      this.tab = 4; 
    } else {
      this.footerBg = true;
      this.tab = 1;
    }
    // this.linkWallet();
  },
  created() {
    this.langSelectValue = this.$i18n.locale;
  }
  
};
</script>

<style scoped >
.pcContainer {
  background: linear-gradient(180deg, #69C89D 0%, #FFFFFF 100%);
  min-height: 100vh;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 6.45rem;
  padding: 0 2.5rem;
  box-sizing: border-box;
  padding-bottom: 1rem;
}
.header_left {
  width: 6rem;
  cursor: pointer;
}
.header_mid {
  display: flex;
  align-items: center;
  
  box-sizing: border-box;
}
.header_mid p {
  text-align: center;
  font-size: 1rem;
  color: #26493E;
  margin-right: 5.6rem;
  cursor: pointer;
  position: relative;
}
.header_mid a {
  text-align: center;
  font-size: 1rem;
  color: #26493E;
  cursor: pointer;
  text-decoration: none;
}
.header_mid p:last-child {
  margin-right: 0;
}
.header_mid .choose {
  font-weight: 600;
  border-bottom: 2px solid #26493E;
}

.header_mid p::before,
.header_mid p::after {
    content: '';
    /* 将开始的宽度设置为0,这样才会展示出过渡效果 */
    width: 0;
    height: 2px;
    background-color: #26493E;
    /* 设置原本定位 */
    position: absolute;
    top: 100%;
    left: 50%;
    /* 设置过渡效果 */
    transition: all 0.8s;
}

.header_mid p:hover::before {
    /* 实现向左展开效果 */
    /* 鼠标移入改变宽度 */
    width: 50%;
    /* 鼠标移入改变定位位置 */
    left: 0px;
}
/* 同上 */
.header_mid p:hover::after {
    width: 50%;
    right: 0px;
}



.header_right {
  display: flex;
  align-items: center;
}
.lang_view {
  position: relative;
}
.lang_select_bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.4);
}
.lang_select {
  position: absolute;
  top: 3rem;
  right: 0px;
  z-index: 10;
  width: 9rem;
  background: #fff;
  border-radius: 5px;
  padding: 0.5rem 0;
  box-sizing: border-box;
}
.lang_select_item {
  width: 94%;
  min-height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 1rem;
  box-sizing: border-box;
  margin: 0 auto;
  margin-bottom: 0.5rem;
}
.lang_select_item:active {
  background: #f0f0f0;
}
.lang_select_item p {
  color: #26493E;
  font-size: 0.8rem;
  height: 100%;
  line-height: 1.5rem;
}
.lang_select_item:hover {
  
  background: #D8F3E6;
  border-radius: 2rem;
}
.lang_select_item img {
  flex-shrink: 0;
  width: 1.2rem;
}
.lang_btn_view {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.75rem;
  cursor: pointer;
}
.lang_btn_view:hover {
  background: rgba(0, 0, 0, 0.1);
}
.lang_btn {
  width: 1rem;
  height: 1rem;
  
}
.wallet_view {
  width: auto;
  height: 2.1rem;
  border-radius: 3.15rem;
  overflow: hidden;
  position: relative;
}
.link_btn {
  width: 100%;
  height: 100%;
  font-size: 1rem;
  color: #26493E;
  line-height: 2.1rem;
  background: #FFFFFF;
  color: #26493E;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 1.5rem;
}
.address {
  width: 100%;
  height: 100%;
  font-size: 1rem;
  color: #26493E;
  text-align: center;
  line-height: 2.1rem;
  background: #FFFFFF;
  cursor: pointer;
  padding: 0 1.5rem;
  position: relative;
  padding-right: 3rem;
}
.copy {
  position: absolute;
  right: 0;
  top: 0;
  width: 2.1rem;
  height: 2.1rem;
  background: url('../../assets/image/invite1.png') no-repeat center center / 0.8rem 1rem;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.copy:hover {
  background: url('../../assets/image/invite2.png') no-repeat center center / 0.8rem 1rem;
  background-color: #B4E3CD;
}

.routerView {
  padding: 0 2.5rem;
  box-sizing: border-box;
  width: 100%;
}
.footer_view {
  padding: 0 2rem;
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 8.45rem;
  margin-top: 2.5rem;
}
.social_view {
  width: 100%;
  height: 14rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5.7rem;
  box-sizing: border-box;
}
.footer_title {
  font-size: 1rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #26493E;
  margin-bottom: 1.75rem;
}
.footer_list {
  display: flex;
  margin-bottom: 2rem;
  align-items: center;
}
.footer_list_item {
  margin-right: 3.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}
.footer_list_item img {
  width: 1.65rem;
  height: 1.65rem;
  margin-bottom: 10px;
}
.footer_list_item p {
  font-size: 0.7rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9A9A9A;
  text-align: center;
}
.footer_copyright {
  font-size: 0.6rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9A9A9A;
}
.connectWalletBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}
.connect_wallet_view {
  width: 30rem;
  height: 20rem;
  background: #FFFFFF;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}
.connect_wallet_view_item {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}
.connect_wallet_view_item:first-child {
  border-bottom: 1px solid #E1EDE8;
}
.connect_wallet_view_item img {
  width: 3.75rem;
  height: 3.75rem;
  margin-bottom: 5px;
  cursor: pointer;
}
.connect_wallet_view_item p {
  font-size: 1.3rem;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #26493E;
}
.connect_wallet_view_item p:last-child {
  font-size: 1rem;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #93BCAF;
  margin-top: 9px;
}
.wallet_info_draw {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.draw_view {
  position: absolute;
  right: 0;
  top: 0;
  width: 30rem;
  height: 100%;
  background: #FFFFFF;
  padding: 0 2.4rem;
  padding-top: 3.3rem;
  padding-bottom: 3.5rem;
  box-sizing: border-box;
  overflow: auto;
}
.draw_title {
  font-size: 1rem;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #769991;
  margin-bottom: 3.6rem;
  padding-left: 0.6rem;
}
.draw_dollar {
  font-size: 1.8rem;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #26493E;
  margin-bottom: 1.5rem;
}
.draw_coin {
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
}
.draw_coin_icon {
  width: 3.35rem;
  height: 2rem;
  margin-right: 13px;
}
.draw_coin_icon img {
  height: 2rem;
  width: auto;
}
.draw_coin_name {
  text-align: left;
  flex: 1;
  font-size: 0.8rem;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #26493E;
}
.add_wallet {
  width: 1.2rem;
  height: 1.2rem;
  margin-left: 1rem;
  cursor: pointer;
}
.draw_coin_value {
  font-size: 0.8rem;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #26493E;
  text-align: right;
  line-height: 1rem;
}
.draw_coin_value p:first-child {
  color: #26493E;
}
.draw_coin_value p:last-child {
  color: #769991;
}

.draw_btn_view {
  display: flex;
  margin-bottom: 1.6rem;
}
.draw_btn_view div {
  flex: 1;
  height: 3rem;
  background: #6FCAA0;
  border-radius: 1.5rem;
  line-height: 3rem;
  text-align: center;
  font-size: 1rem;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #FFFFFF;
  cursor: pointer;
}
.draw_btn_view div:first-child {
  margin-right: 0.8rem;
}

.my_nft_btn {
  width: 100%;
  height: 4rem;
  background: url('./../../assets/image/btn_bg.png') no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.8rem;
  cursor: pointer;
}
.my_nft_btn p {
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
}
.my_nft_btn img {
  width: 0.9rem;
}

.close_draw {
  position: absolute;
  top: 3rem;
  right: 1.8rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
.close_draw img {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  margin: 5px;
}
.draw_disconnect {
  position: absolute;
  bottom: 1.95rem;
  right: 12.5rem;
  width: 5rem;
  height: 1.5rem;
  font-size: 1rem;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #9A9A9A;
  text-align: center;
  cursor: pointer;
}
.switchNetwork_text {
  font-size: 0.9rem;
  color: #333;
  text-align: left;
  margin-top: 2.4rem;
  margin-bottom: 4.3rem;
}
.switchNetwork_btn {
  width: 100%;
  height: 3rem;
  line-height: 3rem;
  background: #6FCAA0;
  border-radius: 1rem;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  margin: auto;
  cursor: pointer;
}
.switchNetwork_btn:hover {
  background: #8DDDB8;
}

.copyContent {
  width: 100%;
  height: 7.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  
}
.copyContent span {
  font-size: 0.8rem;
  color: #8B8B8B;
  font-weight: bold;
  text-align: left;
  padding: 0 0.5rem;
  word-break: break-all;
}
.copyContent img {
  width: 1rem;
  height: auto;
  cursor: pointer;
}
</style>
