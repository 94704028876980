<template>
  <div class="buy_content">
    <div class="buy_top">
      <p class="buy_top_title">{{$t("lang.rengouyibiao")}}</p>
      <div class="buy_top_my" @click="goDetail(1)">
        <p>{{$t("lang.woderengou")}}</p>
        <img src="./../../assets/image/right.png" alt="right">
      </div>
    </div>
    <div class="buy_sencond">
      <div class="buy_sencond_item">
        <p>{{$t("lang.rengouzongliang")}}</p>
        <p>2,000,000 UMI</p>
      </div>
      <div class="buy_sencond_item">
        <p>{{$t("lang.rengouzhekou")}}</p>
        <p>{{(discount*1).toFixed(2)}}%</p>
      </div>
      <div class="buy_sencond_item">
        <p>{{$t("lang.UMIjiage")}}</p>
        <p>${{($store.state.UMI_USDC * 1).toFixed(3)}}</p>
      </div>
    </div>
    <p class="progress">{{$t("lang.rengoujindu")}} <span>{{ (progressValue / 2000000).toFixed(2)}}%</span></p>
    <div class="progress_view" id="progress_fa">
      <div class="progress_value" :style="{width: `${progressWidth}px`}"></div>
      <p class="progress_total">{{$t("lang.shengyu")}} {{dealNumber((2000000 - progressValue).toFixed(3))}}</p>
    </div>
    <p class="rengouTitle">{{$t("lang.danrengouUMI")}}</p>
    <div class="rengouView">
      <div class="rengouInfo">
        <div class="rengouInfoItem">
          <p>2000</p>
          <p>{{$t("lang.qigoujine")}}</p>
        </div>
        <div class="rengouInfoItem">
          <p>9.00%</p>
          <p>{{$t("lang.shuocangshouyi")}}</p>
        </div>
        <div class="rengouInfoItem">
          <p>{{((discount * 1) + 9).toFixed(2) }}%</p>
          <p>ROI</p>
        </div>
        <div class="rengouInfoItem">
          <p>{{1}} NFT</p>
          <p>NEZHA NFT</p>
        </div>
      </div>
      <p class="rengouTips">{{$t("lang.shuoding30")}}</p>
      <div class="rengouBtn" @click="goDetail(2)">
        {{$t("lang.canyurengou")}}
        <img src="./../../assets/image/qurengou.png" alt="">
      </div>
    </div>

    <div class="buy_view" v-if="false">
      <div class="buy_card_item" style="margin-right:8.5rem;background:#E1EDE8">
        <p class="buy_item_type_title">{{$t("lang.zuLPrengou")}}</p>
        <p class="buy_item_type_text">{{$t("lang.shuoding7")}}</p>
        <div class="card_view">
          <div class="card_info">
            <p>{{$t("lang.rengouzhekou")}}</p>
            <p>{{discount || '--'}}%</p>
          </div>
          <div class="card_info">
            <p>{{$t("lang.shuocangshouyi")}}</p>
            <p>18.00%</p>
          </div>
          <div class="card_info">
            <p>ROI</p>
            <p>{{((discount * 1) + 18).toFixed(2) }}%</p>
          </div>
          <div class="card_info">
            <p>{{$t("lang.rengouzichan")}}</p>
            <p>USDC</p>
          </div>
          <p class="card_tips">{{$t("lang.zijinyongtu2")}}</p>
          <p class="card_money2">{{$t("lang.qigoujine")}} 5,000USDC</p>
          <div class="card_btn" @click="goDetail(1)">{{$t("lang.canyurengou")}}</div>
        </div>
      </div>
      
      <div class="buy_card_item">
        <div class="buy_card_item" style="background:#E1EDE8">
        <p class="buy_item_type_title">{{$t("lang.danrengouUMI")}}</p>
        <p class="buy_item_type_text">{{$t("lang.shuoding30")}}</p>
        <div class="card_view">
          <div class="card_info">
            <p>{{$t("lang.rengouzhekou")}}</p>
            <p>{{discount || '--'}}%</p>
          </div>
          <div class="card_info">
            <p>{{$t("lang.shuocangshouyi")}}</p>
            <p>9.00%</p>
          </div>
          <div class="card_info">
            <p>ROI</p>
            <p>{{((discount * 1) + 9).toFixed(2) }}%</p>
          </div>
          <div class="card_info">
            <p>{{$t("lang.rengouzichan")}}</p>
            <p>USDC</p>
          </div>
          <p class="card_tips">{{$t("lang.zijinyongtu1")}}</p>
          <p class="card_money2">{{$t("lang.qigoujine")}} 2,000USDC</p>
          <div class="card_btn" @click="goDetail(2)">{{$t("lang.canyurengou")}}</div>
        </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import utils from "@/utils/utils";
import { Notification,Message, Loading } from 'element-ui';
import H5Modal from '@/components/H5Modal'
import abi from './../../assets/js/XRC20abi'
export default {
  name: "detail",
  components: {
    H5Modal
  },
  data() {
    return {
      showModal: false,

      // 购买进度数量
      progressValue: 0,
      progressWidth: 0,
      discount: 10
    };
  },
  computed: {
    
  },
  watch: {
    "$store.state.DAO_contract"() {
      this.getProgress();
    }
  },
  methods: {
    dealNumber(val) {
      return utils.formatNum(val)
    },
    goDetail(val) {
      if(!this.$store.state.userAddress) {
        this.$emit('openLinkWallet');
        return 
      }
      this.$router.push({path:'/purchaseDetail',query: { type: val}});
    },
    async getProgress() {
      const { DAO_contract,userAddress } = this.$store.state;
      const faWidth = document.getElementById('progress_fa').clientWidth;
      const res = await DAO_contract.methods.totalBondAmount().call({from: userAddress});
      const result = utils.mathpow(res,this.$api.UMI_Demical);
      this.progressValue = result
      this.progressWidth = faWidth * (result / 2000000 );
    },
    async getProgress1() {
      const web3jsobj = new Web3(window.ethereum);
      const DAOContract = new web3jsobj.eth.Contract(abi.poolabi, this.$api.DAO_Addr, {});
      const faWidth = document.getElementById('progress_fa').clientWidth;
      const res = await DAOContract.methods.totalBondAmount().call();
      const result = utils.mathpow(res,this.$api.UMI_Demical);
      this.progressValue = result
      this.progressWidth = faWidth * (result / 2000000 );
    },
    
    // 获取折扣相关数据
    async getdiscountData() {
      const web3jsobj = new Web3(window.ethereum);
      const DAOContract = new web3jsobj.eth.Contract(abi.poolabi, this.$api.DAO_Addr, {});
      const lockInfo = await DAOContract.methods.lockinfo().call();
      const res  = await DAOContract.methods.getDaoDiscount().call();
      this.getDiscount(lockInfo[2],res);
    },
    getDiscount(start,res) {
      const time = new Date().getTime() - Number(start+'000');
      
      // <!-- 1-30天内折扣为 0.15；31-90天内折扣为0.10； 91天以后折扣为0.05； -->
      const day = time / 1000 / 3600 / 24;
      if(day < 30) {
        this.discount =  (100 - res[0]).toFixed(2);
      } else if (day > 90) {
        this.discount = (100 - res[2]).toFixed(2);
      } else {
        this.discount = (100 - res[1]).toFixed(2);
      }
    },
  },
  mounted() {
    const { userAddress, lockInfo, discount } = this.$store.state;
    if(userAddress) {
      this.getProgress();
      this.getDiscount(lockInfo.start,discount);
    } else {
      this.getProgress1();
      this.getdiscountData();
    }
  }
};
</script>

<style scoped >
  .buy_content {
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 0.5rem;
    padding: 4.8rem 6.75rem;
    box-sizing: border-box;
  }
  .buy_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4.3rem;
  }
  .buy_top_title {
    font-size: 1.5rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #26493E;
  }
  .buy_top_my {
    width: 10rem;
    height: 3rem;
    background: #D8F3E6;
    border-radius: 3.15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .buy_top_my p {
    font-size: 1rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #26493E;
  }
  .buy_top_my img {
    height: 0.8rem;
    margin-left: 0.5rem;
  }
  .buy_sencond {
    display: flex;
    align-items: center;
    margin-bottom: 4.4rem;
  }
  .buy_sencond_item {
    flex: 1;
  }
  .buy_sencond_item p:first-child {
    font-size: 1rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #9A9A9A;
    margin-bottom: 2rem;
    text-align: center;
  }
  .buy_sencond_item p:last-child {
    font-size: 1.5rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #26493E;
    text-align: center; 
  }
  .progress {
    text-align: left;
    font-size: 1rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #BDBDBD;
    margin-bottom: 1.05rem;
  }
  .progress span {
    color: #26493E;
  }
  .progress_view {
    width: 100%;
    height: 1.5rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 1.2rem 0px rgba(231,248,245,1);
    border-radius: 1.5rem;
    position: relative;
    margin-bottom: 3.85rem;
  }
  .progress_value {
    position: absolute;
    left: 0;
    top: 0;
    width: 10rem;
    height: 100%;
    background: #6FCAA0;
    border-radius: 1.5rem;
    font-size: 0.8rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 1.5rem;
    z-index: 1;
  }
  .progress_total {
    font-size: 0.8rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #9A9A9A;
    position: absolute;
    right: 1rem;
    top: 0;
    height: 1.5rem;
    line-height: 1.5rem;
    text-align: right;
    z-index: 2;
  }
  /* 新的样式 */
  .rengouTitle {
    font-size: 1.5rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #26493E;
    margin-top: 4.25rem;
    text-align: center;
    margin-bottom: 1.85rem;
  }
  .rengouView {
    width: 100%;
    min-height: 30rem;
    background: url('./../../assets/image/rengoubg.png') no-repeat;
    background-size: 100% 100%;
    padding-top: 6.8rem;
  }
  .rengouInfo {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 8.95rem;
  }
  .rengouInfoItem p:first-child {
    font-size: 1.5rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #26493E;
    margin-bottom: 0.85rem;
    text-align: center;
  }
  .rengouInfoItem p:last-child {
    font-size: 1rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #26493E;
    text-align: center;
  }
  .rengouTips {
    font-size: 0.7rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #26493E;
    margin-bottom: 1rem;
    text-align: center;
  }
  .rengouBtn {
    width: 15rem;
    height: 4rem;
    background: #6FCAA0;
    border-radius: 3.15rem;
    position: relative;
    line-height: 4rem;
    text-align: center;
    font-size: 1.25rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    cursor: pointer;
    margin: 0 auto;
  }
  .rengouBtn img {
    position: absolute;
    right: 0.85rem;
    top: 0.75rem;
    width: 2.5rem;
    height: 2.5rem;
  }
  .rengouBtn:hover {
    background: #8DDDB8;
  }




  .buy_view {
    display: flex;
    flex-wrap: nowrap;
  }
  .buy_card_item {
    flex: 1;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
  }
  .buy_item_type_title {
    font-size: 1.4rem;
    font-weight: 500;
    color: #26493E;
    text-align: center;
    margin-bottom: 5px;
    margin-top: 1.5rem;
  }
  .buy_item_type_text {
    font-size: 0.8rem;
    font-weight: 500;
    color: #9A9A9A;
    margin-bottom: 1.8rem;
    text-align: center;
  }
  .card_view {
    width: 100%;
    border-radius: 1rem;
    background: #FFFFFF;
    padding: 4.45rem 2.5rem 2.8rem 2.5rem;
    box-sizing: border-box;
    flex: 1;
  }
  .card_view:hover {
    box-shadow: 0 10px 12px 2px rgb(111 202 160 / 10%);
  }
  .card_info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.2rem;
  }
  .card_info p:first-child {
    font-size: 0.8rem;
    font-weight: 500;
    color: #9A9A9A;
  }
  .card_info p:LAST-child {
    font-size: 1rem;
    font-weight: 500;
    color: #26493E;
  }
  .card_tips {
    margin-top: 3rem;
    min-height: 7rem;
    font-size: 0.8rem;
    font-weight: 500;
    color: #769991;
    line-height: 1.5rem;
  }
  .card_money1 {
    font-size: 1rem;
    font-weight: 500;
    color: #26493E;
    text-align: center;
  }
  .card_money1 span {
    font-size: 2.5rem;
  }
  .card_money2 {
    font-size: 0.8rem;
    font-weight: 500;
    color: #26493E;
    margin-bottom: 1rem;
    text-align: center;
  }
  .card_btn {
    margin: auto;
    width: 10rem;
    height: 3rem;
    border-radius: 2rem;
    background: #6FCAA0;
    text-align: center;
    line-height: 3rem;
    font-size: 1rem;
    font-weight: 600;
    color: #FFFFFF;
    cursor: pointer;
  }
  .card_btn:hover {
    background: #8DDDB8;
  }
</style>