// 丹麦   key由 文字中的四个字的拼音组成
export const lang = {
  'shouye':'Forside',
  'rengou':'Indkøb',
  'zhiya':'Indsats',
  'lianjieqianbao':'Forbind tegnebog',
  'quanqiulingxian':'Verdens førende Crypto Native Decentraliseret selvstændig organisation DAO',
  'zhuanzhuyu':'Udnyttelse af fællesskabs- og kryptoteknologi til at opbygge DeFi, Web3 og Metaverse med henblik på at indhente industriens vækstdividende for fællesskabet.',
  'canyurengou':'Købe Nu',
  'daibifenpei':'Fordeling af værdikupon',
  'zongfaxingliang':'Samlet Udbud',
  'chubeijin':'Reservefond',
  'shequeDAO':'Fællesskab DAO',
  'liudongxingLP':'Likviditet LP',
  'tuandui':'Hold',
  'rengoujiangli':'Belønninger for Indkøb',
  'luxiantu':'Køreplan',
  'jiamijijin':'Pioner af Kryptofond',
  'umiCashchengli':'Maj, 2018 umiCash Etablering',
  'umiCashv1shangxian':'Maj, 2019 umiCash APP Lanceres',
  'umiCashzhishujijin':'Maj, 2020 umiCash Indeksfond Frigøre',
  'umiCashxiaomubiao':'Juli, 2021 umiCash Lille Mål Frigøre',
  'hunhedapan':'Sept, 2021 Hybride Markedsfond 10 Nettoværdi steg op til 10,0',
  'umiCashzuoshishangban':'Dec, 2021 umiCash Markedsmægler Version Lanceres',
  'umiDeFichanpinshangxian':'Q1 umiDeFi Produkter Frigøre',
  'umiDAOzhililuntan':'Q2 umiDAO Styring Forum Frigøre',
  'UMIDAOzhiliguifan':'UMIDAO Styringsstandard Lanceres',
  'UMIDAOweiyuanhui':'Valg af udvalg i UMIDAO',
  'umiCustodyjiamizhiguan':'umiCustody Forvaltning af Aktiver',
  'SatQuantDEXlianghua':'SatQuant Kvantitativ DEX',
  'UMIDAOshequqianbao':'UMIDAO Fællesskabets Tegnebog Multisig Fordeling',
  'umiCashProjiamilianghua':'umiCashPro Krypto Kvantisering',
  'FundDappjiamijijin':'Kryptofond DAPP',
  'shequn':'Fællesskab',
  'rengouyibiao':'Indkøb Instrumentbræt',
  'woderengou':'Mit Indkøb',
  'rengouzongliang':'Samlet Indkøb (Fase II)',
  'rengouzhekou':'Rabat',
  'shuocangshouyi':'Lockup Belønning',
  'zongshouyi':'Samlet ROI',
  'UMIjiage':'UMI Pris',
  'rengoujindu':'Fremskridtssats',
  'yiwancheng':'Afsluttet',
  'shengyu':'Tilbageværende',
  'danrengouUMI':'Enkelt UMI Indkøb',
  'shuoding30':'6-måneders lockup frigiver 40% efterfulgt af en hver måned lineær frigivelse 10%',
  'rengouzichan':'Finansiering Aktiver',
  'zijinyongtu1':"Anvendelse af Fonde: Fællesskabets reservepenge, som håndteres af Fællesskabets Tegnebog Multisig, tager 80% af indkøbsfonden, mens projektets driftsfond tager 20 %.",
  'qigoujine':'Mindste Indkøb',
  'zuLPrengou' :'Tilbyde LP Indkøb',
  'shuoding7':'6-måneders lockup frigiver 40% efterfulgt af en hver måned lineær frigivelse 10%',
  'zijinyongtu2':'Anvendelse af Fonde: Fællesskabets reservepenge tager 80 % af indkøbsfonden, mens projektets driftsfond tager 20 %.Resten af pengene bruges til at aggregere UMI-USDC LP og tilføre dem til uniswap puljen.',
  'lingquUMI':'Kræve UMI',
  'qigoumenkan':'Mindste Indkøb',
  'yirengou':'Indkøbt',
  'yilingqu':'Krævet beløb',
  'kelingqu':'Kan kræves Beløb',
  'daifafang':'Udestående beløb',
  'nianhuaAPR':'APR',
  'rengoujine':'Indkøb Beløb',
  'yue':'Saldo',
  'duiyingUMI-USDC':'Est. Pool Allocation',
  'duiyingUMI': 'Købe',
  'danbirengou':'Enkelt UMI Indkøb',
  'UMIzhekou':'UMI Rabat',
  'youhui':'Rabat',
  'daoqilingqu':'Du vil få',
  'pizhunrengou':'Godkend',
  'rengouchengong':'Indkøb Afsluttet',
  'lingqurengouNFT':'Afhente Indkøb NFT',
  'gongxi':'Tillykke',
  'lingquchengong':'Kræv med succes',
  'dianjichakan':'Tjek',
  'wodeNFT':'Min NFT',
  'yiqiNFT':'Fase I NFT',
  'dangqiandizhi':'Den aktuelle adresse er ikke på hvidlisten',
  'ninzaiyiqi':'Du er på den hvidliste for fase I af NFT',
  'lingquyiqi':'Kræve Fase I NFT',
  'nftmingzi1':'UMIDAO Medlemskab（Fase I）',
  'nftmingzi2':'UMIDAO Medlemskab（Fase II）',
  'bianhao':'Værdikupon ID',
  'chuangjianshijian':'Oprettelsesdato',
  'heyuedizhi':'Kontraktadresse',
  'jiaoyihaxi':'Txn Hash',
  'querengoumai':'Bekræft Kræve',
  'yuebuzu':'Utilstrækkelig Saldo',
  'querenlingqu':'Bekræft Kræve',
  'zhiyamenkan':'Mindste Indsats',
  'zongshuocang':'TVL',
  'zichanmingchen':'Aktiver',
  'zhiyazhouqi':'Indsats Varighed',
  'shuhui':'Indløs',
  'souquanUMIDAO':'Bemyndige af UMIDAO til at satse i ',
  'shouquan':'Bemyndige',
  'zhiyayue':'Din Indsats Saldo',
  'daoqijiangli':'Samlet Belønning',
  'jiaogeshijian':'Optjening Varighed',
  'shuhuizhiya':'Indløs og Indsats',
  'daifafangjiang':'Afventende Belønninger',
  'kelingqujiang':'Kan Kræves Belønninger',
  'daoqihou':'Tid indtil fuldt ud optjent',
  'daoqiriqi':'Optjeningsdato',
  '2022_6_1':'1. juni 2022',
  'dengdaiqueren':'Venter på Bekræftelse',
  'querenzheci':'Bekræft denne transaktion i din tegnebog',
  'wodeqianbao':'Min Tegnebog',
  'duankailianjie':'Afbryd Forbindelsen',
  'lianjieshiba':'Forbindelse Mislykkedes',
  'lianjiebeijujie':'forbindelsen mislykkedes, forbindelsen er afvist',
  'wanluocuowu':'Netværk Mislykkedes',
  'qingqiehuanwang':'Netværk mislykkedes, skift venligst til Hovednet',
  'qiehuanwangluo':'Skift til Hovednet',
  'pizhunshibai':'Godkendelse mislykkedes',
  'pizhunjujie':'anmodning om godkendelse er afvist',
  'jiaoyishibai':'Transaktionen mislykkedes',
  'jiaoyijujie':'anmodning om transaktion er afvist',
  'lianjieMetamask':'Opret forbindelse til din MetaMask Tegnebog',
  'lianjieWalletConnect':'Forbind med WalletConnect',
  'zhiyachengong':'Indsats med succes',

  'yizhiya':'Indsats', // 增加
  'month1': '1 måned',
  'month3': '3 måneder',
  'month6': '6 måneder',
  'month12': '12 måneder',
  'gasbutie': 'Gas Bonus',

  // 
  'jiaoyichenggong': 'Transaktionen lykkes',
  'guanbi': 'Luk',
  'yitijiao': 'Indsendt',
  'zhixingchenggong': 'Gennemført transaktion',
  'dengdaiqueren': 'Venter på Bekræftelse',
  'dailingqu': 'Uafklaret krav',
  'lingquzhong': 'Kræve',

  'dengdaishouquan': 'Venter på tilladelse',
  'shouquanzhong': 'Tilladelse',
  'shouquanchenggong': 'Tilladelse lykkes',
  'jiaoyizhong': 'Handel',
  'dengdaishuhui': 'Venter på forløsning',
  'shuhuizhong': 'Forløsning',
  'shuhuichenggong': 'Forløsning lykkes',
  'zhiyazhong': 'Indsatser',
  'goumaichenggong': 'Købe lykkes',
  'yisifang': 'Udgivet',
  'shouquanrengou': 'Tilladelse til køb',
  'zhiyashijian': 'Indsats term',
  'keyong1': 'Tilgængelig UMI  ',
    'shurutokenid': 'Indtast LP TokenID',
  'tokeindcuowu': 'TokenID Forkert',
  'qianbao': 'Tegnebog',

  'nftbannertxt': 'I det fremtidige Metaverse er teknologien monopoliseret af kapitalen for at kontrollere befolkningen. På nattehimlen flyver en massiv mekanisk dragebåd hen over bygningerne. De nødlidende individer i hjørnet af slummen ryster på jorden. Her eksisterer der kun kapital og ikke-entiteter, som er fyldt med forbrydelser og tab. Med MagicTech i hånden planlægger NEZHA at afmontere hovedstadens hemmelighed og reducere alle løgne til aske for at finde frem til den skjulte sandhed.',
  'nezhamanghe': 'NEZHA Blindes kasse',
  'tongguozhuzao': 'Opnåelse af NEZHA NFT via møntning, krav på luftdrop via køb af UMI og indsats af LP.',
  'zhuzao': 'Møntning',
  'meixiaochannft': 'Mønt 3 NFT i timen, 72 NFT om dagen',
  'kaishizhuzao': 'Møntning nu',
  'kongtou': 'Luftdrop',
  'rengouzhiyalingqunft': 'Gør krav på NFT airdrop via købt UMI og indsats af LP.',
  'lingqukongtou': 'Krav nu',
  'fenpeizhuzaoguize':'Regel om distribution og udmøntning',
  'nezhaoriental': 'Det samlede antal NEZHA Oriental-mønter er på 10.000, hvoraf 1.000 er reserveret til holdet, 1.000 bliver kastet ned til samfundet, og 8.000 bliver præget til salg.',
  'tuanduibaoliu': 'Hold Reserveret',
  'kaifatuandui': '500 til udviklerhold, 500 til IP-hold',
  'shequkongtou': 'Fællesskabet Luftdrop',
  'rengouzhiyabaimingdan': '1.000 hvid liste over køb/indsats',
  'zhuzaofashou': 'Møntning Salg',
  '8000mint': "8.000 MINT 1-3, maks. 3 NFT'er",
  'zhuzaoguize': ' Møntning Regel',
  '8000manghe': "8.000 for blindes kasseprægning, og den sælges på hjemmesiden via MINT-kanalen. Blind box mønter 3 NEZHA i timen, 72 NEZHA om dagen, med et maksimum på 3 NEZHA NFT'er for hver bruger.",
  'womenjiangchangqi': 'Vi vil foretage NFT-planlægning i overensstemmelse med denne køreplan over en længere periode.',
  'womenweinezha': "Vi har fastlagt en plan for NEZHA's udvikling. Når vi har nået målet om salgsprocent, vil vi forsøge at opfylde de fastsatte mål",
  "xianshanghualang": "Online galleri",
  "nezhachaowan": "Udvikling af NEZHA Tidevandsspil derivater",
  "xianxiaip": "Offline IP fælles udstillingstur",
  "nezhadujia":"NEZHA eksklusive produkter/ samling i begrænset udgave",
  "goumaixuni":"Køb virtuel jord til visning af metarum",
  "xianxiashuzi":"Offline digital kunstauktion",
  "OpenSea": "OpenSea Tilbagekøb",
  "qidianjuhui":"Fejring & Fester",
  "zhuzaozongliang":"Møntning summen",
  "zhuzajiage":"Møntning pris",
  "yixuan":"Udvalgte",
  "zongji":"summen",
  "zhuzaowancheng":"Møntning Færdig",
  "ccobanquan":"NEZHA er møntet i overensstemmelse med CC0-aftalen om ophavsret.",
  "kongtounezha":"Luftdrop NEZHA",
  "kongtouzongliang":"Luftdrop summen",
  "yikongtou":"Luftdroppet",
  "wodekongtou":"Min Luftdrop",
  "buzaibaimingdan":"Ud af den hvide liste",
  "zhuzaochenggong":"Møntning lykkes",

  "yaoqinglianjie": 'Invitationslink',
  "fuzhi": "Kopier",
  "fuzhichenggong": "Kopier lykkes",
  "dakaiqianbao": "Åbn din decentrale tegnebog",
  "lianjiefuzhi": "Når du har kopieret webstedslinket, skal du åbne det i wallet-browseren til Møntning"
}