<template>
  <div class="castnft_page">
    <!-- <img src="../../assets/image/castnft_pagebg.png" alt="" class="castnft_pagebg"> -->
    <div class="castnft_title">
      <!-- <img src="../../assets/image/castnft_title.png" alt="" @click="$router.back()"> -->
      <p>{{$t("lang.kongtounezha")}}</p>
    </div>
    <div class="cast_info">
      <div>
        <p class="cast_info_title">{{$t("lang.kongtouzongliang")}}</p>
        <p class="cast_info_value">{{dropInfo.dropSum}} NFT</p>
      </div>
      <div>
        <p class="cast_info_title">{{$t("lang.dailingqu")}}</p>
        <p class="cast_info_value">{{dropInfo.dropMinting}} NFT</p>
      </div>
      <div>
        <p class="cast_info_title">{{$t("lang.yikongtou")}}</p>
        <p class="cast_info_value">{{dropInfo.dropMinted}} NFT</p>
      </div>
    </div>

    <div class="cast_content">
      <div class="cast_content_bg">
        <div class="cast_content_top">
          <p class="cast_content_top_right">{{$t("lang.wodekongtou")}}</p>
        </div>
        <img :src="alreadyGet ? require('./../../assets/image/dropnftimg1.png') : require('./../../assets/image/dropnftimg.png')" alt="" class="manghe_icon">
        <div class="cast_bnt" v-if="!$store.state.userAddress" @click="openLinkWallet">{{$t("lang.lianjieqianbao")}}</div>
        <div class="cast_bnt" v-else-if="!hideBtn && $store.state.userAddress" :style="buyingStatus!=1 && {background:'#979797',color: buyingStatus==2?'#fff':'#4A4A4A',cursor:'not-allowed'}">
          <UmiButton
            :loading="buyingStatus==2"
            :text="buyingStatusTxt"
            @clickFn="getNft"
            :disable="!canGet"
            btnColor="auto"
            circleColor="#fff"
          />
        </div>
      </div>
    </div>
    
    <!-- 弹窗 -->
    <PcModal v-if="showModal" @closeModal="closePCmodal" :title="modalTitle">
      <!-- loading -->
      <div slot="content" v-if="modalType == 0" class="buy_succes_modal_h5"><modalLoading :h5="true"/></div>
      <!-- 交易已提交 -->
      <div slot="content" v-if="modalType == 1" class="buy_succes_modal_h5">
        <div class="buy_succes_modal_content_h5">
          <img src="../../assets/image/arrow_top.png" alt="" style="width:67px;margin:0 auto;">
          <p style="text-align:center;margin-bottom:20px">{{$t("lang.yitijiao")}}</p>
        </div>
        <div class="buy_succes_modal_btn_h5" style="background:#4A4A4A" @click="closePCmodal">{{$t("lang.guanbi")}}</div>
      </div>
      <!-- 交易成功 -->
      <div slot="content" v-if="modalType == 2" class="buy_succes_modal_h5">
        <div class="buy_succes_modal_content_h5">
          <p>{{$t("lang.rengouchengong")}}</p>
        </div>
        <div class="buy_succes_modal_btn_h5" @click="closePCmodal">{{$t("lang.guanbi")}}</div>
      </div>
      <!-- 失败 -->
      <div slot="content" v-if="modalType ==3" class="buy_succes_modal_h5">
        <div class="buy_succes_modal_content_h5">
          <img src="../../assets/image/error_gray.png" alt="" style="width:67px;margin:0 auto;">
        </div>
        <div class="buy_succes_modal_btn_h5" style="background:#4A4A4A" @click="closePCmodal">{{$t("lang.guanbi")}}</div>
      </div>
    </PcModal>

    <!-- 铸造成功 castSuccess -->
    <div class="cast_success_modal" v-if="castSuccess">
      <p class="cast_success_modal_title1">{{$t("lang.gongxi")}}!</p>
      <p class="cast_success_modal_title2">{{$t("lang.lingquchengong")}}</p>
      <div class="cast_success_nft">
        <div 
          class="cast_success_nft_item_info"
          v-for="(item,index) in dropNftList" 
          :key={index}
        >
          <p>NEZHA</p>
          <p>#{{item}}</p>
        </div>
      </div>
       <div class="close_cast_success_modal" @click="$router.replace('/h5mynft')">{{$t("lang.wodeNFT")}}</div>
    </div>

    <!-- <img src="./../../assets/image/dropnftimg.png" alt="" class="bottom_img"> -->
  </div>
</template>
<script>
import axios from 'axios';
import PcModal from './../../components/H5Modal.vue'
import modalLoading from '../../components/loading.vue';
import UmiButton from '../../components/UmiButton.vue';
import LoadingIcon from '../../components/loadingIcon.vue';
// 交易hash
let successOnce = false;
export default {
  name: "detail",
  components: {
    PcModal,
    modalLoading,
    UmiButton,
    LoadingIcon
  },
  data() {
    return {

      showModal: false,
      modalType: 0, // 0 loading, 1 已提交  2 成功 3 失败
      modalTitle: '',

      buyingStatus: 1, // 可领取 2 领取中 3 不在白名单 4以领取
      buyingStatusTxt:  this.$t("lang.lingqukongtou"),

      canGet: true,
      ethBalance: 0,

      dropNftList: [],
      castSuccess: false,
      notWhite: false, // 白名单

      alreadyGet: false,
      // 空头数据
      dropInfo: {
        dropSum: 1000,
        dropMinted: 0,
        dropMinting: 0
      },
      hideBtn: false
    };
  },
  computed: {
    
  },
  watch: {
    modalType() {
      if(this.modalType == 3) {
        this.modalTitle = this.$t("lang.jiaoyishibai");
      } else if(this.modalType == 1) {
        this.modalTitle = this.$t("lang.jiaoyichenggong");
      } else {
        this.modalTitle =  '';
      }
    },
    // 弹窗显示 关闭
    showModal() {
      if(!this.showModal) {
        this.modalTitle =  '';
        this.modalType = 0;
      }
    },
    "$store.state.NFT_contract" () {
      if(this.$store.state.NFT_contract) {
        this.checkWhiteList();
        this.getInfo()
      }
    }
  },
  methods: {
    openLinkWallet() {
      this.$emit("openLinkWallet")
    },
    closePCmodal() {
      this.showModal = false;
      this.modalType = 0;
    },
    async getNft() {
      if(this.buyingStatus != 1) {
        return
      }
      successOnce = false;
      this.showModal = true;
      this.buyingStatus = 2;

      const { NFT_contract,userAddress,NFTList } = this.$store.state;

      NFT_contract.methods.whiteListMint().send({from: userAddress})
      .on('transactionHash', (hash)=>{ // 交易hash
        this.modalType = 1;
        !this.showModal && (this.showModal = true);
      })
      .on('confirmation', (confirmationNumber, receipt)=>{ // 交易已提交
        
      })
      .on('receipt', async (receipt)=>{
        if(!successOnce) {
          const returnValue = receipt.events.Transfer.returnValues;
          const dropNftList = [
            returnValue.tokenId
          ]
          this.dropNftList = dropNftList;
          this.castSuccess = true;
          this.showModal = false;
          this.buyingStatus = 4;
          this.buyingStatusTxt = this.$t("lang.yilingqu");
          this.alreadyGet = true;
          this.getInfo();
          successOnce = true;
          this.$store.commit('setNFTList',[...NFTList,...dropNftList])
        }
        
      })
      .on('error',(error, receipt)=>{ // 交易失败
        if(!successOnce) {
          this.modalType = 3;
          this.buyingStatus = 1;
          !this.showModal && (this.showModal = true);
          this.buyingStatusTxt =  this.$t('lang.lingqukongtou');
        }
      })

      
    },
    async getInfo() {
      const { NFT_contract } = this.$store.state;
      const res1 = await NFT_contract.methods.MAX_MINT_SUPPLY().call(); // 总量
      const res2 = await NFT_contract.methods.whiteListLeft().call(); // 待领取
      // const res3 = await NFT_contract.methods.currentLeftNft().call(); // 待领取
      this.dropInfo = {
        dropSum: 1000,
        dropMinted: 1000 - (res2 || 0),
        dropMinting: res2
      }
      if((res2*1) == 0 ) {
        this.hideBtn = true
      }
    },
    async checkWhiteList() {
      const { NFT_contract,userAddress } = this.$store.state;
      const isWhite = await NFT_contract.methods.isWhiteList(userAddress).call();
      if(!isWhite) {
        this.modalType = 3;
        this.notWhite = true;
        this.buyingStatus = 3;
        this.buyingStatusTxt = this.$t("lang.buzaibaimingdan")
        return 
      }
      
      const isGet = await NFT_contract.methods.isWhitelistAlreadyTaked(userAddress).call();
      if(isGet) {
        this.buyingStatus = 4;
        this.buyingStatusTxt = this.$t("lang.yilingqu");
        this.alreadyGet = true;
        this.canGet= false;
      }
    }
  },
  mounted() {
    
    if(!this.$store.state.userAddress) {
      this.$emit('openLinkWallet');
      return 
    }
    this.checkWhiteList();
    this.getInfo()
  }
};
</script>

<style scoped >
  .castnft_page {
    width: 100%;
    background: url('../../assets/image/castnft_pagebg.png') no-repeat;
    background-size: calc(100% - 7px - 22px) auto;
    background-position-x: 7px;
    background-position-y: 50px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 20px 12px;
    border-radius: 10px;
    position: relative;
    margin-top: 20px;
  }
  .castnft_title {
    display: flex;
    align-items: center;
    margin-bottom: 23px;
  }
  .castnft_title p {
    font-size: 17px;
    font-weight: 500;
    color: #26493E;
  }

  .cast_info {
    width: 100%;
    height: 67px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(105, 200, 157, 0.3);
    border-radius: 10px;
    text-align: center;
    margin-bottom: 19px;
  }
  .cast_info div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .cast_info_title {
    font-size: 10px;
    font-weight: 600;
    color: #26493E;
    margin-bottom: 1.25rem;
  }
  .cast_info_value {
    font-size: 13px;
    font-weight: 600;
    color: #26493E;
  }

  .cast_content {
    width: 100%;
    min-height: 520px;
    background: url('./../../assets/image/castnftcontentbg.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .cast_content_bg {
    background: url('./../../assets/image/castcontentbg_phone.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    flex: 1;
  }
  .cast_content_top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    padding-left: 11px;
    padding-right: 26px;
  }
  .cast_content_top_right {
    font-size: 17px;
    font-weight: 500;
    color: #26493E;
  }
  .manghe_icon {
    margin-top: 180px;
    margin-bottom: 130px;
    width: 115px;
    height: auto;
  }
  .cast_bnt {
    width: 240px;
    height: 48px;
    cursor: pointer;
    background: #4A4A4A;
    border-radius: 54px;
    line-height: 48px;
    text-align: center;
    font-size: 19px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 50px;
    overflow: hidden;
  }

  .cast_success_modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 91;
    
    padding: 50px 45px;
    box-sizing: border-box;
  }
  .cast_success_modal_title1 {
    font-size: 29px;
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 19px;
  }
  .cast_success_modal_title2 {
    font-size: 19px;
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 10px;
  }
  .cast_success_nft {
    height: 200px;
    margin: 100px auto;
  }
  
  .cast_success_nft_item_info {
    width: 100%;
    margin-bottom: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cast_success_nft_item_info p {
    font-size: 19px;
    font-weight: 600;
    color: #fff;
  }
  .close_cast_success_modal {
    margin-top: 35px;
    width: 90%;
    height: 49px;
    background: #FFFFFF;
    border-radius: 53px;
    text-align: center;
    line-height: 49px;
    font-size: 19px;
    font-weight: bold;
    color: #4A4A4A;
    cursor: pointer;
  }

</style>