// 意大利    key由 文字中的四个字的拼音组成y
export const lang = {
  'shouye':'Home',
  'rengou':'Acquistare',
  'zhiya':'Ipotecare',
  'lianjieqianbao':'Collegare il portafoglio',
  'quanqiulingxian':'Il nativo crittografico leader mondiale Aboriginal Community Autonomous Organization DAO',
  'zhuanzhuyu':"Costruire DEFI, Web3 e Metaverse da parte della comunità e della tecnologia crittografica per catturare il dividendo di crescita del settore per la comunità.",
  'canyurengou':'Aquista ora',
  'daibifenpei':'Distribuzione di token',
  'zongfaxingliang':'Fornitura totale',
  'chubeijin':'Fondo di riserva',
  'shequeDAO':'Comunità DAO',
  'liudongxingLP':'Liquidità LP',
  'tuandui':'Squadra',
  'rengoujiangli':'Acquistare premi',
  'luxiantu':'Tabella di marcia',
  'jiamijijin':'Pioniere di fondo crittografico',
  'umiCashchengli':'Maggio, 2018 istituzione umiCash',
  'umiCashv1shangxian':"Rilascio dell'APP umiCash di maggio 2019",
  'umiCashzhishujijin':"Rilascio del fondo dell'indice umiCash di maggio 2020",
  'umiCashxiaomubiao':'Rilascio di umiCash small target luglio 2021',
  'hunhedapan':'Il patrimonio netto del fondo di mercato ibrido 10 è aumentato a 10.0 a settembre 2021',
  'umiCashzuoshishangban':'Dicembre 2021 umiCash rilascia la versione marketer',
  'umiDeFichanpinshangxian':'Rilascio dei prodotti umiDigi nel primo trimestre',
  'umiDAOzhililuntan':'Rilascio del forum sulla governance di umiDO nel secondo trimestre',
  'UMIDAOzhiliguifan':'Rilascio dello standard di governance UMIDAO nel terzo trimestre',
  'UMIDAOweiyuanhui':'Elezione del Comitato UMIDAO',
  'umiCustodyjiamizhiguan':'Gestione delle risorse umiCustody',
  'SatQuantDEXlianghua':'SatQuant quantitativo DEX',
  'UMIDAOshequqianbao':'Multisig-distribuzione del portafoglio della community UMIDAO nel quarto trimestre',
  'umiCashProjiamilianghua':'Quantizzazione crittografica di umiCashPro',
  'FundDappjiamijijin':'Fondo crittografico DAPP',
  'shequn':'Comunità',
  'rengouyibiao':'Acquistare dashboard',
  'woderengou':'Il mio acquisto',
  'rengouzongliang':'Acquisto totale (Fase II)',
  'rengouzhekou':'Sconto',
  'shuocangshouyi':'Ricompensa per il blocco',
  'zongshouyi':'ROI totale',
  'UMIjiage':'Prezzo UMI',
  'rengoujindu':'Tasso di avanzamento',
  'yiwancheng':'Completato',
  'shengyu':'Residuo',
  'danrengouUMI':'Acquisto unico UMI',
  'shuoding30':'Blocca per 6 mesi per rilasciare il 40%, quindi rilascia linearmente il 10% ogni mese',
  'rengouzichan':'Beni di finanziamento',
  'zijinyongtu1':"Utilizzo dei fondi: 80% del fondo di acquisto viene utilizzato per il fondo di riserva della comunità, che è gestito dal portafoglio multisig della comunità, e il 20% è utilizzato per il funzionamento del progetto",
  'qigoujine':'Acquisto minimo',
  'zuLPrengou' :"Fornire l'acquisto di LP",
  'shuoding7':'Blocca per 6 mesi per rilasciare il 40%, quindi rilascia linearmente il 10% ogni mese',
  'zijinyongtu2':'Utilizzo dei fondi: 80% del fondo di acquisto viene utilizzato per il fondo di riserva comunitaria e il 20% per il funzionamento del progetto. Il fondo rimanente viene utilizzato per raggruppare UMI-USDC LP e versarlo nel pool uniswap.',
  'lingquUMI':"Rivendicare l'UMI",
  'qigoumenkan':'Acquisto minimo',
  'yirengou':'Acquistato',
  'yilingqu':'Importo richiesto',
  'kelingqu':'Importo rivendicabile',
  'daifafang':'Importo in sospeso',
  'nianhuaAPR':'APR',
  'rengoujine':"Quantità d'acquisto",
  'yue':'Saldi',
  'duiyingUMI-USDC':'Est. Pool Allocation',
  'duiyingUMI':'Acquistare',
  'danbirengou':'Acquisto unico UMI',
  'UMIzhekou':'Sconto UMI',
  'youhui':'Sconti',
  'daoqilingqu':'Tu avrai',
  'pizhunrengou':'Approvare',
  'rengouchengong':'Acquisto completato',
  'lingqurengouNFT':'Richiedere acquisto NFT',
  'gongxi':'Congratulazioni',
  'lingquchengong':'Rivendicare con successo',
  'dianjichakan':'Verificare',
  'wodeNFT':'Il mio NFT',
  'yiqiNFT':'Fase I NFT',
  'dangqiandizhi':"L'indirizzo attuale non è nella lista nera",
  'ninzaiyiqi':'Sei nella lista bianca NFT di Fase I',
  'lingquyiqi':'Rivendicazione Fase I NFT',
  'nftmingzi1':'Iscrizione UMIDAO（Fase I）',
  'nftmingzi2':'Iscrizione UMIDAO（Fase II）',
  'bianhao':'ID token',
  'chuangjianshijian':'Data di creazione',
  'heyuedizhi':'Aggiungere contratto',
  'jiaoyihaxi':'Txn Hash',
  'querengoumai':'Confermare reclamo',
  'yuebuzu':'Saldo insufficiente',
  'querenlingqu':'Conferma reclamo',
  'zhiyamenkan':'Ipoteca minima',
  'zongshuocang':'TVL',
  'zichanmingchen':'Risorsa',
  'zhiyazhouqi':'Durata dell’ipoteca',
  'shuhui':'Riscattare',
  'souquanUMIDAO':"Autorizza UMIDAO a mettere nell'ipoteca ",
  'shouquan':'Autorizzare',
  'zhiyayue':"Il tuo saldo dell'ipoteca",
  'daoqijiangli':'Ricompensa totale',
  'jiaogeshijian':'Periodo di maturazione',
  'shuhuizhiya':'Riscattare e ipotecare',
  'daifafangjiang':'Ricompense in sospeso',
  'kelingqujiang':'Premi rivendicabili',
  'daoqihou':'Tempo fino al completo conferimento',
  'daoqiriqi':'Periodo di maturazione',
  '2022_6_1':'1 giugno 2022',
  'dengdaiqueren':'In attesa di conferma',
  'querenzheci':"Confermare questa transazione nel tuo portafoglio",
  'wodeqianbao':'Il mio portafoglio',
  'duankailianjie':'Disconnetto',
  'lianjieshiba':'Connessione fallita',
  'lianjiebeijujie':'connessione fallita, connessione rifiutata',
  'wanluocuowu':'Rete fallita',
  'qingqiehuanwang':'Rete non riuscita, passare alla rete principale',
  'qiehuanwangluo':'Rete non riuscita, passare alla rete principale',
  'pizhunshibai':'Approvazione fallita',
  'pizhunjujie':'richiesta di approvazione è respinta',
  'jiaoyishibai':'Transazione fallita',
  'jiaoyijujie':'richiesta di transazione rifiutata',
  'lianjieMetamask':'Collegare al tuo portafoglio MetaMask',
  'lianjieWalletConnect':'Connettere con WalletConnect',
  'zhiyachengong':'Pegno con successo',
  
  'yizhiya':'Ipotecato', // 增加
  'month1': '1 mese',
  'month3': '3 mesi',
  'month6': '6 mesi',
  'month12': '12 mesi',
  'gasbutie': 'Ricompense del gas',
  // 
  'jiaoyichenggong': 'Transazione riuscita',
  'guanbi': 'Chiudere',
  'yitijiao': 'Presentato',
  'zhixingchenggong': 'Transazione eseguita',
  'dengdaiqueren': 'In attesa di conferma',
  'dailingqu': 'In attesa di ricevuto',
  'lingquzhong': 'Ricevo in corso',

  'dengdaishouquan': 'In attesa di autorizzazione',
  'shouquanzhong': 'Autorizzazione',
  'shouquanchenggong': 'Autorizzazione riuscita',
  'jiaoyizhong': 'Commercio',
  'dengdaishuhui': 'Aspettando la redenzione',
  'shuhuizhong': 'Redenzione in corso',
  'shuhuichenggong': 'Successo redenzione',
  'zhiyazhong': 'Ipotecare in corso',
  'goumaichenggong': 'Acquisto riuscito',
  'yisifang': 'Rilasciato',
  'shouquanrengou': "Autorizza l'acquisto",
  'zhiyashijian': 'Tempo ipoteca',
'keyong1': 'UMI disponibile',
    'shurutokenid': 'Immettere LP TokenID',
  'tokeindcuowu': 'TokenID errato',
  'qianbao': 'Portafoglio',

  'nftbannertxt': "Nel futuro metaverso, il capitale monopolizza la tecnologia per controllare i cittadini comuni. Sotto la notte, c'erano molti edifici alti,  una gigantesca barca drago macchinario solcava il cielo tra gli edifici, per terra, i poveri negli angoli dei bassifondi tremavano. Ci sono solo capitale e formiche qui e c'è crimine e smarrimento. NEZHA, che tiene in mano la tecnologia nera, vuole fare a pezzi la nebbia del capitale, ridurre in cenere tutte le bugie con il fuoco e cercare di trovare la verità nascosta...",
  'nezhamanghe': 'Scatola cieca NEZHA',
  'tongguozhuzao': "Ottenere NEZHA NFT attraversoil conio, rivendicare airdrop NFT attraverso l'acquisto UMI e ipoteca LP.",
  'zhuzao': 'Coniare',
  'meixiaochannft': "Mint 3 NFT all'ora, 72 NFT al giorno",
  'kaishizhuzao': 'Conio ora',
  'kongtou': 'Airdrop',
  'rengouzhiyalingqunft': "Richiedere l'airdrop NFT tramite UMI acquistato ed ipoteca LP.",
  'lingqukongtou': 'Ricevere airdrop',
  'fenpeizhuzaoguize':' Regola di distribuzione e conio',
  'nezhaoriental': 'DNEZHA Oriental ha prodotto un totale di 10.000 NEZHA, di cui 1.000 sono stati riservati dal team, 1.000 sono stati destinati alla comunità e 7.000 e sono stati coniati e venduti.',
  'tuanduibaoliu': 'Squadra riservata',
  'kaifatuandui': '500 per il team di sviluppatori, 500 per il team di IP',
  'shequkongtou': "Airdrop comunitario",
  'rengouzhiyabaimingdan': "1.000 white list di acquisto/ipoteca",
  'zhuzaofashou': "Vendita al conio",
  '8000mint': "Tra 8000 MINT1-3, fino a 3 NFT",
  'zhuzaoguize': "Regola di conio",
  '8000manghe': "8000 scatole cieche MINT, vendute tramite il canale del conio della homepage. La scatola cieca genera 3 NEZHA ogni 1 ora, 72 ogni giorno, e gli utenti possono abbonarsi per un massimo di 3 NFT NEZHA.",
  'womenjiangchangqi': "8000 scatole cieche MINT, vendute tramite il canale del conio della homepage. La scatola cieca genera 3 NEZHA ogni 1 ora, 72 ogni giorno, e gli utenti possono abbonarsi per un massimo di 3 NFT NEZHA.",
  'womenweinezha': "Abbiamo pianificato un percorso di sviluppo per NEZHA. Una volta raggiunta la percentuale target di vendita, inizieremo a lavorare verso l'obiettivo stabilito.",
  "xianshanghualang": "Galleria in linea",
  "nezhachaowan": "Sviluppo di giocattoli alla moda derivati NEZHA",
  "xianxiaip": "Tour congiunto IP offline",
  "nezhadujia": "Merce esclusiva NEZHA/oggetti da collezione in edizione limitata",
  "goumaixuni": "Acquistare i terreni virtuali per dimostrare Metaverse",
  "xianxiashuzi": "Asta di arte digitale offline",
  "OpenSea": "Riaqusti OpenSea",
  "qidianjuhui": "Celebrazioni e raduni",
  "zhuzaozongliang": "Conio totale",
  "zhuzajiage": "Prezzo di conio",
  "yixuan":"Selezionato",
  "zongji":"Totale",
  "zhuzaowancheng":"Conio fatto",
  "ccobanquan":"Questo NEZHA NFT è coniato utilizzando l'accordo di avviso di copyright CC0",
  "kongtounezha":"Airdrop NEZHA",
  "kongtouzongliang":"Airdrop totale",
  "yikongtou":"Airdrop fatto",
  "wodekongtou":"Il mio airdrop",
  "buzaibaimingdan":"Non nella lista bianca",
  "zhuzaochenggong":"Successo conio",

  "yaoqinglianjie": 'Link di invito',
  "fuzhi": "Copia",
  "fuzhichenggong": "Copia riuscita",
  "dakaiqianbao": "Aprire il tuo portafoglio decentralizzato",
  "lianjiefuzhi": "Depois de copiar o link do site, abra-o no navegador da carteira para fundição"
}