<template>
  <div class="pledge">
    <p class="title">{{ $t("lang.zhiya") }}</p>
    <div class="buy_sencond">
      <div class="buy_sencond_item">
        <p>{{ $t("lang.zhiyamenkan") }}</p>
        <p>2000UMI</p>
      </div>
      <div class="buy_sencond_item">
        <p>{{ $t("lang.zongshuocang") }}</p>
        <p>${{ poolSum }}</p>
      </div>
      <div class="buy_sencond_item">
        <p>{{ $t("lang.UMIjiage") }}</p>
        <p>${{ ($store.state.UMI_USDC * 1).toFixed(3) }}</p>
      </div>
    </div>
    <div class="nft_card">
      <div class="nft_list_title">
        <div></div>
        <p class="card_list_info_one">{{ $t("lang.zichanmingchen") }}</p>
        <p class="card_list_info_two">{{ $t("lang.zhiyazhouqi") }}</p>
        <p class="card_list_info_three">APR</p>
        <div></div>
      </div>
      <div class="nft_list_row" v-for="(item, key) in list" :key="key">
        <div class="nft_list_icon">
          <img :src="item.img" alt="nft" />
        </div>
        <p class="card_list_info_one">{{ item.name }}</p>
        <p class="card_list_info_two">{{ $t("lang.month" + item.time) }}</p>
        <p class="card_list_info_three">{{ item.apr }}</p>
        <div class="nft_list_btn" @click="pledge(item)">
          {{ $t("lang.zhiya") }}
        </div>
      </div>
    </div>
    <!-- 质押弹窗 -->
    <pledgeUMIModal
      :UMIAllow="UMIAllow"
      :pledgeData="pledgeData"
      v-if="pledge_umi"
      @close="closeUMIPledge"
      @approvedUMI="approvedUMI"
    />

    <pledgeLPModal
      :LPAllow="LPAllow"
      :pledgeData="pledgeData"
      v-if="pledge_lp"
      @close="closeLPPledge"
      @approvedLP="approvedLP"
    />
    <!-- modal loading -->
    
  </div>
</template>
<script>
import utils from "@/utils/utils";
import abi from './../../assets/js/XRC20abi'
import pledgeUMIModal from './pledgeUMIModal'
import pledgeLPModal from './pledgeLPModal'
export default {
  name: "detail",
  components: {
    pledgeUMIModal,
    pledgeLPModal
  },
  data() {
    return {
      // 列表数据
      list: [
        {
          img: require("../../assets/image/nft.png"),
          apr: "9.00%",
          time: 1,
          name: "UMI",
        },
        {
          img: require("../../assets/image/nft.png"),
          apr: "12.00%",
          time: 3,
          name: "UMI",
        },
        {
          img: require("../../assets/image/nft1.png"),
          apr: "15.00%",
          time: 6,
          name: "UMI_USDC LP",
        },
        {
          img: require("../../assets/image/nft1.png"),
          apr: "20.00%",
          time: 12,
          name: "UMI_USDC LP",
        },
      ],
      
      // 质押数据
      pledgeData: {},
      
      // 锁仓总价值
      poolSum: 0,

      pledge_umi: false,
      UMIAllow: 0,

      LPAllow: 0,
      pledge_lp: false,
      
    };
  },
  
  watch: {
    "$store.state.UMI_USDC"() {
      this.getPoolNum1()
    },
    "$store.state.userAddress"() {
      this.getPoolNum();
      this.getAllow();
    }
  },
  mounted() {
    if(this.$store.state.userAddress) {
      this.getPoolNum();
      this.getAllow();
    } else {
      this.getPoolNum1()
    }
    
  },
  methods: {
    closeUMIPledge() {
      this.pledge_umi = false;
    },
    approvedUMI(val) {
      this.UMIAllow = val
    },

    closeLPPledge() {
      this.pledge_lp = false;
    },
    approvedLP(val) {
      this.LPAllow = val
    },
    
    async getPoolNum1() {
      const { UMI_USDC } = this.$store.state;
      if(!UMI_USDC) {
        return 
      }
      const web3jsobj = new Web3(window.ethereum);
      const DAOContract = new web3jsobj.eth.Contract(abi.poolabi, this.$api.DAO_Addr, {});
      const res = await DAOContract.methods.totalSupply().call();
      if(Array.isArray(res)) {
        const sum = res.reduce((acc,item)=>{
          acc = utils.plus(acc,item)
          return acc
        },0)
        this.poolSum = (utils.mathpow(sum, this.$api.UMI_Demical) * UMI_USDC).toFixed(3)
      }
    },
    async getPoolNum() {
      const { DAO_contract, userAddress, UMI_USDC } = this.$store.state;
      const res = await DAO_contract.methods.totalSupply().call({ from: userAddress });
      if(Array.isArray(res)) {
        const sum = res.reduce((acc,item)=>{
          acc = utils.plus(acc,item)
          return acc
        },0)
        this.poolSum = (utils.mathpow(sum, this.$api.UMI_Demical) * UMI_USDC).toFixed(3)
      }
      
      // const result = utils.mathpow(res, this.$api.UMI_Demical);
      // this.poolSum = (result * UMI_USDC).toFixed(3);
    },
    
    async getAllow() {
      const { UMI_contract, userAddress, LP_contract } = this.$store.state;
      const UMIAllow = await UMI_contract.methods
        .allowance(userAddress, this.$api.DAO_Addr)
        .call({ from: userAddress });

      const LPAllow = await LP_contract.methods
        .isApprovedForAll(userAddress, this.$api.DAO_Addr)
        .call({ from: userAddress });
      this.UMIAllow = utils.mathpow(UMIAllow, this.$api.UMI_Demical) * 1;
      this.LPAllow = LPAllow;
    },
    
    pledge(item) {
      if(!this.$store.state.userAddress) {
        this.$emit('openLinkWallet');
        return 
      }
      if(item.name == 'UMI') {
        this.pledge_umi = true;
      } else {
        this.pledge_lp = true;
      }
      
      this.pledgeData = item;
    },
  },
};
</script>

<style scoped >
.pledge {
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 0.5rem;
  padding: 5.1rem 6.75rem;
  box-sizing: border-box;
}
.title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #26493e;
  margin-bottom: 5rem;
}
.buy_sencond {
  display: flex;
  align-items: center;
  margin-bottom: 4.4rem;
  justify-content: space-between;
  margin-bottom: 3.4rem;
}
.buy_sencond_item {
  flex: 1;
}
.buy_sencond_item p {
  font-size: 1rem;
  font-weight: 600;
  color: #9A9A9A;
  margin-bottom: 1.25rem;
  text-align: center;
}
.buy_sencond_item p:last-child {
  margin-bottom: 0;
  color: #26493e;
  font-size: 1.5rem;
}
.nft_card {
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 1.2rem 0px rgba(216, 243, 230, 1);
  border-radius: 1.5rem;
  padding: 4.05rem;
  box-sizing: border-box;
}
.nft_list_title,
.nft_list_row {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.nft_list_title div:first-child {
  width: 4.35rem;
  flex-shrink: 0;
  margin-right: 2.4rem;
}
.nft_list_title div:last-child {
  width: 10rem;
  flex-shrink: 0;
}
.nft_list_title p {
  font-size: 0.8rem;
  font-weight: bold;
  color: #9a9a9a;
  flex: 1;
}
.card_list_info_one {
  text-align: left;
  margin-right: 2.4rem;
}
.card_list_info_two {
  text-align: center;
  margin-right: 2.4rem;
}
.card_list_info_three {
  text-align: center;
}
.nft_list_row {
  margin-bottom: 2.5rem;
  align-items: center;
}
.nft_list_icon {
  width: 4.35rem;
  height: 2.5rem;
  flex-shrink: 0;
  margin-right: 2.4rem;
}
.nft_list_icon img {
  height: 2.5rem;
  width: auto;
  margin: auto;
  display: block;
}
.nft_list_row p {
  font-size: 0.8rem;
  font-weight: bold;
  color: #26493e;
  flex: 1;
}
.nft_list_btn {
  width: 10rem;
  flex-shrink: 0;
  height: 2.5rem;
  background: #6fcaa0;
  border-radius: 2.5rem;
  text-align: center;
  line-height: 2.5rem;
  font-size: 0.8rem;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}
.nft_list_btn:hover {
  background: #8DDDB8;
}


/* pledge_dialog */
.pledge_dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 90;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}
.pledge_dialog_view {
  width: 50rem;
  min-height: 35rem;
  background: #ffffff;
  border-radius: 1.5rem;
  padding: 2.25rem 3.5rem;
  box-sizing: border-box;
  position: relative;
}
.pledge_dialog_close {
  position: absolute;
  right: 2.5rem;
  top: 3rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
.pledge_dialog_close img {
  width: 1rem;
  height: 1rem;
}
.pledge_dialog_title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3.5rem;
}
.pledge_dialog_title img {
  height: 2rem;
  margin-right: 0.75rem;
}
.pledge_dialog_title p {
  font-size: 1.2rem;
  font-weight: bold;
  color: #26493e;
}
.pledge_dialog_price {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.pledge_dialog_price div:first-child {
  margin-right: 23rem;
}
.pledge_dialog_price_title {
  font-size: 0.8rem;
  font-weight: bold;
  color: #9a9a9a;
  margin-bottom: 0.7rem;
  text-align: center;
}
.pledge_dialog_price_value {
  font-size: 1rem;
  font-weight: bold;
  color: #26493e;
  text-align: center;
}
.pledge_dialog_tab {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pledge_dialog_tab_item {
  width: 2rem;
  font-size: 1rem;
  font-weight: 500;
  color: #9a9a9a;
  padding-bottom: 8px;
  cursor: pointer;
}
.pledge_dialog_tab_item_choose {
  border-bottom: 2px solid #26493e;
  color: #26493e;
}
.pledge_approve_view {
  margin-top: 1rem;
  color: #9a9a9a;
  font-size: 0.7rem;
}
.pledge_approve_view p {
  height: 2.5rem;
  text-align: center;
  line-height: 2.5rem;
  margin: auto;
}
.pledge_approve_view span {
  color: #26493e;
  font-weight: 600;
}
.pledge_input_view {
  display: flex;
  align-items: center;
  width: 27rem;
  height: 2.5rem;
  background: #f8f8f8;
  border-radius: 3rem;
  margin: auto;
  margin-top: 1rem;
  overflow: hidden;
}
.pledge_input_view .userCoin {
  font-size: 0.7rem;
  color: #BDBDBD;
  font-weight: 500;
  margin-right: 0.5rem;
  flex-shrink: 0;
}
.pledge_input_view input {
  flex: 1;
  height: 2rem;
  padding: 0 1.5rem;
  background: none;
  box-sizing: border-box;
  font-size: 1.2rem;
  font-weight: 500;
  color: #26493E;
  border: none;
  outline: none;
}
.pledge_input_view input::placeholder {
  font-size: 1rem;
  color: #BDBDBD;
}
.pledge_input_view_coin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.4rem;
  height: 2rem;
  border-left: 2px solid #eeeeee;
  flex-shrink: 0;
}
.pledge_input_view_coin img {
  height: 1.25rem;
  width: auto;
  margin-right: 5px;
}
.pledge_input_view_coin p {
  font-size: 1rem;
  font-weight: bold;
  color: #26493e;
}
.approve_btn {
  margin: auto;
  width: 20rem;
  height: 3rem;
  background: #6fcaa0;
  border-radius: 3rem;
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 3rem;
  cursor: pointer;
  overflow: hidden;
}
.canClick:hover {
  background: #8DDDB8;
}
.pledge_dialog_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
}
.pledge_dialog_info p {
  font-size: 1rem;
  font-weight: bold;
  color: #26493e;
}

/* 交易状态 */
.transcation_view {
  width: 100%;
  height: 3rem;
  border-top: 1px  solid #E0E3E8;
  margin-top: 1.25rem;
  display: flex;
  align-items: flex-end;
}
.transcation_content {
  display: flex;
  align-items: center;
}
.get_status_view_img {
    width: 1rem;
    min-height: 1rem;
    margin-right: 0.5rem;
  }
.get_status_view_img img {
  width: 100%;
}
.transcation_content p {
  color: #4A4A4A;
  font-size: 0.75rem;
  letter-spacing: 1px;
}
</style>
