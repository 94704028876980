<template>
  <div class="buyDetail">
    <div class="card">
      <div class="title_flex">
       <p>{{$t("lang.zhiya")}}</p>
        <p>${{ ($store.state.UMI_USDC * 1).toFixed(3) }}</p>
      </div>
      <div class="title_flex1">
        <p>{{$t("lang.zongshuocang")}} ${{ poolSum }}</p>
        <p>{{$t("lang.UMIjiage")}}</p>
      </div>
      <div class="nft_list_card">
        <div class="nft_list_flex nft_list_title">
          <div class="icon_view"></div>
          <p class="nft_name">{{$t("lang.zichanmingchen")}}</p>
          <p class="nft_time">{{$t("lang.zhiyazhouqi")}}</p>
          <p class="nft_apr">APR</p>
          <div class="select_view"></div>
        </div>
        <div class="nft_list_flex nft_list_content" v-for="(item,index) in list" :key="index" @click="selectNft(item,index)" :style="select === index && {borderColor:'#6FCAA0'}">
          <div class="icon_view">
            <img :src="item.img" alt="">
          </div>
          <p class="nft_name">{{ item.name }}</p>
          <p class="nft_time">{{ $t("lang.month" + item.time) }}</p>
          <p class="nft_apr">{{ item.apr }}</p>
          <div class="select_view">
            <img src="./../../assets/image/h5radio_icon.png" alt="" v-show="select === index">
          </div>
        </div>
        <p class="tips">{{$t("lang.zhiyamenkan")}} 2000UMI</p>
        <div class="peldge_btn" @click="pledge">{{$t("lang.zhiya")}}</div>
      </div>
     
    </div>
  </div>
</template>

<script>
import { Notification,Message  } from 'element-ui';
import abi from './../../assets/js/XRC20abi'
import utils from "@/utils/utils";
export default {
  name: "buy",
  components: {
   
  },
  data() {
    return {
      tab: 1,
      isTeam: false,
       // 列表数据
      list: [
        {
          img: require("../../assets/image/nft.png"),
          apr: "9.00%",
          time: 1,
          name: "UMI",
          id: 1
        },
        {
          img: require("../../assets/image/nft.png"),
          apr: "12.00%",
          time: 3,
          name: "UMI",
          id: 2
        },
        {
          img: require("../../assets/image/nft1.png"),
          apr: "15.00%",
          time: 6,
          name: "UMI-USDC LP",
          id: 3
        },
        {
          img: require("../../assets/image/nft1.png"),
          apr: "20.00%",
          time: 12,
          name: "UMI-USDC LP",
          id: 4
        },
      ],
      select: 0,
      pledgeId: 1,

      poolSum: 0,
    };
  },
  computed: {
   
  },
  watch: {
   "$store.state.UMI_USDC"() {
     this.getPoolNum1()
   }
  },
  methods: {
    async getPoolNum() {
      const { DAO_contract, userAddress, UMI_USDC } = this.$store.state;
      const res = await DAO_contract.methods.totalSupply().call({ from: userAddress });
      if(Array.isArray(res)) {
        const sum = res.reduce((acc,item)=>{
          acc = utils.plus(acc,item)
          return acc
        },0)
        this.poolSum = (utils.mathpow(sum, this.$api.UMI_Demical) * UMI_USDC).toFixed(3)
      }
    },
    async getPoolNum1() {
      const { UMI_USDC } = this.$store.state;
      if(!UMI_USDC) {
        return 
      }
      const web3jsobj = new Web3(window.ethereum);
      const DAOContract = new web3jsobj.eth.Contract(abi.poolabi, this.$api.DAO_Addr, {});
      const res = await DAOContract.methods.totalSupply().call();
      if(Array.isArray(res)) {
        const sum = res.reduce((acc,item)=>{
          acc = utils.plus(acc,item)
          return acc
        },0)
        this.poolSum = (utils.mathpow(sum, this.$api.UMI_Demical) * UMI_USDC).toFixed(3)
      }
    },
    pledge() {
      if(!this.pledgeId) {
        return 
      }
      this.$router.push({
        path: '/h5stakeDetail',
        query: { id: this.pledgeId }
      })
    },
    selectNft(item,index) {
      this.select = index;
      this.pledgeId = item.id
    }
  },
  mounted() {
   if(this.$store.state.userAddress) {
      this.getPoolNum()
    } else {
      this.getPoolNum1()
    }
  },
};
</script>

<style scoped >
  .card {
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 0px 6px 0px rgba(147,188,175,1);
    border-radius: 10px;
    margin-top: 20px;
    padding: 16px 11px;
    box-sizing: border-box;
  }
  .title_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #26493E;
    margin-bottom: 5px;
  }
  .title_flex p:first-child {
    font-size: 17px;
    
  }
  .title_flex p:last-child {
    font-size: 12px;
  }
  .title_flex1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #26493E;
    font-size: 11px;
    margin-bottom: 19px;
  }
  .title_flex1 p:first-child {
    color: #9A9A9A;
  }
  .nft_list_card {
    width: 100%;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 0px 6px 0px rgba(216,243,230,1);
    border-radius: 10px;
    padding: 20px 9px;
    box-sizing: border-box;
  }
  .nft_list_flex {
    width: 100%;
    height: 58px;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 0px 6px 0px rgba(216,243,230,1);
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    box-sizing: border-box;
  }
  .icon_view {
    width: 48px;
    height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 5px;
  }
  .icon_view img {
    width: auto;
    height: 19px;
  }
  .nft_name {
    flex: 1;
    margin-right: 5px;
    text-align: center;
  }
  .nft_time {
    flex: 1;
    margin-right: 5px;
    text-align: center;
  }
  .nft_apr {
    width: 50px;
    text-align: center;
    flex-shrink: 0;
  }
  .select_view {
    width: 35px;
    height: 17px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .select_view img {
    width: 17px;
    height: 17px;
  }
  .nft_list_title {
    box-shadow: none;
    height: 30px;
  }
  .nft_list_title p {
    font-size: 12px;
    color: #9A9A9A;
  }
  .nft_list_content {
    border: 1px solid rgba(0,0,0,0);
  }
  .nft_list_content p {
    font-size: 12px;
    color: #26493E;
  }
  .tips {
    font-size: 12px;
    color: #769991;
    margin-top: 50px;
    margin-bottom: 10px;
    text-align: center;
  }
  .peldge_btn {
    width: 200px;
    height: 48px;
    background: #6FCAA0;
    border-radius: 26px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    line-height: 48px;
    margin: auto;
  }
</style>
