<template>
<div>
  <div 
    class="umibtn_pc" 
    :style="(loading || disable)&&{background: btnColor=='auto'?'none':'#B4E3CD',cursor:'not-allowed'}"
    @click="clickBtn"
    v-if="!h5Btn"
  >
    <p>{{text}}</p>
    <div v-show="loading" class="pc_btn_loading">
      <svg viewBox="25 25 50 50">
        <circle cx="50" cy="50" r="20" :style="{stroke: circleColor || '#6FCAA0'}"></circle>
      </svg>
    </div>
  </div>

  <div class="umibtn_h5" v-else :style="(loading || disable)&&{background: btnColor=='auto'?'none':'#B4E3CD',cursor:'not-allowed'}" @click="clickBtn">
    <p>{{text}}</p>
    <div v-show="loading" class="h5_btn_loading">
      <svg viewBox="25 25 50 50">
        <circle cx="50" cy="50" r="20" :style="{stroke: circleColor || '#6FCAA0'}"></circle>
      </svg>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "button",
  props: ['loading', 'text', 'h5Btn', 'disable', 'btnColor', 'circleColor'],
  data() {
    return {
      
    }
  },
  methods: {
    clickBtn() {
      if(this.loading || this.disable) {
        return 
      }
      this.$emit("clickFn")
    }
  },
 
  
};
</script>

<style  scoped>
.umibtn_pc,.umibtn_h5 {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pc_btn_loading {
  position: absolute;
  right: 2.4rem;
  height: 100%;
  width: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pc_btn_loading svg {
  width: 1.2em;
  transform-origin: center;
  animation: rotate 2s linear infinite;
}
.h5_btn_loading {
  position: absolute;
  right: 20px;
  height: 100%;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.h5_btn_loading svg {
  width: 20px;
  transform-origin: center;
  animation: rotate 2s linear infinite;
}

circle {
  fill: none;
  
  stroke-width: 5;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dashoffset: -125px;
  }
}
</style>

