<template>
  <div class="pledgeDetail">
    <div class="card">
      <div class="title_flex">
        <img :src="pledgeData.img" alt="">
        <p>{{pledgeData.name}}</p>
      </div>
      <div class="info_flex1">
        <div>
          <p class="info_flex1_title">{{ 'LPs' }}</p>
          <p class="info_flex1_value">{{((this.$store.state.balance.LPBalacnce*1)|| '0')}}LP</p>
        </div>
        <div>
          <p class="info_flex1_title">APR</p>
          <p class="info_flex1_value">{{pledgeData.apr}}</p>
        </div>
      </div>
      <div class="tab">
        <p :class="`tab_item ${pledgeTab==1 && 'tab_choose'}`" @click="changeTab(1)">{{$t("lang.zhiya")}}</p>
        <p :class="`tab_item ${pledgeTab==2 && 'tab_choose'}`" @click="changeTab(2)">{{$t("lang.shuhui")}}</p>
      </div>
      <div  v-show="pledgeTab==1">
        
        <div class="approve" v-if="LPApproveView">
          <p class="tips">{{$t("lang.souquanUMIDAO")}}<span>{{pledgeData.name}}</span></p>
          <div class="btn canclick">
            <UmiButton
              :disable="approveStatus==2"
              :text="$t('lang.shouquan')"
              @clickFn="approveFn(2)"
              :h5Btn="true"
            />
          </div>
        </div>
        
        <div class="pledge">
          <div class="input_view" v-show="pledgeInputView" :style="isfocus && {background: '#EEF8F4'}">
            <input
              type="number"
              :placeholder="$t('lang.shurutokenid')"
              name=""
              id="" 
              v-model="pledgeTokenid" 
              @blur="isfocus = false"
              @focus="isfocus = true"
              :style="isfocus && {background: '#EEF8F4',caretColor: '#6fcaa0'}"
            />
            <div class="input_right">
              <img :src="pledgeData.img" alt="" class="input_right_coin">
              <p>{{ "LP" }}</p>
            </div>
          </div>


        <div
          class="btn"
          v-if="noBalance"
          style="background: #D8F3E6;cursor: not-allowed;"
        >
          {{ $t("lang.yuebuzu") }}
        </div>
        <div
          v-else
          class="btn canClick"
          v-show="pledgeInputView"
        >
          <UmiButton
            :disable="stakeStatus==1 || !canPledge"
            :text="$t('lang.zhiya')"
            @clickFn="pledgeFn"
            :h5Btn="true"
          />
        </div>
        </div>
      </div>
      <!-- 赎回 -->
      <div
        class="btn canClick"
        v-show="pledgeTab == 2"
        style="margin-bottom:10px"
      >
        <UmiButton
          :disable="redeemStatus==2 || !canRedeem || !date_redeem"
          :text="$t('lang.shuhui')"
          @clickFn="redeemFn"
          :h5Btn="true"
        />
      </div>
      <div
        class="btn canClick"
        v-show="pledgeTab == 2"
      >
        <UmiButton
          :disable="redeemStatus==2 || !canRedeem || !date_redeem"
          :text="$t('lang.shuhuizhiya')"
          @clickFn="redeemPledgeFn"
          :h5Btn="true"
        />
      </div>
      
      <div style="height:30px"></div>
      <div class="info_flex2" v-show="pledgeTab == 2">
        <p>{{$t("lang.daifafangjiang")}}</p>
        <p>{{(info.willsendNum * 1).toFixed(3)}} UMI</p>
      </div>
      <div class="info_flex2" v-show="pledgeTab == 2">
        <p>{{$t("lang.kelingqujiang")}}</p>
        <p>{{(info.canGetNum * 1).toFixed(3)}} UMI</p>
      </div>
      <div class="info_flex2" v-show="pledgeTab == 1">
        <p>{{$t("lang.zhiyayue")}}</p>
        <p>
          {{ (info.stakeNum * 1).toFixed(3) }}
          {{  "UMI"  }}
        </p>
      </div>

      <div class="info_flex2" v-show="pledgeTab == 1">
        <p>{{ $t("lang.daoqijiangli") }}</p>
        <p>{{ (info.willsendNum * 1).toFixed(3) }} UMI</p>
      </div>

      <!-- <div class="info_flex2">
        <p>{{$t("lang.nianhuaAPR")}}</p>
        <p>{{pledgeData.apr}}</p>
      </div> -->
      <div class="info_flex2" v-show="pledgeTab == 1">
        <p>{{ $t("lang.zhiyashijian") }}</p>
        <p>{{ $t("lang.month" + pledgeData.time) }}</p>
      </div>
      <div class="info_flex2" v-show="pledgeTab == 2">
        <p>{{$t("lang.daoqiriqi")}}</p>
        <p>{{ info.endTime }}</p>
      </div>
      <div class="transcation_view">
      <!-- approve -->
        <div class="transcation_content" v-if="(pledgeData.name == 'UMI' ? UMIApproveView : LPApproveView) && pledgeTab==1">
          <div class="get_status_view_img">
            <img src="./../../assets/image/waite.png" alt="" v-if="approveStatus==1">
            <loadingIcon v-if="approveStatus==2" svgW="16px" svgH="16px"/>
            <img src="./../../assets/image/success.png" alt="" v-if="approveStatus==3">
          </div>
          <p>{{approveStatusTxt}}</p>
        </div>

        <!-- tokenid 错误 -->
          <div class="transcation_content" v-if="canPledge && tokenidErr && pledgeTab==1 ">
            <div class="get_status_view_img" style="height:18px">
              <img src="./../../assets/image/error.png" alt="" >
            </div>
            <p>{{tokenidErr}}</p>
          </div>

        <!-- stake -->
        <div class="transcation_content" v-if="pledgeInputView && canPledge && stakeStatus ">
          <div class="get_status_view_img">
            <loadingIcon v-if="stakeStatus==1" svgW="16px" svgH="16px"/>
            <img src="./../../assets/image/success.png" alt="" v-if="stakeStatus==2">
          </div>
          <p>{{stakeStatusTxt}}</p>
        </div>
        <!-- redeem -->
        <div class="transcation_content" v-if=" canRedeem && pledgeTab==2">
          <div class="get_status_view_img">
            <img src="./../../assets/image/waite.png" alt="" v-if="redeemStatus==1">
            <loadingIcon v-if="redeemStatus==2" svgW="16px" svgH="16px"/>
            <img src="./../../assets/image/success.png" alt="" v-if="redeemStatus==3">
          </div>
          <p>{{redeemStatusTxt}}</p>
        </div>
      </div>
      <p class="redeem_tip" v-show="pledgeTab==2 && $i18n.locale=='zh'">多笔质押展示最后一笔质押到期时间，赎回未到期的不发放奖励</p>
    </div>
  </div>
</template>

<script>
import utils from "@/utils/utils";
import PcModal from "@/components/H5Modal";
import modalLoading from "../../components/loading.vue";
import UmiButton from '../../components/UmiButton.vue';
import LoadingIcon from '../../components/loadingIcon.vue';
var shownotify = false;
var shownotify1 = false;
var timer = null;
export default {
  name: "detail",
  components: {
    PcModal,
    modalLoading,
    UmiButton,
    LoadingIcon
  },
  props: [ "LPAllow" ],
  data() {
    return {
      // 列表数据
      list: [
        {
          img: require("../../assets/image/nft.png"),
          apr: "9.00%",
          time: 1,
          name: "UMI",
          id: 1
        },
        {
          img: require("../../assets/image/nft.png"),
          apr: "12.00%",
          time: 3,
          name: "UMI",
          id: 2
        },
        {
          img: require("../../assets/image/nft1.png"),
          apr: "15.00%",
          time: 6,
          name: "UMI-USDC LP",
          id: 3
        },
        {
          img: require("../../assets/image/nft1.png"),
          apr: "20.00%",
          time: 12,
          name: "UMI-USDC LP",
          id: 4
        },
      ],
      // 质押 or 赎回
      pledgeTab: 1,
      // 质押弹窗
      pledge_dialogShow: false,
      // 质押数据
      pledgeData: {},
      // 质押数量
      pledgeTokenid: '',

      // 能否质押
      canPledge: false,
      //  质押信息
      info: {
        stakeNum: '0.000',
        willsendNum: '0.000',
        canGetNum: '0.000',
        endTime: ''
      },
      canRedeem: false,
      date_redeem: true, // 当质押为12 个月的时候，需要到期才能赎回

      approveStatus: 1, // 1等待授权  2授权中 3授权成功
      approveStatusTxt: `${this.$t("lang.dengdaishouquan")}...`,

      noBalance: false,

      stakeStatus: null, // null '' 1交易中 2质押成功
      stakeStatusTxt: '',

      redeemStatus: 1, // 1等待赎回  2赎回中 3赎回成功
      redeemStatusTxt: `${this.$t("lang.dengdaishuhui")}...`,
      tokenidErr: '', // 查询tokenid 错误获取tokenid 余额不足

      isfocus: false, // =输入框是否聚焦
      item: {}
    };
  },
  computed: {
    
    LPApproveView() {
      return (
        this.pledgeTab == 1 && !this.LPAllow
      );
    },
    pledgeInputView() {
      return this.pledgeTab == 1 && !!this.LPAllow;
      
    },
  },
  watch: {
    "$store.state.userAddress"() {
      this.getPrize();
    },
    "info.stakeNum" () {
      if(this.info.stakeNum*1 > 0) {
        this.canRedeem = true;
      }
    },
    pledgeTokenid() {
      if(this.pledgeTokenid) {
        this.canPledge = true;
      }
    }
  },
  mounted() {
    this.getItemData();
    this.getPrize();
  },
  methods: {
    changeTab(val) {
      this.pledgeTab = val;
    },
    getItemData() {
      const id = this.$route.query.id;
      const res = this.list.filter(item=>item.id == id)[0];
      this.pledgeData = res;
    },
    // genuine当前日期 获取到期 日期
    getDuedate(start,monthNum) {
      if(!start || start *1 == 0 ) {
        return ''
      }
      var s = new Date((start+'000')*1);
      var year = s.getFullYear(); //获取当前日期的年份
      var month = s.getMonth() + 1; //获取当前日期的月份
      var day = s.getDate(); //获取当前日期的日
      var days = new Date(year, month, 0);
      days = days.getDate(); //获取当前日期中的月的天数
      var hour = s.getHours();
      var min = s.getMinutes();
      var year2 = year;
      var month2 = parseInt(month) + parseInt(monthNum);
      if (month2 > 12) {
        year2 =
          parseInt(year2) +
          parseInt(parseInt(month2) / 12 == 0 ? 1 : parseInt(month2) / 12);
        month2 = parseInt(month2) % 12;
      }
      var day2 = day;
      var days2 = new Date(year2, month2, 0);
      days2 = days2.getDate();
      if (day2 > days2) {
        day2 = days2;
      }
      if (month2 < 10) {
        month2 = "0" + month2;
      }

      var t2 = `${year2}-${month2}-${day2} ${hour<10 ? '0'+hour : hour}:${min<10 ? '0'+min : min} `;
      return t2;
    },
    async approveFn(type) {
      const { userAddress, LP_contract } = this.$store.state;
      this.approveStatus = 2;
      this.approveStatusTxt = this.$t("lang.shouquanzhong")+'...'
      try {
        this.approvingStatus2 = 2;
        const res = await LP_contract.methods
        .setApprovalForAll(
          this.$api.DAO_Addr,
          true
        )
        .send({ from: userAddress });
        this.$emit('approvedLP',true);
        this.approveStatus = 3;
        this.approveStatusTxt = this.$t("lang.shouquanchenggong");
      } catch (error) {
        this.approveStatus = 1;
        this.approveStatusTxt = this.$t("lang.dengdaishouquan")+'...';
      }
    },
    pledgeFn() {
      const { name, time } = this.pledgeData;
      let lockType = "";
      
      this.stakeStatus = 1;
      this.stakeStatusTxt = this.$t("lang.jiaoyizhong")+'...';
      
      lockType = (time == "6" ? 2 : 3);
      this.LPPledge(this.pledgeTokenid, lockType);
    },
    // 认购成功 或这领取成功 需要重新获取数据
    async regetData(type) {
      const { UMI_contract, LP_contract, userAddress, balance } = this.$store.state;
      const UMIres = await UMI_contract.methods.balanceOf(userAddress).call();
      const UMIBalance = utils.mathpow(UMIres,this.$api.UMI_Demical);
      const LPBalacnce = await LP_contract.methods.balanceOf(userAddress).call();
        
        if(balance.LPBalacnce == LPBalacnce) {
          timer = setTimeout(()=>{ this.regetData()},3000);
          return 
        }
        
        this.$store.commit('setBalance',{...balance,UMIBalance,LPBalacnce});
    },
    async LPPledge(num, lockType) {
      const { DAO_contract, userAddress } = this.$store.state;
      this.tokenidErr = '';
      try {
        const res = await DAO_contract.methods.queryLpByTokenId(num*1).call({from: userAddress})
        
        const nft_umi = utils.mathpow(res[1],this.$api.UMI_Demical);
        if(nft_umi*1 < 2000) {
          this.tokenidErr = this.$t("lang.yuebuzu");
          this.stakeStatus = null;
          this.stakeStatusTxt = '';
          return 
        }
      } catch (error) {
        this.stakeStatus = null;
        this.stakeStatusTxt = '';
        this.tokenidErr = this.$t("lang.tokeindcuowu");
        return 
      }
      
      shownotify = false;
      DAO_contract.methods
        .stakeLP(num*1, lockType)
        .send({ from: userAddress })
        .on("transactionHash", (hash) => {
          // 交易hash
        })
        .on("receipt", (receipt) => {
          // 交易已广播
          if(!shownotify) {
            this.stakeStatus = 2;
            this.stakeStatusTxt = this.$t("lang.zhiyachengong");
            const text = this.$t("lang.zhiyachengong");
            this.canRedeem = true;
            this.$notify({
              title: '',
              duration: 8000,
              dangerouslyUseHTMLString: true,
              message: `<div class="notify_success_view_h5"><img src="${require("../../assets/image/success.png")}"/><p>${text}</p></div>`
            });
            this.pledgeTokenid = '';
            timer = setTimeout(()=> {
              this.stakeStatus = null;
              this.stakeStatusTxt = '';
              this.getPrize(true);
            },2000)
            shownotify = true;
            this.regetData();
          }
        })
        .on("error", (error, receipt) => {
          // 交易失败
          this.stakeStatus = null;
          this.stakeStatusTxt = '';
        });
    },
    //  查询 奖励
    async getPrize() {
      try {
        const { time } = this.pledgeData;
        let lockType = (time == "6" ? 2 : 3);
        const arrIndex = time=='6' ? 2 : 3;
        const { DAO_contract, userAddress, USDC_contract } = this.$store.state;
        const res = await DAO_contract.methods
          .getLpLineReward(userAddress,lockType)
          .call({ from: userAddress });
        const info = {
          stakeNum:  utils.mathpow((res.stakedAmount[arrIndex] || 0),this.$api.UMI_Demical),
          willsendNum:  utils.mathpow((res.targetRewardAmount[arrIndex] || 0),this.$api.UMI_Demical),
          canGetNum:  utils.mathpow((res.realRewardAmount[arrIndex] || 0),this.$api.UMI_Demical),
          endTime: this.getDuedate(res.startDay,this.pledgeData.time)
        }
        this.date_redeem = this.pledgeData.time==12 ? (new Date().getTime() > new Date(info.endTime).getTime() ) : true;
        if(this.info.stakeNum == info.stakeNum && reget) {
          timer = setTimeout(()=>{this.getPrize(reget)},2000)
        }
        this.info = info;
      } catch (error) {
        
      }
    },

    // 赎回
    redeemFn(status) {
      this.redeemStatus = 2;
      this.redeemStatusTxt = this.$t("lang.shuhuizhong")+'...';
      
      this.LPRedeem(status);
    },
    async LPRedeem() {
      const { time } = this.pledgeData;
      let lockType = (time == "6" ? 2 : 3);
      shownotify1 = false;
      const { DAO_contract, userAddress } = this.$store.state;
      DAO_contract.methods
        .withdrawLP(lockType)
        .send({ from: userAddress })
        .on("transactionHash", (hash) => {
          // 交易hash
        })
        .on("receipt", (receipt) => {
          if(!shownotify1) {
            this.redeemStatus = 3;
            const text = this.$t("lang.shuhuichenggong");
            this.canRedeem = false;
            this.$notify({
              title: '',
              duration: 8000,
              dangerouslyUseHTMLString: true,
              message: `<div class="notify_success_view_h5"><img src="${require("../../assets/image/success.png")}"/><p>${text}</p></div>`
            });
            timer = setTimeout(()=> {
              this.redeemStatus = null;
              this.stakeStatusTxt = '';
              this.getPrize(true);
            },2000)
            shownotify1 = true;

            this.regetData()
          }
        })
        .on("error", (error, receipt) => {
          // 交易失败
          this.redeemStatus = 1;
          this.redeemStatusTxt = this.$t("lang.dengdaishuhui")+'...';
        });
    },
    // 赎回并质押
    redeemPledgeFn() {
      const { time } = this.pledgeData;
      let lockType = (time == "6" ? 2 : 3);
      shownotify1 = false;
      this.redeemStatus = 2;
      this.redeemStatusTxt = this.$t("lang.jiaoyizhong")+'...';
      const { DAO_contract, userAddress } = this.$store.state;
      DAO_contract.methods
        .withdrawLPandLP(lockType)
        .send({ from: userAddress })
        .on("transactionHash", (hash) => {
          // 交易hash
        })
        .on("receipt", (receipt) => {
          // 交易已广播
          if(!shownotify1) {
            this.redeemStatus = 3;
            const text = this.$t("lang.zhiyachengong");
            this.canRedeem = true;
            this.$notify({
              title: '',
              duration: 8000,
              dangerouslyUseHTMLString: true,
              message: `<div class="notify_success_view_h5"><img src="${require("../../assets/image/success.png")}"/><p>${text}</p></div>`
            });
            this.pledgeTokenid = '';
            timer = setTimeout(()=> {
              this.stakeStatus = null;
              this.stakeStatusTxt = '';
              this.getPrize(true);
            },2000)
            shownotify1 = true;
            this.regetData();
          }
        })
        .on("error", (error, receipt) => {
          // 交易失败
          this.redeemStatus = 1;
          this.redeemStatusTxt = this.$t("lang.dengdaishuhui")+'...';
        });
    },
  },
  destroyed() {
    clearTimeout(timer);
  }
 
};
</script>

<style scoped >
  .card {
    width: 100%;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 0px 6px 0px rgba(147,188,175,1);
    border-radius: 10px;
    margin-top: 20px;
    padding: 20px 28px;
    box-sizing: border-box;
  }
  .title_flex {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }
  .title_flex img {
    height: 19px;
    margin-right: 10px;
  }
  .title_flex p {
    font-size: 17px;
    font-weight: 600;
    color: #26493E;
  }
  .info_flex1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  .info_flex1_title {
    font-size: 12px;
    color: #9A9A9A;
    margin-bottom: 10px;
    text-align: center;
  }
  .info_flex1_value {
    font-size: 12px;
    color: #26493E;
    text-align: center;
  }
  .tab {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  .tab_item {
    width: 29px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    color: #9A9A9A;
    font-weight: 600;
    font-size: 14px;
  }
  .tab p:first-child {
    margin-right: 40px;
  }
  .tab_choose {
    border-bottom: 2px solid #26493E;
    color: #26493E;
  }
  .tips {
    font-size: 10px;
    font-weight: 600;
    color: #9A9A9A;
    text-align: center;
    margin-bottom: 19px;
  }
  .tips span {
    color: #26493E;
  }
  .btn {
    width: calc(100% - 40px);
    height: 48px;
    line-height: 48px;
    border-radius: 26px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    background: #6FCAA0;
    margin: auto;
    overflow: hidden;
  }
  .input_view {
    display: flex;
    align-items: center;
    width: 100%;
    height: 38px;
    background: #F8F8F8;
    border-radius: 58px;
    margin-bottom: 19px;
    box-sizing: border-box;
  }
  .input_view  .userCoin {
  font-size: 12px;
  color: #BDBDBD;
  font-weight: 500;
  margin-right: 10px;
  flex-shrink: 0;
}
  .input_view input {
    flex: 1;
    width: 100px;
    height: 21px;
    line-height: 21px;
    padding: 0 10px;
    box-sizing: border-box;
    border: none;
    outline: none;
    background: none;
    font-size: 15px;
    font-weight: 500;
    color: #26493E;
  }
  .input_view input::placeholder {
    font-size: 13px;
    color: #BDBDBD;
  }
  .input_right {
    flex-shrink: 0;
    height: 21px;
    display: flex;
    align-items: center;
    border-left: 1px solid #EEEEEE;
    padding: 0 10px;
    box-sizing: border-box;
    position: relative;
  }
  .input_right_coin {
    height: 19px;
    margin-right: 6px;
  }
  .input_right p {
    font-size: 15px;
    font-weight: 600;
    color: #26493E;
    margin: 0 5px;
  }
  .info_flex2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    font-size: 12px;
    font-weight: 500;
    color: #26493E;
  }
  /* 交易状态 */
.transcation_view {
  width: 100%;
  height: 40px;
  border-top: 1px  solid #E0E3E8;
  margin-top: 20px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.transcation_content {
  display: flex;
  align-items: center;
}
.get_status_view_img {
    width: 15px;
    min-height: 15px;
    margin-right: 10px;
  }
.get_status_view_img img {
  width: 100%;
}
.transcation_content p {
  color: #4A4A4A;
  font-size: 12px;
  letter-spacing: 1px;
  height: 20px;
  line-height: 17px;
}
.redeem_tip {
  font-size: 12px;
  color: #4A4A4A;
  text-align: left;
  margin-top: 10px;
  line-height: 18px;
}
</style>
