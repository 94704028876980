<template>
  <div class="pledgeDetail">
    <pledgeLP 
      v-if="id==3 || id==4" 
      :LPAllow="LPAllow"
      :pledgeData="pledgeData"
      @close="closeLPPledge"
      @approvedLP="approvedLP"
    />
    <pledgeUMI
      v-if="id==1 || id==2"
      :UMIAllow="UMIAllow"
      :pledgeData="pledgeData"
      @close="closeUMIPledge"
      @approvedUMI="approvedUMI"
    />
  </div>
</template>

<script>
import utils from "@/utils/utils";
import pledgeLP from './pledgeLP.vue'
import pledgeUMI from './pledgeUMI.vue'
var shownotify = false;
export default {
  name: "detail",
  components: {
    pledgeLP,
    pledgeUMI,
  },
  data() {
    return {
      id: null,
      pledgeData: {},

      pledge_umi: false,
      UMIAllow: 0,

      LPAllow: 0,
      pledge_lp: false,
    };
  },
  computed: {
   
  },
  
  mounted() {
    this.id = this.$route.query.id;
    if(this.$store.state.userAddress) {
      this.getAllow();
    }
  },
  watch: {
    
    "$store.state.LP_contract"() {
      this.getAllow();
    }
  },
  methods: {
     closeUMIPledge() {
      this.pledge_umi = false;
    },
    approvedUMI(val) {
      this.UMIAllow = val
    },

    closeLPPledge() {
      this.pledge_lp = false;
    },
    approvedLP(val) {
      this.LPAllow = val
    },
    
    async getAllow() {
      const { UMI_contract, userAddress, LP_contract } = this.$store.state;
      const UMIAllow = await UMI_contract.methods
        .allowance(userAddress, this.$api.DAO_Addr)
        .call({ from: userAddress });

      const LPAllow = await LP_contract.methods
        .isApprovedForAll(userAddress, this.$api.DAO_Addr)
        .call({ from: userAddress });
      this.UMIAllow = utils.mathpow(UMIAllow, this.$api.UMI_Demical) * 1;
      this.LPAllow = LPAllow;
    },
    
  },
 
};
</script>

<style scoped >
  
</style>
