// 葡萄牙   key由 文字中的四个字的拼音组成
export const lang = {
  'shouye':'Página Inicial',
  'rengou':'Comprar',
  'zhiya':'Apostar',
  'lianjieqianbao':'Conectar a Carteira',
  'quanqiulingxian':'O Mundial- Principal Native Dao de Criptografia DAO',
  'zhuanzhuyu':'Edifício Defi, Web3 e Metaverse construídas pela comunidade e tecnologia de criptografia para capturar o dividendo do crescimento da indústria para a comunidade.',
  'canyurengou':'Compre agora',
  'daibifenpei':'Distribuição de Token',
  'zongfaxingliang':'Fornecimento Total',
  'chubeijin':'Fundo de Reserva',
  'shequeDAO':'Comunidade DAO',
  'liudongxingLP':'Liquidez LP',
  'tuandui':'Equipe',
  'rengoujiangli':'Recompensas de Compra',
  'luxiantu':'Roteiro',
  'jiamijijin':'Pioneiro do Fundo da Criptografia',
  'umiCashchengli':'Maio de 2018, Estabelecimento de umiCash',
  'umiCashv1shangxian':'Maio de 2019, Lançamento do Aplicativo de umiCash',
  'umiCashzhishujijin':'Maio de 2020, Liberação do Fundo do Índice de umaCash',
  'umiCashxiaomubiao':'Julho de 2021, Lançamento da Meta Pequena de umiCash',
  'hunhedapan':'Setembro de 2021, Patrimônio Líquido do Fundo do Mercado Híbrido 10 subiu para 10,0',
  'umiCashzuoshishangban':'Dezembro de 2021, Lançamento da Versão do Criador do Mercado de umiCash',
  'umiDeFichanpinshangxian':'Q1, Lançamento dos Produtos de umiDeFi',
  'umiDAOzhililuntan':'Q2, Lançamento do Fórum da Governança de umiDAO',
  'UMIDAOzhiliguifan':'Lançamento do Padrão da Governança de UMIDAO',
  'UMIDAOweiyuanhui':'UMIDAO Eleição do Comitê',
  'umiCustodyjiamizhiguan':'umiCustody Gestão dos Ativos',
  'SatQuantDEXlianghua':'SatQuant DEX Quantitativo',
  'UMIDAOshequqianbao':'UMIDAO Multisig-distribuição da Carteira de Comunidade',
  'umiCashProjiamilianghua':'umiCashPro Quantização da Criptografia',
  'FundDappjiamijijin':'Criptografia do Fundo de DAPP',
  'shequn':'Comunidade',
  'rengouyibiao':'Compra os Paineis',
  'woderengou':'Minha Compra',
  'rengouzongliang':'Compra Total (Fase II)',
  'rengouzhekou':'Desconto',
  'shuocangshouyi':'Recompensa de Bloqueio',
  'zongshouyi':'ROI Total',
  'UMIjiage':'Preço de UMI',
  'rengoujindu':'Taxa de Progresso',
  'yiwancheng':'Concluído',
  'shengyu':'Restante',
  'danrengouUMI':'Compra Única de UMI',
  'shuoding30':'Bloqueio de 6 meses por 40% da liberação e depois, libere 10% em linha por mês',
  'rengouzichan':'Ativos de Financiamento',
  'zijinyongtu1':"Uso dos Fundos: 80% do fundo de compra é utilizado para o fundo de reserva da comunidade, que é gerido pela multisig-carteira da comunidade; e 20% é usado para a operação do projeto.",
  'qigoujine':'Compra Mínima',
  'zuLPrengou' :'Fornecer a Compra de LP',
  'shuoding7':'Bloqueio de 6 meses por 40% da liberação e depois, libere 10% em linha por mês',
  'zijinyongtu2':'Uso dos Fundos: 80% do fundo de compra é usado para o fundo de reserva da comunidade e 20% para a operação do projeto. O fundo restante é usado para agrupar UMI-USDC LP e despejar na piscina de uniswap.',
  'lingquUMI':'Reivindicar UMI',
  'qigoumenkan':'Compra Mínima',
  'yirengou':'Comprado',
  'yilingqu':'Valor Reivindicado',
  'kelingqu':'Valor Reclamável',
  'daifafang':'Valor Pendente',
  'nianhuaAPR':'APR',
  'rengoujine':'Valor de Compra',
  'yue':'Saldos',
  'duiyingUMI-USDC':'Est. Pool Allocation',
  'duiyingUMI': 'Compre',
  'danbirengou':'Compra Única de UMI',
  'UMIzhekou':'Desconto de UMI',
  'youhui':'Descontos',
  'daoqilingqu':'Você Vai Ganhar',
  'pizhunrengou':'Aprovar',
  'rengouchengong':'Compra Concluída',
  'lingqurengouNFT':'Reivindicar a Compra de NFT',
  'gongxi':'Parabéns',
  'lingquchengong':'Reivindicar com sucesso',
  'dianjichakan':'Verificar',
  'wodeNFT':'Meu NFT',
  'yiqiNFT':'Fase I NFT',
  'dangqiandizhi':'O endereço atual não está na lista das permissões',
  'ninzaiyiqi':'Você está na lista das permissões de NFT da Fase I',
  'lingquyiqi':'Reivindicar NFT da Fase I',
  'nftmingzi1':'UMIDAO Filiação（Fase I）',
  'nftmingzi2':'UMIDAO Filiação（Fase II）',
  'bianhao':'ID de Token',
  'chuangjianshijian':'Data de Criação',
  'heyuedizhi':'Endereço de Contrato',
  'jiaoyihaxi':'Txn Hash',
  'querengoumai':'Confirmar a Reivindicação',
  'yuebuzu':'Saldo de Insuficiente',
  'querenlingqu':'Confirmar a Reivindicação',
  'zhiyamenkan':'Aposta Mínima',
  'zongshuocang':'TVL',
  'zichanmingchen':'Ativos',
  'zhiyazhouqi':'Prazo da Aposta',
  'shuhui':'Resgatar',
  'souquanUMIDAO':'Autorizar UMIDAO a apostar',
  'shouquan':'Autorizar',
  'zhiyayue':'Seu Saldo da Aposta',
  'daoqijiangli':'Recompensa Total',
  'jiaogeshijian':'Prazo de Aquisição',
  'shuhuizhiya':'Resgatar e Apostar',
  'daifafangjiang':'Recompensas Pendentes',
  'kelingqujiang':'Recompensas Reclamáveis',
  'daoqihou':'Tempo até que esteja totalmente investido',
  'daoqiriqi':'Data de Aquisição',
  '2022_6_1':'1 de Junho de 2022',
  'dengdaiqueren':'Aguardando a Confirmação',
  'querenzheci':'Confirme esta transação na sua carteira',
  'wodeqianbao':'Minha Carteira',
  'duankailianjie':'Desconectar',
  'lianjieshiba':'Falha em Conectar',
  'lianjiebeijujie':'Falha em conectar e a conexão foi recusada',
  'wanluocuowu':'Falha na Rede',
  'qingqiehuanwang':'Falha na rede, e por favor, mude para Mainnet',
  'qiehuanwangluo':'Mude para Mainnet',
  'pizhunshibai':'Falha na Aprovação',
  'pizhunjujie':'O pedido da aprovação é negado',
  'jiaoyishibai':'Falha na Transação',
  'jiaoyijujie':'O pedido da transação é negado',
  'lianjieMetamask':'Conecte-se à sua carteira de MetaMask',
  'lianjieWalletConnect':'Conecte-se com WalletConnect',
  'zhiyachengong':'Aposta com sucesso',
  
  'yizhiya':'Aposta', // 增加
  'month1': '1 mês',
  'month3': '3 meses',
  'month6': '6 meses',
  'month12': '12 meses',
  'gasbutie': 'Bônus de Gas',
  // 
  'jiaoyichenggong': 'Transação com sucesso',
  'guanbi': 'Fechar',
  'yitijiao': 'Transação Submetida',
  'zhixingchenggong': 'Transação Executada',
  'dengdaiqueren': 'Aguardando a Confirmação',
  'dailingqu': 'Reivindicação Pendente',
  'lingquzhong': 'Reivindicando',

  'dengdaishouquan': 'Espera de Autorização',
  'shouquanzhong': 'Autorizando',
  'shouquanchenggong': 'Autorização com sucesso',
  'jiaoyizhong': 'Negociação',
  'dengdaishuhui': 'Esperando o Resgate',
  'shuhuizhong': 'Resgatando',
  'shuhuichenggong': 'Resgate com sucesso',
  'zhiyazhong': 'Estacando',
  'goumaichenggong': 'Compra com sucesso',
  'yisifang': 'Liberado',
  'shouquanrengou': 'Autorizar Comprar',
  'zhiyashijian': 'Termo de Participação',
'keyong1': 'UMI Disponível  ',
    'shurutokenid': 'Digite LP TokenID',
  'tokeindcuowu': 'TokenID Incorreto',
  'qianbao': 'Carteira',

  'nftbannertxt': 'No Metaverso futuro, o capital monopoliza a tecnologia para controlar os civis comuns. No céu noturno, um barco dragão gigante de maquinário atravessa os arranha-céus. No chão, os pobres da esquina de favela estão tremendo. Aqui só existem os capitais e as nulidades, cheios dos crimes e das perdas. Segurando MagicTech, NEZHA pretende destruir o mistério dos capitais e queimar todas as mentiras em cinzas com fogo para buscar a verdade oculta.',
  'nezhamanghe': 'Caixa Cega de NEZHA',
  'tongguozhuzao': 'Obtendo NEZHA NFT via cunhagem, reivindicando o airdrop de NFT através da compra de UMI e apostando LP',
  'zhuzao': 'Cunhagem',
  'meixiaochannft': 'Produz-se 3 NFTs por hora, 72 NFTs por dia',
  'kaishizhuzao': 'Inicie a cunhagem',
  'kongtou': 'Airdrop',
  'rengouzhiyalingqunft': 'Reivindique o airdrop NFT através da compra de UMI e apostando LP',
  'lingqukongtou': 'Reivindicar o airdrop',
  'fenpeizhuzaoguize':'Regra de Distribuição e Cunhagem',
  'nezhaoriental': 'A produção total de NEZHA Oriental é de 10.000, e entre eles, 1.000 são reservados pelo grupo, 1.000 são realizando o airdrop para a comunidade e 8,000 são cunhados para a venda',
  'tuanduibaoliu': 'Reservado pelo grupo',
  'kaifatuandui': '500 para o grupo de desenvolvedores e 500 para o grupo de IP',
  'shequkongtou': "Airdrop para a Comunidade",
  'rengouzhiyabaimingdan': "1.000 lista branca de compra/aposta",
  'zhuzaofashou': "Cunhagem para a Venda",
  '8000mint': "8,000 Mint dentro 1 a 3, pelo maior 3 NFTs",
  'zhuzaoguize': "Regra de Cunhagem",
  '8000manghe': "8,000 para a cunhagem das caixas cegas, e são vendidos através do canal de cunhagem na página principal. A caixa cega produz 3 NEZHAs por hora, 72 NEZHAs por dia, e os usuários podem comprar 3 NEZHAs pelo máximo.",
  'womenjiangchangqi': "Vamos realizar o planejamento de NFT de acordo com este roteiro por um longo tempo",
  'womenweinezha': "Planejamos uma rota de desenvolvimento para NEZHA. Uma vez atingida a meta da porcentagem das vendas, nos esforçaremos para atingir as metas estabelecidas",
  "xianshanghualang": "Galeria on-line",
  "nezhachaowan": "Desenvolvimento dos Derivados de NEZHA Tide Play",
  "xianxiaip": "Tournée conjunta de IP offline",
  "nezhadujia": "Produtos Exclusivos/ Coleção de Edição Limitada de NEZHA",
  "goumaixuni": "Compre um terreno virtual para exibir o metaespaço",
  "xianxiashuzi": "Leilão de Arte Digital off-line",
  "OpenSea": "Recompra de OpenSea",
  "qidianjuhui": "Comemoração & Festas",
  "zhuzaozongliang": "Soma de Cunhagem",
  "zhuzajiage": "Preço de Cunhagem",
  "yixuan":"Selecionado",
  "zongji":"Soma",
  "zhuzaowancheng":"Cunhagem Pronta",
  "ccobanquan":" Este NEZHA é cunhado de acordo com o contrato da declaração dos direitos autorais de CC0",
  "kongtounezha":"Airdrop de NEZHA",
  "kongtouzongliang":"Soma de Airdrop",
  "yikongtou":"Airdrop com sucesso",
  "wodekongtou":"Meu Airdrop",
  "buzaibaimingdan":"Fora da Lista Branca",
  "zhuzaochenggong":"Cunhagem com secesso",

  "yaoqinglianjie": 'Link de Convite',
  "fuzhi": "Copiar",
  "fuzhichenggong": "Copiar com sucesso",
  "dakaiqianbao": "xxx",
  "lianjiefuzhi": "xxx"
}