    import Vue from 'vue';
    import Vuex from 'vuex';
    import Web3 from 'web3';
    import API from '../assets/js/API';
    import abi from './../assets/js/XRC20abi'
    import utils from './../utils/utils';
    Vue.use(Vuex);
    let interval = null;
    const state = {
      userAddress: '',
      h5address:'',
      UMI_contract: null,
      USDC_contract: null,
      NFT_contract: null,
      DAO_contract: null,
      LP_contract: null,
      balance: {
        UMIBalance: null,
        USDCBalance: null,
        ETHBalance: null,
        NFTBalacnce: null,
        LPBalacnce: null
      },
      allowance: {
        UMIAllown: null,
        USDCAllown: null,
        ETHAllown: null,
      },
      UMI_USDC: 0,
      USDC_ETH: 0,
      NFTPrice: 1.0,
      NFTList: [],
      lockInfo: {},
      discount: [0,0,0],

      castCountTime: 0, // 铸造倒计时时间
      notMint: false, // ture不显示mint页面，false 展示 mint的界面
    };
    
    const store = new Vuex.Store({
      state,
      mutations: {
        setUseraddress(state,data) {
          state.userAddress = data
        },
        setLP_Contract(state,data){
          state.LP_contract = data;
        },
        setUMI_Contract(state,data){
          state.UMI_contract = data;
        },
        setUSDC_Contract(state,data){
          state.USDC_contract = data;
        },
        setNFT_Contract(state,data){
          state.NFT_contract = data;
        },
        setDAO_Contract(state,data){
          state.DAO_contract = data;
        },
        setBalance(state,data) {
          state.balance = data
        },
        setUMI_USDC(state,data) {
          // console.log(data)
          state.UMI_USDC = data;
        },
        setUSDC_ETH(state,data) {
          state.USDC_ETH = data;
        },
        setNFTList(state,data) {
          state.NFTList = data;
        },
        setLockInfo(state,data) {
          state.lockInfo = data;
        },
        setDiscount(state,data) {
          state.discount = data
        },
        setNFTPrice(state,data) {
          state.NFTPrice = data
        },
        setCastCountTime(state,data) {
          state.castCountTime = data
        },
        setNotMint(state,data) {
          state.notMint = data;
        },
      },
      actions: {
        async getPrice(state) {
          const web3jsobj = new Web3(window.ethereum);
          const DAOContract = new web3jsobj.eth.Contract(abi.poolabi, API.DAO_Addr, {});
          // const NFTContract = new web3jsobj.eth.Contract(abi.nftabi, API.NFT_Addr, {});
          interval = setInterval(async()=>{
            try {
              const res1 =  await DAOContract.methods.quote2usdc(utils.mathlog(1,API.UMI_Demical)).call();
              // const res2 = await DAOContract.methods.generalExchange(1000,API.USDC_Addr,utils.mathlog(1,8),API.ETH_Addr).call();
              // console.log('price',res1)
              state.commit('setUMI_USDC',utils.mathpow(res1,API.USDC_Demical));
              // state.commit('setUSDC_ETH',(utils.mathpow(res2,18)*1).toFixed(18));
              
            } catch (error) {
              // console.log('price',error)
            }
           
          },10000);
        },
        async initContartInfo(state,data) {
          const { web3js,userAddr } = data;
          utils.uploadInvite(userAddr);
          // UMI 
          const UMIContract = new web3js.eth.Contract(abi.poolabi, API.UMI_Addr, { from: userAddr });
          const UMIres = await UMIContract.methods.balanceOf(userAddr).call();
          const UMIBalance = utils.mathpow(UMIres,API.UMI_Demical);
          // USDC
          const USDCContract = new web3js.eth.Contract(abi.poolabi, API.USDC_Addr, { from: userAddr });
          const USDCres = await USDCContract.methods.balanceOf(userAddr).call();
          const USDCBalance = utils.mathpow(USDCres,API.USDC_Demical);

          // ETH
          const ETHres = await web3js.eth.getBalance(userAddr);
          const ETHBalance = utils.mathpow(ETHres,API.ETH_Demical);
          // console.log(ETHBalance)
          // const ETHallow = await contract.methods.allowance(userAddr,API.ETH_Addr).call({from: userAddr});
          // const ETHAllown = utils.mathpow(ETHallow,API.ETH_Demical);

          // lp
          const LPContract = new web3js.eth.Contract(abi.nftabi, API.LP_Addr, { from: userAddr });
          // console.log('LPContractLPContract',LPContract)
          const LPBalacnce= await LPContract.methods.balanceOf(userAddr).call();
          // console.log('LPBalacnce',LPBalacnce)
          // NFT 
          const NFTContract = new web3js.eth.Contract(abi.nftabi, API.NFT_Addr, { from: userAddr });
          const NFTBalacnce = await NFTContract.methods.balanceOf(userAddr).call();
          const NFTStartTime = await NFTContract.methods.nftStartDay().call();
          const notMint = await NFTContract.methods.paused().call();
          // console.log('Ispaused',NFTStartTime)
          let count_time = 0;
          if(NFTStartTime != 0) { // 不等于 0 才能获取价格
            count_time =(Number(NFTStartTime) + (8000 / 3 * 3600)) * 1000;
            const res3 = await NFTContract.methods.getCurrentPrice().call();
            // console.log('nfprice**t',utils.mathpow(res3,18)*1)
            store.commit('setNFTPrice',(utils.mathpow(res3,18)*1).toFixed(18))
          }
          
          // console.log('NFTBalacnceNFTBalacnceNFTBalacnce',NFTBalacnce)
          var nftList = []; // nft 列表
          if(!!(NFTBalacnce * 1)) {
            for( let i = 0;i<NFTBalacnce;i++) {
              const res = await NFTContract.methods.tokenOfOwnerByIndex(userAddr,i).call({from: userAddr});
              nftList.push(res); 
            }
          }
          // console.log('coomit ',nftList)
          
          // const NFTBalance = utils.mathpow(NFTres,abi.NFT_Demical);
          // DAO
          const DAOContract = new web3js.eth.Contract(abi.poolabi, API.DAO_Addr, { from: userAddr });
          const lockInfo = await DAOContract.methods.lockinfo().call({from: userAddr});
          // console.log(lockInfo)
          const res1  = await DAOContract.methods.getDaoDiscount().call({from: userAddr});  
          
          // const DAOres = await DAOContract.methods.balanceOf(userAddr).call();
          // const DAOBalance = utils.mathpow(DAOres,abi.DAO_Demical);

          
          
          //  commit  
          state.commit('setLP_Contract',LPContract);
          state.commit('setUMI_Contract',UMIContract);
          state.commit('setUSDC_Contract',USDCContract);
          state.commit('setNFT_Contract',NFTContract);
          state.commit('setDAO_Contract',DAOContract);
          state.commit('setBalance',{UMIBalance,USDCBalance,ETHBalance,NFTBalacnce,LPBalacnce});
          state.commit('setNFTList',nftList);
          state.commit('setLockInfo',{start: lockInfo.daoStartDay, totalRewards: lockInfo.totalReward});
          state.commit('setDiscount', [ res1[0], res1[1], res1[2] ]);
          state.commit('setCastCountTime',count_time);
          state.commit('setNotMint',notMint)
        }
      }
    });
    export default store
