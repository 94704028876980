// 日文
// 中文  key由 文字中的四个字的拼音组成
export const lang = {
  'shouye':'ホーム',
  'rengou':'購入',
  'zhiya':'抵当',
  'lianjieqianbao':'ウォレット接続',
  'quanqiulingxian':'世界一流の暗号化している原住民によるDAO',
  'zhuanzhuyu':'コミュニティと暗号化技術をもって DeFi,、Web3 、メタバースを作り上げ、コミュニティのために、業界成長がもたらす利潤をつかむ。',
  'canyurengou':'購入',
  'daibifenpei':'トークン配分',
  'zongfaxingliang':'総発行数',
  'chubeijin':'準備金',
  'shequeDAO':'コミュニティDAO',
  'liudongxingLP':'流動性LP',
  'tuandui':'チーム',
  'rengoujiangli':'購入奨励',
  'luxiantu':'路線図',
  'jiamijijin':'umiCash暗号化基金創立者',
  'umiCashchengli':'2018.5 umiCash成立',
  'umiCashv1shangxian':'2019.5 umiCash APP v1.0オンライン',
  'umiCashzhishujijin':'2020.5 umiCash指数基金オンライン',
  'umiCashxiaomubiao':'2021.7 umiCash小目標オンライン',
  'hunhedapan':'2021.9ハイブリッドマーケットファンド10純資産が10.0を突破',
  'umiCashzuoshishangban':'2021.12 umiCashマーケットメーカー版オンライン',
  'umiDeFichanpinshangxian':'Q1 umiDeFi製品発売',
  'umiDAOzhililuntan':'Q2 umiDAOガバナンスフォーラムオンライン',
  'UMIDAOzhiliguifan':'UMIDAOガバナンス規範発表',
  'UMIDAOweiyuanhui':'UMIDAO委員会選挙',
  'umiCustodyjiamizhiguan':'umiCustody暗号化資産管理製品',
  'SatQuantDEXlianghua':'SatQuant DEX量化製品',
  'UMIDAOshequqianbao':'UMIDAOコミュニティウォレットマルチシグ配布',
  'umiCashProjiamilianghua':'umiCashPro暗号化量化',
  'FundDappjiamijijin':'Fund Dapp暗号化基金',
  'shequn':'コミュニティ',
  'rengouyibiao':'購入ダッシュボード',
  'woderengou':'購入',
  'rengouzongliang':'購入数（2期）',
  'rengouzhekou':'購入割引',
  'shuocangshouyi':'ロックアップ収益',
  'zongshouyi':'トータル収益',
  'UMIjiage':'UMI価格',
  'rengoujindu':'購入進度',
  'yiwancheng':'完成',
  'shengyu':'残り',
  'danrengouUMI':'UMI購入のみ',
  'shuoding30':'6ヶ月ロックしたら40%リリースし、その後、毎月10%リリースする',
  'rengouzichan':'購入資産',
  'zijinyongtu1':'資金用途：購入金の80％をコミュニティ準備金として、コミュニティマルチシグウォレットが管理する；20％はプロジェクトの運営資金に使う。',
  'qigoujine':'最少購入金額',
  'zuLPrengou' :'LP購入提供',
  'shuoding7':'6ヶ月ロックしたら40%リリースし、その後、毎月10%リリースする',
  'zijinyongtu2':'資金用途：UMI購入の80％はコミュニティ準備金として、20％はプロジェクト運営資金として使う；ほかはUMI-USDC LPの組立に使い、uniswap資金プールに入れる。',
  'lingquUMI':'UMI受取',
  'qigoumenkan':'購入条件',
  'yirengou':'購入済み',
  'yilingqu':'受取済み',
  'kelingqu':'受取可能',
  'daifafang':'配分待ち',
  'nianhuaAPR':'APR',
  'rengoujine':'残高',
  'yue':'残高',
  'duiyingUMI-USDC':'Est. Pool Allocation',
  'duiyingUMI':'購入',
  'danbirengou':'UMI購入のみ',
  'UMIzhekou':'UMI割引',
  'youhui':'割引',
  'daoqilingqu':'取得するUMI',
  'pizhunrengou':'購入批准',
  'rengouchengong':'購入成功',
  'lingqurengouNFT':'NFT購入・受取',
  'gongxi':'おめでとう',
  'lingquchengong':'受取成功',
  'dianjichakan':'クリックして見る',
  'wodeNFT':'NFT',
  'yiqiNFT':'一期NFT',
  'dangqiandizhi':'当アドレスはホワイトリストにない',
  'ninzaiyiqi':'一期NFTホワイトリストにある',
  'lingquyiqi':'一期NFT受取',
  'nftmingzi1':'UMIDAO Membership（Phase I）',
  'nftmingzi2':'UMIDAO Membership（Phase II）',
  'bianhao':'番号',
  'chuangjianshijian':'作成時間',
  'heyuedizhi':'契約アドレス',
  'jiaoyihaxi':'ハッシュ取引',
  'querengoumai':'購入確認',
  'yuebuzu':'残高不足',
  'querenlingqu':'受取確認',
  'zhiyamenkan':'抵当条件',
  'zongshuocang':'TVL',
  'zichanmingchen':'資産名',
  'zhiyazhouqi':'抵当周期',
  'shuhui':'償還',
  'souquanUMIDAO':'UMIDAOに授権して を抵当する',
  'shouquan':'授権',
  'zhiyayue':'抵当残高',
  'daoqijiangli':'満期奨励',
  'jiaogeshijian':'引渡時間',
  'shuhuizhiya':'償還して抵当する',
  'daifafangjiang':'待发放配分待ち奨励奖励',
  'kelingqujiang':'受取可能奨励',
  'daoqihou':'満期後',
  'daoqiriqi':'期限',
  '2022_6_1':'2022年6月1日',
  'dengdaiqueren':'確認待ち',
  'querenzheci':'ウォレットで取引と確認',
  'wodeqianbao':'ウォレット',
  'duankailianjie':'接続オフ',
  'lianjieshiba':'接続失敗',
  'lianjiebeijujie':'接続失敗、拒絶された',
  'wanluocuowu':'网ネットエラー络错误',
  'qingqiehuanwang':'请切换到主网=ネットエラー、メインネットへ切り替え',
  'qiehuanwangluo':'メインネットへ切り替え',
  'pizhunshibai':'批准失敗',
  'pizhunjujie':'批准要請が拒絶された',
  'jiaoyishibai':'取引失敗',
  'jiaoyijujie':'取引要請が拒絶された',
  'lianjieMetamask':'MetaMaskウォレット接続',
  'lianjieWalletConnect':'WalletConnect接続',
  'zhiyachengong':'抵当成功',
  
  'yizhiya':'抵当済み', // 增加
  'month1': '1 ヶ月',
  'month3': '3 ヶ月',
  'month6': '6 ヶ月',
  'month12': '12 ヶ月',
  'gasbutie': 'Gas ボーナス',
  // 
  'jiaoyichenggong': '取引成功',
  'guanbi': '閉じる',
  'yitijiao': '取引提出済み',
  'zhixingchenggong': '取引実行に成功した',
  'dengdaiqueren': '確認待ち',
  'dailingqu': '受取待ち',
  'lingquzhong': '受取中',

  'dengdaishouquan': '授権待ち',
  'shouquanzhong': '授権中',
  'shouquanchenggong': '授権成功',
  'jiaoyizhong': '取引中',
  'dengdaishuhui': '償還待ち',
  'shuhuizhong': '償還中',
  'shuhuichenggong': '償還成功',
  'zhiyazhong': 'ステーキング中',
  'goumaichenggong': '購入成功',
  'yisifang': 'リリース済み',
  'shouquanrengou': '授権購入',
  'zhiyashijian': 'ステークス時間',
'keyong1': '利用可能な UMI  ',
    'shurutokenid': 'LP TokenD入力',
  'tokeindcuowu': 'TokenIDエラー',
  'qianbao': 'ウォレット',

  'nftbannertxt': '未来のメタバースでは、資本が科学技術を独占し、一般人をコントロールしようとしている。夜の街はビルが立ち並び、機械でできている巨大なドラゴン模様のボートはビルの間を飛んでいる。地上では、スラムの隅にいる貧しい人々が震えている。ここにあるのは資本とアリだけで、犯罪と迷子が溢れている。ブラックテクノロジーを持っているNEZHAは資本の秘密を暴露し、全ての嘘を炎で焼き尽くし、隠されている真実を探そうとしている',
  'nezhamanghe': 'NEZHA ブラインドボックス',
  'tongguozhuzao': '鋳造でNEZHA NFTが得られ、UMIとステークスLPの購入をすれば、エアドロップNFTを受取できる',
  'zhuzao': '鋳造',
  'meixiaochannft': '1時間に三つ、1日に72個のNFTを鋳造する',
  'kaishizhuzao': '鋳造開始',
  'kongtou': 'エアドロップ',
  'rengouzhiyalingqunft': 'UMIとステークスLPの購入でNFTエアドロップを受け取る',
  'lingqukongtou': 'エアドロップ受取',
  'fenpeizhuzaoguize':'分配と鋳造ルール',
  'nezhaoriental': 'NEZHA Orientalは合計10000個のNEZHAを作り、そのうち、チームが1000個を保留し、コミュニティに1000個エアドロップし、8000個は発売する',
  'tuanduibaoliu': 'チーム保留',
  'kaifatuandui': '開発チームが500個、IPチームが500個',
  'shequkongtou': "コミュニティエアドロップ",
  'rengouzhiyabaimingdan': "1000個の購入・ステークスホワイトリスト",
  'zhuzaofashou': "鋳造発売",
  '8000mint': "8000個のMINTは1-3個で、最多三つのNFT",
  'zhuzaoguize': "鋳造ルール",
  '8000manghe': "8000個のブラインドボックスはホームページの鋳造チャンネルで発売する。ブラインドボックスは1時間に三つ、１日に72個のNEZHAを作り、ユーザーは最多、三つのNEZHA NFTを購入できる",
  'womenjiangchangqi': "長期的には、この道路地図に沿ってNFT企画を立てる",
  'womenweinezha': "NEZHAに一つの発展計画を立てた。目標の販売比率に達すれば、既定の目標に向かって頑張る",
  "xianshanghualang": "オンラインギャラリー",
  "nezhachaowan": "NEZHAミニフィギュア・グッズ開発",
  "xianxiaip": "オフラインIP連合展示ツアー",
  "nezhadujia": "NEZHA独自商品/限定版コレクション",
  "goumaixuni": "バーチャル土地を購入してメタバースを展示する",
  "xianxiashuzi": "オフラインデジタルアートオークション",
  "OpenSea": "OpenSea 買い戻し",
  "qidianjuhui": "祭&パーティー",
  "zhuzaozongliang": "鋳造合計数",
  "zhuzajiage": "鋳造価格",
  "yixuan":"選択済み",
  "zongji":"合計",
  "zhuzaowancheng":"鋳造完成",
  "ccobanquan":"NEZHA NFTはCC0版権申告契約をもって鋳造を行う",
  "kongtounezha":"NEZHAエアドロップ",
  "kongtouzongliang":"エアドロップ総量",
  "yikongtou":"エアドロップ済み",
  "wodekongtou":"マイエアドロップ",
  "buzaibaimingdan":"ホワイトリストにない",
  "zhuzaochenggong":"鋳造成功",

  "yaoqinglianjie": '招待リンク',
  "fuzhi": "コピー",
  "fuzhichenggong": "コピー成功",
  "dakaiqianbao": "分散型ウォレットを開く",
  "lianjiefuzhi": "サイトのリンクをコピーし、ウォレットブラウザで開いて鋳造する"
}