<template>
  <div class="buyDetail">
    <div class="card">
      <div class="title_flex">
       <p>{{$t("lang.rengou")}}</p>
        <p>${{($store.state.UMI_USDC * 1).toFixed(3)}}</p>
      </div>
      <p class="title_text1">{{$t("lang.UMIjiage")}}</p>
      <div class="form_view">
        <div class="form_title_flex">
          <p>{{$t("lang.rengoujine")}}</p>
          <!-- <p class="form_title_flex_right" @click="changeBuyType">{{buyType==1?$t("lang.zuLPrengou"):$t("lang.danbirengou")}}</p> -->
          <p></p>
        </div>
        <div class="input_view" :style="isfocus && {background: '#EEF8F4'}">
          <input
            type="number"
            v-model="inputValue" 
            :disabled="!$store.state.userAddress || !($store.state.UMI_USDC*1)"
            @blur="isfocus = false"
            @focus="isfocus = true"
            :style="isfocus && {background: '#EEF8F4',caretColor: '#6fcaa0'}"
          />
          <div class="input_right">
            <img src="./../../assets/image/coin_USDC.png" alt="" class="input_right_coin">
            <p class="input_right_name">{{moneyUnit}}</p>
            <!-- <img src="./../../assets/image/select_icon.png" alt="" style="width:14px;">
            <div class="select_coin">
              <el-select v-model="moneyUnit" placeholder="请选择" style="width:100%;height:100%">
                <el-option label="ETH" value="ETH"></el-option>
                <el-option label="USDC" value="USDC"></el-option>
              </el-select>
            </div> -->
          </div>
        </div>
        <p class="form_view_balance">{{$t("lang.yue")}}：{{ numberTofixed($store.state.balance[`${moneyUnit}Balance`])}}{{moneyUnit }}</p>

        <div class="form_title_flex">
          <p>{{buyType == 1 ? $t("lang.duiyingUMI-USDC") : $t("lang.duiyingUMI")}}</p>
          <p></p>
        </div>
        <div class="input_view" style="background:#f0f0f0">
          <input type="text" readonly :value="moneyUMI">
          <div class="input_right">
            <img src="./../../assets/image/coin_UMI.png" alt="" class="input_right_coin">
            <p class="input_right_name">UMI</p>
            <!-- <img src="./../../assets/image/select_icon.png" alt="" style="width:14px;">
            <div class="select_coin">
              
            </div> -->
          </div>
        </div>
        <!-- 组队认购才会显示 -->
        <div class="form_view_text_flex">
          <p>{{$t("lang.UMIzhekou")}}：{{(discount * 100).toFixed(2)}}%</p>
          <p v-if="buyType == 1">{{$t("lang.youhui")}} {{(inputValue * discount).toFixed(3)}}</p>
          <!-- <p v-else>1 {{moneyUnit}} = {{moneyUnit=='ETH'? UMI_ETH : (1 / $store.state.UMI_USDC).toFixed(3)}} UMI</p> -->
        </div>
        <div class="input_view" v-if="buyType == 1" style="background:#f0f0f0">
          <input type="text" readonly :value="moneyUSDC">
          <div class="input_right">
            <img src="./../../assets/image/nft2.png" alt="" class="input_right_coin">
            <p class="input_right_name">USDC</p>
            <!-- <img src="./../../assets/image/select_icon.png" alt="" style="width:14px;"> -->
            <div class="select_coin">
              <!-- select  -->
            </div>
          </div>
        </div>
        <p class="from_text1">{{$t("lang.daoqilingqu")}}</p>
        <p class="from_text2"><span>{{rewards ? `${rewards}+100` : '0.00'}}</span> {{rewards ? `(${$t("lang.gasbutie")})` : ''}}</p>
        <!-- 按钮区域 -->
        <div class="can_buy_btn" v-if="!$store.state.userAddress" @click="openLinkWallet">{{$t("lang.lianjieqianbao")}}</div>
        <div class="can_buy_btn" v-else-if="noBalance">{{$t("lang.yuebuzu")}}</div>

        <div v-else>
          <!-- 授权的按钮 -->
          <div class="can_buy_btn" v-if="moneyUnit=='USDC' && !USDCAllow">
            <UmiButton
              :loading="approvingStatus==2"
              :text="approvingStatusTxt"
              @clickFn="approveFn('USDC')"
              :h5Btn="true"
            />
          </div>
          <!-- 已认购 -->
          <div class="buy_btn_nobalance get_ed" v-if="(info.buy_ed*1 > 0)">{{$t('lang.goumaichenggong')}}</div>
          <!-- 购买按钮 -->
          <div class="can_buy_btn" v-else v-show="USDCAllow" :style="buyingStatus==3&& {background:'#EEEEEE',color:'#C7C7C7'}">
            <UmiButton
              :loading="buyingStatus==2"
              :text="buyingStatusTxt"
              @clickFn="buyUMI"
              :disable="disbaleBuy"
              :h5Btn="true"
            />
          </div>
        </div>
        
      </div>
      <div class="form_view">
        <div class="get_title_flex">
          <div class="get_title_flex_left">
            <img src="./../../assets/image/nft.png" alt="">
            <p>UMI</p>
            <span v-if="buyType==1">/</span>
            <img src="./../../assets/image/nft2.png" alt="" v-if="buyType==1">
            <p v-if="buyType==1">USDC</p>
          </div>
          <div class="get_title_btn" @click="openGetUMIModal" :style="!(info.get_can*1) && {opacity:'0.7',cursor:'not-allowed'}" >{{$t("lang.lingquUMI")}}</div>
        </div>

        <div class="from_info_flex">
          <p>{{$t("lang.shuocangshouyi")}}</p>
          <p>{{buyType ==1 ? '18.00%' : '9.00%'}}</p>
        </div>
        <div class="from_info_flex">
          <p>{{$t("lang.rengouzhekou")}}</p>
          <p>{{(discount * 100).toFixed(2) }}%</p>
        </div>
        <div class="from_info_flex">
          <p>{{$t("lang.qigoumenkan")}}</p>
          <p>${{buyType==2 ? '2000.00' : '5000.00'}}</p>
        </div>
        <div class="from_info_flex">
          <p>{{$t("lang.yirengou")}}</p>
          <p>{{info.buy_ed}}UMI</p>
        </div>
        <div class="from_info_flex">
          <p>{{$t("lang.yisifang")}}</p>
          <p>{{info.get_ed}}UMI</p>
        </div>
        <div class="from_info_flex">
          <p>{{$t("lang.kelingqu")}}</p>
          <p>{{info.get_can}}UMI</p>
        </div>
        <div class="from_info_flex">
          <p>{{$t("lang.yilingqu")}}</p>
          <p>{{info.get_will}}UMI</p>
        </div>
        <!-- <div class="from_info_flex">
          <p>{{$t("lang.rengouzongliang")}}</p>
          <p>2,000,000UMI</p>
        </div> -->
        <!-- <div class="from_info_flex">
          <p>认购折扣</p>
          <p>2,000,000UMI</p>
        </div> -->
      </div>
    </div>
    <!-- <p class="buy_detail_tip">{{$t("lang.zijinyongtu1")}}</p> -->

    <PcModal v-if="showModal" @closeModal="closePCmodal" :title="modalTitle">
      <!-- loading -->
      <div slot="content" v-if="modalType==0" class="buy_succes_modal_h5"><modalLoading :h5="true"/></div>
      <!-- 交易已提交 -->
      <div slot="content" v-if="modalType ==1" class="buy_succes_modal_h5">
        <div class="buy_succes_modal_content_h5">
          <img src="../../assets/image/arrow_top.png" alt="" style="width:67px;margin:0 auto;">
          <p style="text-align:center">{{$t("lang.yitijiao")}}</p>
        </div>
        <div class="buy_succes_modal_btn_h5" @click="closePCmodal">{{$t("lang.guanbi")}}</div>
      </div>
      <!-- 交易成功 -->
      <div slot="content" v-if="modalType == 2" class="buy_succes_modal_h5">
        <div class="buy_succes_modal_content_h5">
          <img src="../../assets/image/success1.png" alt="" style="width:67px;margin:0 auto;">
          <p>  </p>
        </div>
        <div class="buy_succes_modal_btn_h5"  @click="closePCmodal">{{$t("lang.guanbi")}}</div>
      </div>
      <!-- 交易失败 -->
      <div slot="content" v-if="modalType ==3" class="buy_succes_modal_h5">
        <div class="buy_succes_modal_content_h5">
          <img src="../../assets/image/close_circle.png" alt="" style="width:67px;margin:0 auto;">
          <p>{{$t("lang.jiaoyijujie")}}</p>
        </div>
        <div class="buy_succes_modal_btn_h5" @click="closePCmodal">{{$t("lang.guanbi")}}</div>
      </div>
      
    </PcModal>

    <!-- 领取UMImodal  showGetUMI -->
    <PcModal  v-if="showGetUMI" @closeModal="closeGetUMI" :title="$t('lang.lingquUMI')">
      <div slot="content" class="get_UMI_modal_content">
        <div class="UMI_nums" v-if="getUMIStatus==1">
          <img src="./../../assets/image/coin_UMI.png" alt="">
          <p>{{info.get_can}}UMI</p>
        </div>
        <div class="UMI_nums" v-if="getUMIStatus==2" style="margin-bottom:50px">
          <modalLoading :h5="true"/>
        </div>
        <div class="UMI_nums" v-if="getUMIStatus==3" style="margin-bottom:50px">
          <img src="./../../assets/image/success1.png" alt="" v-if="getUMIStatus==3" style="width:67px;height:auto;margin:0">
        </div>
        <div class="get_umi_btn can_get" v-if="getUMIStatus==1" @click="getUMIFn">{{$t("lang.querenlingqu")}}</div>
        <div class="get_umi_btn get_ing" v-if="getUMIStatus==2">{{$t("lang.querenlingqu")}}</div>
        <div class="get_umi_btn can_get" v-if="getUMIStatus==3" @click="closeGetUMI">{{$t("lang.lingquchengong")}}</div>
        <!-- <div class="get_status_view">
          <div class="get_status_view_img">
            <img src="./../../assets/image/waite.png" alt="" v-if="getUMIStatus==1" style="width:15px">
            <loadingIcon v-if="getUMIStatus==2" svgW="18px" svgH="18px"/>
            <img src="./../../assets/image/success.png" alt="" v-if="getUMIStatus==3">
          </div>
          
          <p>{{getUMIStatus==1 ? `${$t("lang.dailingqu")}...` : (getUMIStatus==2 ? `${$t("lang.lingquzhong")}...` : $t("lang.lingquchengong"))}}</p>
        </div> -->
      </div>
    </PcModal>
    
  </div>
</template>

<script>
import utils from "@/utils/utils";
import PcModal from '@/components/H5Modal';
import UmiButton from '../../components/UmiButton.vue';
import modalLoading from '@/components/loading.vue';
import LoadingIcon from '../../components/loadingIcon.vue';
var shownotify = false;
var shownotify1 = false;
export default {
  name: "buy",
  components: {
    PcModal,
    modalLoading,
    UmiButton,
    LoadingIcon
  },
  data() {
    return {
      isTeam: false,

      buyType: 2,
      // isApprove: true,
      approvingStatus: 1, // 1可点击授权 2 授权中不能点击
      approvingStatusTxt: this.$t('lang.shouquanrengou'),

      ETHAllow: null,
      USDCAllow: null,
      
      buyingStatus: 1, // 1可点击购买 2 购买中不能点击 3 购买成功不可点击
      buyingStatusTxt:  this.$t('lang.querengoumai'),
      disbaleBuy: true,
      moneyUnit: 'USDC',
      inputValue: '',
      showModal: false,
      modalType: 1, // 0 loading, 1 已提交  2 成功 3 失败 
      info: {
        buy_ed: 0,
        get_will: 0,
        get_ed: 0,
        get_can: 0
      },
      moneyUMI: '',
      moneyUSDC: '',
      // 领取nft成功
      getNFTModal: false,
      // 折扣
      discount: 0,
      // modal  title
      modalTitle: this.$t("lang.dengdaiqueren"),
      // 到期领取奖励
      rewards: 0,
      noBalance: false,

      // get umi modal 
      showGetUMI: false,
      getUMIStatus: 1,

      isfocus: false
    };
  },
  mounted() {
    if(!this.$store.state.userAddress) {
      this.$emit('openLinkWallet');
      return 
    }
    this.noBalance = !((this.$store.state.balance.USDCBalance*1) > 0)
    // this.buyType = this.$route.query.type || 2;
    this.getAllow();
    this.getInfo();
    this.getDiscount();
  },
  computed: {
    UMI_ETH() {
      const { UMI_USDC, USDC_ETH } = this.$store.state;
      const res = utils.division(utils.division(1,UMI_USDC),USDC_ETH)
      return Number(res).toFixed(3)
    }
  },
  watch: {
    // "$route.query.type"() {
    //   this.buyType = this.$route.query.type;
    // },
    // 监听输入框
    inputValue(){
      let value = '';
      let value1 = '';
      const { UMI_USDC, USDC_ETH } = this.$store.state;
      if(this.inputValue * 1) {
        const res =  this.buyType == 1 ? this.inputValue / (UMI_USDC*(1 + (1 - this.discount))) : this.inputValue/ (1-this.discount) /  UMI_USDC  //  : this.inputValue / (UMI_USDC * USDC_ETH);
        const res1 =  this.inputValue / (1 + (1 - this.discount)) // : this.inputValue / USDC_ETH;
        value = res.toFixed(3);
        value1 = res1.toFixed(3);
      }
      this.moneyUMI = value;
      this.moneyUSDC = value1;
      this.rewards = this.geRewards(value);
      // 余额不足的情况 展示余额不足
      if(this.inputValue * 1 > this.$store.state.balance[`${this.moneyUnit}Balance`]) {
        this.noBalance = true;
      } else {
        this.noBalance = false;
      }
      // 认购按钮 是否可以点击认购条件
      if(this.inputValue * 1 >= 2000) {
        this.disbaleBuy = false;
      } else {
        this.disbaleBuy = true
      }
    },
    // 购买单位
    moneyUnit() {
      this.inputValue = '';
    },
    // 弹窗内容类型
    modalType() {
      if(this.modalType == 3) { // 交易失败
        this.modalTitle = this.$t("lang.jiaoyishibai");
      } else if(this.modalType == 1) { //交易成功
        this.modalTitle = this.$t("lang.jiaoyichenggong");
      } else if(this.modalType == 2) { //交易提交
        this.modalTitle = this.$t("lang.yitijiao");
      } else if(this.modalType == 0) { // 等待确认
        this.modalTitle = this.$t("lang.dengdaiqueren");
      } 
    },
    // 弹窗显示 关闭
    showModal() {
      if(!this.showModal) {
        this.modalTitle =  '';
        this.modalType = 0;
      }
    },
    "$store.state.balance.USDCBalance"() {
      if(this.$store.state.balance.USDCBalance) {
        this.noBalance = false;
      }
    },
    "$store.state.DAO_contract"() {
      this.getInfo();
      this.getAllow();
      this.getDiscount();
    },
    buyType() {
      this.inputValue = '';
      this.moneyUMI = '';
      this.moneyUSDC = '';
      this.rewards = '';
    }
  },
  methods: {
    //
    openLinkWallet() {
      this.$emit("openLinkWallet")
    },
    // 到期领取奖励计算
    geRewards(value) {
      const { UMI_USDC, USDC_ETH } = this.$store.state;
      try {
        if(this.buyType == 1) {
          const P = this.moneyUnit=='ETH' ? (UMI_USDC *USDC_ETH ) : UMI_USDC;
          const lptop = this.$utils.multiplication(2*1.18,this.inputValue);
          const lpbom = this.$utils.multiplication( P,(1 + ( 1 - this.discount )) );
          const res = this.$utils.division(lptop,lpbom)
          return Number(res).toFixed(3)
        } else {
          const singletop = this.$utils.multiplication(this.inputValue/(1-this.discount), 1.09); // 1.09:  1 + r;   r为9%
          const res = this.$utils.division(singletop,UMI_USDC)
          return Number(res).toFixed(3)
        }
      } catch (error) {
        return ''
      }
      // const lp = (2* this.inputValue * 118) / (this.UMI_ETH * (100 + ( 1- this.discount )*100));
    },
    getDiscount() {
      const { lockInfo, discount } = this.$store.state;
      const time = new Date().getTime() - Number(lockInfo.start+'000');
      // <!-- 1-30天内折扣为 0.15；31-90天内折扣为0.10； 91天以后折扣为0.05； -->
      const day = time / 1000 / 3600 / 24;
      // console.log(day)
      if(day < 30) {
        this.discount =  ((100 - discount[0]) / 100).toFixed(2) * 1;
      } else if (day > 90) {
        this.discount = ((100 - discount[2]) / 100).toFixed(2) * 1;
      } else {
        this.discount = ((100 - discount[1]) / 100).toFixed(2) * 1;
      }
    },
    lookNft() {
      this.$router.push('/mynft');
    },
    getNft() {
      this.closePCmodal();
      this.getNFTModal = true;
    },
    closePCmodal() {
      this.showModal = false;
      this.modalType = 0;
    },
    changeBuyType() {
      if(this.buyType == 1) {
        this.buyType = 2;
      } else {
        this.buyType = 1;
      }
    },
    async getAllow() {
      const { UMI_contract, userAddress, USDC_contract, } = this.$store.state;
      const USDCallow = await USDC_contract.methods.allowance(userAddress,this.$api.DAO_Addr).call({from: userAddress});
      this.USDCAllow = utils.mathpow(USDCallow,this.$api.USDC_Demical) * 1;
      // console.log(utils.mathpow(USDCallow,this.$api.USDC_Demical))
      // const ETHallow = await USDC_contract.methods.allowance(UMI_contract,API.UMI_Addr).call({from: userAddr});
    },
    async regetData() {
      const { UMI_contract, USDC_contract, userAddress, balance } = this.$store.state;
      const UMIres = await UMI_contract.methods.balanceOf(userAddress).call();
      const UMIBalance = utils.mathpow(UMIres,this.$api.USDC_Demical);
      const USDCres = await USDC_contract.methods.balanceOf(userAddress).call();
      const USDCBalance = utils.mathpow(USDCres,this.$api.USDC_Demical);  
        if(balance.USDCBalance == USDCBalance) {
          timer = setTimeout(()=>{ this.regetData()},3000);
          return 
        }
        
        this.$store.commit('setBalance',{...balance,UMIBalance,USDCBalance});
    },
    async getInfo(reoload,type) {
      const { userAddress, DAO_contract } = this.$store.state;
      try {
        var res1  = await DAO_contract.methods.getBondsReward(userAddress).call({from: userAddress});  
      } catch (error) {
        // console.log('getBondsReward`',error)
      }
      
      if(reoload) {
        const again = type == 2 ? this.info.get_ed == this.numberTofixed(utils.mathpow(res1.takedAmount,this.$api.UMI_Demical)) : this.buy_ed == this.numberTofixed(utils.mathpow(res1.origAmount,this.$api.UMI_Demical))
        if(again) {
          setTimeout(()=> {
            this.getInfo(reoload,type)
          },2000)
          return 
        }
      }
      // 新的合约 改了 只需要调用getBondsReward  就可以拿 所有需要的数据了
      const info  = {
        buy_ed: this.numberTofixed(utils.mathpow(res1.origAmount,this.$api.UMI_Demical)), // 已认购
        get_will: this.numberTofixed(utils.mathpow(res1.takedAmount,this.$api.UMI_Demical)), // 已领取
        get_ed: this.numberTofixed(utils.mathpow((res1.takedAmount*1)+(res1.unlockAmount*1),this.$api.UMI_Demical)), // 已释放
        get_can: this.numberTofixed(utils.mathpow((res1.unlockAmount*1),this.$api.UMI_Demical)), // 可领取
      }
      this.info = info;
    },
    async approveFn(type) {
    
      const { UMI_contract, userAddress, USDC_contract, } = this.$store.state;
      const contract = type == 'ETH' ? null : USDC_contract;
      try {
        this.approvingStatusTxt = this.$t("lang.dengdaishouquan");
        this.approvingStatus = 2;
        const res = await contract.methods.approve(this.$api.DAO_Addr,utils.mathlog(1000000000000,this.$api[`USDC_Demical`])).send({from: userAddress});
        // // console.log('approve',utils.mathlog(10000,this.$api[`USDC_Demical`]))
        this.USDCAllow = 100000;
        this.approvingStatus = 1;
        this.$notify({
          title: '',
          duration: 8000,
          dangerouslyUseHTMLString: true,
          message: `<div class="notify_success_view_h5"><img src="${require("../../assets/image/success.png")}"/><p>${this.$t('lang.shouquanchenggong')}</p></div>`
        });
      } catch (error) {
        this.USDCAllow = null;
        this.approvingStatus = 1;
        this.approvingStatusTxt = this.$t('lang.shouquanrengou');
      }
      
    },
    buyUMI() {
      const type = this.buyType == 1 ? 5 : 4;
      // const minValue = type==5 ? 5000 : 2000;
      // if ((this.moneyUMI* 1) < minValue) {
      //   this.$message(`购买数量不能低于${minValue}！`)
      //   return 
      // }
      // if(this.moneyUnit == 'ETH') {
      //   this.useETHBuy(type)
      // } else {
        this.modalType = 0;
        this.modalTitle = this.$t("lang.dengdaiqueren");
        this.useUSDCBuy(type)
        
      // }

    },
    // usdc 购买
    async useUSDCBuy(type) {
      shownotify = false;
      this.showModal = true;
      this.buyingStatusTxt = this.$t('lang.dengdaiqueren');
      this.buyingStatus = 2;
      const { DAO_contract, userAddress, USDC_contract, } = this.$store.state;
      const num = this.$utils.mathlog(this.inputValue, this.$api.USDC_Demical);
      // console.log('num',num)
      DAO_contract.methods[this.buyType==1?'bondsLP':'bonds'](num).send({from: userAddress})
      .on('transactionHash', (hash)=>{ // 交易hash
        this.modalType = 1;
        !this.showModal && (this.showModal = true);
      })
      .on('confirmation', (confirmationNumber, receipt)=>{ // 交易已提交
        
      })
      .on('receipt', (receipt)=>{ // 交易已广播
        if(!shownotify) {
          const text = this.$t("lang.goumaichenggong");
          this.$notify({
            title: '',
            duration: 8000,
            dangerouslyUseHTMLString: true,
            message: `<div class="notify_success_view_h5"><img src="${require("../../assets/image/success.png")}"/><p>${text}</p></div>`
          });
          this.inputValue = '';
          this.moneyUMI = '';
          this.moneyUSDC = '';
          this.rewards = '';
          setTimeout(()=> {
            this.showModal = false;
            this.buyingStatusTxt =  this.$t('lang.rengouchengong');
            this.buyingStatus = 3;
            
          },500)
          shownotify = true;
          this.getInfo('reoload',1);
          this.regetData();
        }
      })
      .on('error',(error, receipt)=>{ // 交易失败
        // console.log(error)
        this.modalType = 3;
        this.buyingStatus = 1;
        !this.showModal && (this.showModal = true);
        this.buyingStatusTxt =  this.$t('lang.querengoumai');
      })
    },
    // eth 购买 
    // async useETHBuy() { // 需要设置 gas  未完成
    //   const gasPrice  = await this.web3js.eth.getGasPrice();
    //   this.showModal = true;
    //   const { DAO_contract, userAddress, USDC_contract, } = this.$store.state;
    //   const num = this.web3js.utils.toWei(this.inputValue); //获取当前gas 价格
    //   DAO_contract.methods.ethBonds(num,this.buyType==1 ? true: false).send({from: userAddress,gasPrice,value: num })
    //   .on('transactionHash', (hash)=>{ // 交易hash
    //   })
    //   .on('receipt', (receipt)=>{ // 交易已广播
    //     // console.log('receipt',receipt)
    //     this.modalType = 2;
    //     !this.showModal && (this.showModal = true);
    //   })
    //   .on('error',(error, receipt)=>{ // 交易失败
    //     this.modalType = 3
    //   })

    // },

    numberTofixed(val,fix=3) {
      const res = (val || 0) * 1;
      return res.toFixed(fix)
    },

    // 领取UMI
    getUMIFn() {
      shownotify1 = false;
      this.getUMIStatus = 2;
      const { DAO_contract, userAddress } = this.$store.state;
      DAO_contract.methods
        .withdrawBonds()
        .send({ from: userAddress })
        .on("transactionHash", (hash) => {
          // 交易hash
        })
        .on("receipt", (receipt) => {
          if(!shownotify1) {
            this.getUMIStatus = 3;
            this.$notify({
              title: '',
              duration: 8000,
              dangerouslyUseHTMLString: true,
              message: `<div class="notify_success_view_h5"><img src="${require("../../assets/image/success.png")}"/><p>${this.$t('lang.lingquchengong')}</p></div>`
            });
            setTimeout(()=>{ this.showGetUMI = false },2000)
            this.regetData();
            this.getInfo('reoload',2);
            shownotify1 = true
          }
          
          
        })
        .on("error", (error, receipt) => {
          // 交易失败
          this.modalType = 1;
          this.getUMIStatus = 1;
        });
    },
    openGetUMIModal() {
      if(!(this.info.get_can * 1 )) {
        return 
      }
      this.showGetUMI = true;
    },
    closeGetUMI() {
      this.showGetUMI = false;
    },
  }
};
</script>

<style scoped >
  .card {
    width: 100%;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 0px 6px 0px rgba(147,188,175,1);
    border-radius: 10px;
    margin-top: 20px;
    padding: 16px 15px;
    box-sizing: border-box;
  }
  .title_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    color: #26493E;
  }
  .title_flex p:first-child {
    font-size: 17px;
    
  }
  .title_flex p:last-child {
    font-size: 12px;
  }
  .title_text1 {
    font-size: 11px;
    color: #26493E;
    text-align: right;
    margin-bottom: 19px;
  }
  .form_view {
    width: 100%;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 0px 6px 0px rgba(216,243,230,1);
    border-radius: 10px;
    padding: 14px;
    box-sizing: border-box;
    margin-bottom: 19px;
  }
  .form_title_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    color: #9A9A9A;
  }
  .form_title_flex p:first-child {
    font-size: 17px;
  }
  .form_title_flex_right {
    font-size: 12px;
    padding-left: 19px;
    background: url('./../../assets/image/change.png') no-repeat left center / 15px 17px;
  }
  .input_view {
    display: flex;
    align-items: center;
    width: 100%;
    height: 38px;
    background: #F8F8F8;
    border-radius: 58px;
    margin-bottom: 5px;
    box-sizing: border-box;
  }
  .input_view input {
    flex: 1;
    width: 100px;
    height: 21px;
    line-height: 21px;
    padding: 0 10px;
    box-sizing: border-box;
    border: none;
    outline: none;
    background: none;
    font-size: 15px;
    font-weight: 500;
    color: #26493E;
  }
  .select_coin {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1;
    opacity: 0;
  }
  .input_right {
    flex-shrink: 0;
    width: 105px;
    height: 21px;
    display: flex;
    align-items: center;
    border-left: 1px solid #EEEEEE;
    padding: 0 10px;
    box-sizing: border-box;
    position: relative;
  }
  .input_right_coin {
    width: 19px;
    margin-right: 6px;
  }
  .input_right_name {
    font-size: 17px;
    font-weight: 600;
    color: #26493E;
    margin: 0 5px;
  }
  .form_view_balance {
    font-size: 12px;
    color: #9A9A9A;
    margin-top: 5px;
    margin-bottom: 38px;
  }
  .form_view_text_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 29px;
  }
  .form_view_text_flex p {
    font-size: 12px;
    color: #9A9A9A;
  }
  .from_text1 {
    font-size: 12px;
    color: #9A9A9A;
    margin-top: 98px;
    margin-bottom: 3px;
    text-align: center;
  }
  .from_text2 {
    font-size: 12px;
    color: #769991;
    text-align: center;
    margin-bottom: 10px;
  }
  .from_text2 span{
    font-size: 21px;
    color: #26493E;
  }
  .can_buy_btn,.buy_btn_nobalance {
    width: 90%;
    height: 48px;
    margin: auto;
    text-align: center;
    line-height: 48px;
    font-size: 17px;
    color: #fff;
    background: #6FCAA0;
    border-radius: 30px;
    overflow: hidden;
  }
  .buy_btn_nobalance {
    background: #D8F3E6;
    cursor: not-allowed;
  }
  .get_title_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 19px;
  }
  .get_title_flex_left {
    display: flex;
    align-items: center;
  }
  .get_title_flex_left img {
    height: 19px;
    width: auto;
    margin-right: 3px;
  }
  .get_title_flex_left p {
    font-size: 16px;
    color: #26493E;
    font-weight: 600;
  }
  .get_title_flex_left span {
    font-size: 17px;
    color: #26493E;
    margin: 0 5px;
  }
  .get_title_btn {
    min-width: 77px;
    height: 24px;
    background: #D8F3E6;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
    font-size: 12px;
    padding: 0 5px;
    color: #26493E;
  }
  .from_info_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    font-size: 12px;
  }
  .from_info_flex p:first-child {
    color: #9A9A9A;
  }
  .from_info_flex p:last-child {
    color: #26493E;
  }
  .buy_detail_tip {
    font-size: 12px;
    line-height: 16px;
    color: #769991;
    margin-top: 14px;
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: 50px;
  }
  .get_ed {
    background: #EEEEEE;
    color: #c7c7c7;
    cursor: not-allowed;
  }

  /* umi 领取弹窗 */
  .get_UMI_modal_content {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .UMI_nums {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .UMI_nums img {
    width: 38px;
    height: 38px;
    margin-bottom: 20px;
  }
  .UMI_nums p {
    font-size: 14px;
    font-weight: 600;
    color: #26493E;
    margin-bottom: 26px;
  }
  .get_umi_btn {
    width: 100%;
    height: 60px;
    background: #B4E3CD;
    border-radius: 10px;
    line-height: 60px;
    text-align: center;
    font-size: 14px;
  }
  .can_get {
    background: #6FCAA0;
    color: #FFFFFF;
    cursor: pointer;
  }
  .can_get:hover {
    background: #8DDDB8;
  }
  .get_ing {
    background: #B4E3CD;
    color: #FFFFFF;
    cursor: not-allowed;
  }
  .get_ed {
    background: #EEEEEE;
    color: #c7c7c7;
    cursor: not-allowed;
  }
  .get_status_view {
    margin-top: 20px;
    padding-top: 20px;
    width: 100%;
    display: flex;
    align-items: center; 
    border-top: 1px solid #E0E3E8;
  }
  .get_status_view_img {
    width: 15px;
    min-height: 15px;
    margin-right: 10px;
  }
  .get_status_view img {
    width: 100%;
  }
  .get_status_view p {
    color: #4A4A4A;
    font-size: 14px;
    letter-spacing: 1px;
  }
</style>
<style>
.get_nft_modal_h5 {
  width: 100%;
  min-height: 13rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2.3rem;
}
.get_nft_modal_h5 p {
  font-size: 1rem;
  color: #8B8B8B;
  text-align: left;
  padding-left: 2rem;
  margin-bottom: 5px;
}
.get_nft_modal_h5 div {
  width: 100%;
  height: 40px;
  background: #6FCAA0;
  border-radius: 8px;
  text-align: center;
  line-height: 40px;
  color: #FFFFFF;
  font-size:14px;
  cursor: pointer;
  margin-top: 20px;
}
.buy_succes_modal_h5 {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.buy_succes_modal_content_h5 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 26px;
}
.buy_succes_modal_content_h5 p {
  width: 100%;
  text-align: left;
  font-size: 13px;
  color: #9A9A9A;
  padding: 0 10px;
  margin-top: 10px;
  text-align: center;
}

.buy_succes_modal_btn_h5 {
  width: 100%;
  height: 50px;
  background: #6FCAA0;
  border-radius: 8px;
  text-align: center;
  line-height: 50px;
  color: #FFFFFF;
  font-size: 14px;
  cursor: pointer;
}
.buy_succes_modal_btn_h5:hover {
  background: #8DDDB8;
}


</style>