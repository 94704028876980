<template>
  <div class="modal_loading">
    <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     :width="h5?'72px':'100px'" :height="h5?'72px':'100px'" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
      <path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
        <animateTransform attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="1s"
          repeatCount="indefinite"/>
      </path>
    </svg>
    <p class="loading_text1" v-if="!h5">{{$t("lang.dengdaiqueren")}}</p>
  </div>
</template>

<script>
export default {
  name: "modal_loading",
  props: ["h5"],
  data() {
    return {
    }
  },

  
};
</script>

<style>
.modal_loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal_loading svg path,
.modal_loading svg rect{
  fill: #6FCAA0;
}
.loading_text1 {
  font-size: 1rem;
  font-weight: bold;
  color: #8B8B8B;
  margin-top: 25px;
}
.loading_text2 {
  font-size: 16px;
  font-weight: bold;
  color: #C7C7C7;
  margin-top: 10px;
}
</style>
