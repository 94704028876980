<template>
  <div class="buy">
    <div class="card">
      <div class="title_flex">
       <p>{{$t("lang.rengouyibiao")}}</p>
        <p  @click="goDetail(1)">{{$t("lang.woderengou")}}</p>
      </div>
      <div class="info_flex">
        <p>{{$t("lang.rengouzongliang")}}</p>
        <p class="info_flex_text1">2,000,000UMI</p>
      </div>
      <div class="info_flex">
        <p>{{$t("lang.rengouzhekou")}}</p>
        <p class="info_flex_text1 info_flex_zhekou">{{(discount*1).toFixed(2)}}%</p>
      </div>
      <div class="info_flex">
        <p>{{$t("lang.UMIjiage")}}</p>
        <p class="info_flex_text2">${{($store.state.UMI_USDC * 1).toFixed(3)}}</p>
      </div>
      <p class="progress_title">{{$t("lang.rengoujindu")}}<span>{{ (progressValue / 2000000).toFixed(2)}}%</span></p>
      <div class="progress_view" id="progress_fa">
        <div class="progress_value"  :style="{width: `${progressWidth}px`}"></div>
      </div>
      <div class="info_flex1">
        <p>{{$t("lang.yiwancheng")}}{{(progressValue*1).toFixed(3)}}</p>
        <p>{{$t("lang.shengyu")}} {{dealNumber((2000000 - progressValue).toFixed(3))}}</p>
      </div>
    </div>
    <div class="card2">
      <div class="tab_view">
        <!-- <p :class="`tab_item ${tab==1 && 'tab_item_choose'}`" @click="changeTab(1)">{{$t("lang.zuLPrengou")}}</p> -->
        <p :class="`tab_item `" @click="changeTab(2)">{{$t("lang.danrengouUMI")}}</p>
      </div>
      <!-- <div class="tab_content" v-if="tab==1">
        <p class="tab_content_text1">{{$t("lang.shuoding7")}}</p>
        <div class="tab_content_flex">
          <p>{{$t("lang.rengouzhekou")}}</p>
          <p>{{discount || '--'}}%</p>
        </div>
        <div class="tab_content_flex">
          <p>{{$t("lang.shuocangshouyi")}}</p>
          <p>18.00%</p>
        </div>
        <div class="tab_content_flex">
          <p>ROI</p>
          <p>{{((discount * 1) + 18 ).toFixed(2)}}%</p>
        </div>
        <div class="tab_content_flex">
          <p>{{$t("lang.rengouzichan")}}</p>
          <p>USDC</p>
        </div>
        <p class="tab_content_text2">{{$t("lang.zijinyongtu1")}}</p>
        <p class="tab_content_text4">{{$t("lang.qigoujine")}} 5,000USDC</p>
        <div class="buy_btn"  @click="goDetail(1)">{{$t("lang.canyurengou")}}</div>
      </div> -->
      <div class="tab_content">
        
        <div class="tab_content_flex">
          <p>{{$t("lang.rengouzhekou")}}</p>
          <p>{{discount || '--'}}%</p>
        </div>
        <div class="tab_content_flex">
          <p>{{$t("lang.shuocangshouyi")}}</p>
          <p>9.00%</p>
        </div>
        <div class="tab_content_flex">
          <p>ROI</p>
          <p>{{ ((discount * 1) + 9).toFixed(2) }}%</p>
        </div>
        <div class="tab_content_flex">
          <p>NEZHA NFT</p>
          <p>{{1}} NFT</p>
        </div>
        <p class="tab_content_text1">{{$t("lang.shuoding7")}}</p>
        <!-- <p class="tab_content_text2">{{$t("lang.zijinyongtu2")}}</p> -->
        <p class="tab_content_text4">{{$t("lang.qigoujine")}} 2,000USDC</p>
        <div class="buy_btn"  @click="goDetail(2)">{{$t("lang.canyurengou")}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Notification,Message  } from 'element-ui';
import H5Modal from '@/components/H5Modal'
import utils from "@/utils/utils";
import abi from './../../assets/js/XRC20abi'
export default {
  name: "buy",
  components: {
   H5Modal
  },
  data() {
    return {
      tab: 1,
      showModal: true,
      // 购买进度数量
      progressValue: 0.00,
      progressWidth: 0,
      discount: 10
    };
  },
  computed: {
   
  },
  watch: {
    "$store.state.DAO_contract"() {
      this.getProgress();
    }
  },
  methods: {
    dealNumber(val) {
      return utils.formatNum(val)
    },
    changeTab(val) {
      this.tab = val;
    },
    goDetail(val) {
      this.$router.push({path:'/h5purchaseDetail',query: { type: val}});
    },
    async getProgress() {
      const { DAO_contract,userAddress } = this.$store.state;
      const faWidth = document.getElementById('progress_fa').clientWidth;
      const res = await DAO_contract.methods.totalBondAmount().call({from: userAddress});
      const result = utils.mathpow(res,this.$api.UMI_Demical);
      this.progressValue = result
      this.progressWidth = faWidth * (result / 2000000 );
    },
    async getProgress1() {
      const web3jsobj = new Web3(window.ethereum);
      const DAOContract = new web3jsobj.eth.Contract(abi.poolabi, this.$api.DAO_Addr, {});
      const faWidth = document.getElementById('progress_fa').clientWidth;
      const res = await DAOContract.methods.totalBondAmount().call();
      const result = utils.mathpow(res,this.$api.UMI_Demical);
      this.progressValue = result
      this.progressWidth = faWidth * (result / 2000000 );
    },
    // 获取折扣相关数据
    async getdiscountData() {
      const web3jsobj = new Web3(window.ethereum);
      const DAOContract = new web3jsobj.eth.Contract(abi.poolabi, this.$api.DAO_Addr, {});
      const lockInfo = await DAOContract.methods.lockinfo().call();
      const res  = await DAOContract.methods.getDaoDiscount().call();
      this.getDiscount(lockInfo[2],res);
    },
    getDiscount(start,res) {
      const time = new Date().getTime() - Number(start+'000');
      // console.log(new Date().getTime(),Number(start+'000'))
      
      // <!-- 1-30天内折扣为 0.15；31-90天内折扣为0.10； 91天以后折扣为0.05； -->
      const day = time / 1000 / 3600 / 24;
      // console.log(day)
      if(day < 30) {
        this.discount =  (100 - res[0]).toFixed(2);
      } else if (day > 90) {
        this.discount = (100 - res[2]).toFixed(2);
      } else {
        this.discount = (100 - res[1]).toFixed(2);
      }
    },
  },
  mounted() {
    const { userAddress, lockInfo, discount } = this.$store.state;
    if(userAddress) {
      this.getProgress();
      this.getDiscount(lockInfo.start,discount);
    } else {
      this.getProgress1();
      this.getdiscountData();
    }
  },
};
</script>

<style scoped >
  .card {
    width: 100%;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 0px 6px 0px rgba(147,188,175,1);
    border-radius: 10px;
    margin-top: 20px;
    padding: 16px 20px;
    box-sizing: border-box;
  }
  .title_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .title_flex p:first-child {
    font-size: 17px;
    font-weight: 500;
    color: #26493E;
  }
  .title_flex p:last-child {
    font-size: 12px;
    font-weight: 500;
    color: #6FCAA0;
    padding-right: 12px;
    background: url('./../../assets/image/right_green.png') no-repeat right center;
    background-size: 9px 12px;
  }
  .info_flex,.info_flex1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 12px;
  }
  .info_flex p:first-child {
    font-weight: 500;
    color: #9A9A9A;
  }
  .info_flex_text1 {
    color: #26493E;
  }
  .info_flex_zhekou {
    color: #6FCAA0;
    padding-left: 21px;
    background: url('./../../assets/image/zhekou_icon.png') no-repeat left center / 15px 10px;
  }
  .info_flex_text2 {
    
    color: #26493E;
  }
  .progress_title {
    font-size: 12px;
    font-weight: 500;
    color: #9A9A9A;
    margin-bottom: 5px;
  }
  .progress_title span {
    color: #26493E;
    padding-left: 5px;
  }
  .progress_view {
    width: 100%;
    height: 19px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 0px rgba(216,243,230,1);
    border-radius: 28px;
    position: relative;
    overflow: hidden;
    margin-bottom: 3px;
  }
  .progress_value {
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    height: 19px;
    background: #6FCAA0;
    border-radius: 28px;
  }
  .info_flex1 p:first-child {
    color: #6FCAA0;
  }
  .info_flex1 p:last-child {
    color: #9A9A9A;
  }
  .card2 {
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 0px 6px 0px rgba(147,188,175,1);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 18px 9px;
    margin-top: 20px;
  }
  .tab_view {
    display: flex;
    margin-bottom: 10px;
  }
  .tab_view p {
    flex: 1;
    /* min-height: 44px; */
    text-align: left;
    padding: 0 25px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
  }
  .tab_item {
    font-size: 17px;
    color: #26493E
  }
  .tab_item_choose {
    background: #DFEDE8;
    color: #26493E;
    border-radius: 10px 10px 0 0;
  }
  .tab_content {
    width: 100%;
    padding: 14px 25px;
    box-sizing: border-box;
    /* box-shadow: 0px 2px 6px -1px rgba(147,188,175,0.5); */
    border-radius: 10px;
  }
  .tab_content_text1 {
    font-size: 10px;
    font-weight: 500;
    margin: 19px 0;
    color: #769991;
    margin-bottom: 52px;
  }
  .tab_content_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  .tab_content_flex p:first-child {
    font-size: 12px;
    font-weight: 500;
    color: #9A9A9A;
  }
  .tab_content_flex p:last-child {
    font-size: 12px;
    font-weight: 500;
    color: #26493E;
  }
  .tab_content_text2 {
    min-height: 80px;
    font-size: 12px;
    font-weight: 500;
    color: #769991;
    line-height: 16px;
    margin-top: 7px;
    margin-bottom: 4px;
  }
  .tab_content_text3 {
    font-size: 12px;
    font-weight: 500;
    color: #26493E;
    text-align: center;
    margin-bottom: 5px;
  }
  .tab_content_text3 span {
    font-size: 19px;
    font-weight: 600;
    padding-right: 5px;
  }
  .tab_content_text4 {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #26493E;
    margin-bottom: 10px;
    text-align: center;
  }
  .buy_btn {
    width: 100%;
    height: 48px;
    background: #6FCAA0;
    border-radius: 24px;
    text-align: center;
    line-height: 48px;
    font-size: 19px;
    font-weight: 500;
    color: #FFFFFF;
    margin-bottom: 10px;
  }
</style>
