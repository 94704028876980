<template>
  <div class="nft_page">
    <div class="top_view">
      <div class="nftbanner_view">
        <img src="./../../assets/image/nftbanner.png" alt="" class="nft_banner">
        <p class="nft_banner_text">{{$t("lang.nftbannertxt")}}</p>
      </div>
    </div>
    <!-- 盲盒 -->
    <div class="manghe_view">
      <p class="manghe_title">NEZHA ORIENTAL</p>
      <p class="manghe_title1">{{$t("lang.tongguozhuzao")}}</p>
      <div class="manghe_nft">
        <div class="manghe_nft_item">
          <p class="nft_item_title">{{$t("lang.zhuzao")}}</p>
          <p class="nft_item_price"><span>8000</span></p>
          <p class="nft_item_text">{{$t("lang.meixiaochannft")}}</p>
          <div class="nft_item_btn" @click="goPage('mintnft')">{{$t("lang.kaishizhuzao")}}</div>
          <img src="../../assets/image/nftimg1.png" alt="" class="nft_item_img">
        </div>
        <div class="manghe_nft_item">
          <p class="nft_item_title">{{$t("lang.kongtou")}}</p>
          <p class="nft_item_price"><span>1000</span></p>
          <p class="nft_item_text">{{$t("lang.rengouzhiyalingqunft")}}</p>
          <div class="nft_item_btn" @click="goPage('dropnft')">{{$t("lang.lingqukongtou")}}</div>
          <img src="../../assets/image/nftimg2.png" alt="" class="nft_item_img">
        </div>
      </div>
    </div>
    <!-- 关于 -->
    <div class="introduce_view">
      <div class="introduce_rule_view">
        <div class="introduce_title title_nezha">
          <span></span>
          <p>{{$t("lang.fenpeizhuzaoguize")}}</p>
        </div>
        <p class="introduce_text text_nezha">
          {{$t("lang.nezhaoriental")}}<br/><br/>
          {{$t("lang.tuanduibaoliu")}}：{{$t("lang.kaifatuandui")}}<br/>
          {{$t("lang.shequkongtou")}}：{{$t("lang.rengouzhiyabaimingdan")}}<br/>
          {{$t("lang.zhuzaofashou")}}：{{$t("lang.8000mint")}}<br/><br/>

          {{$t("lang.zhuzaoguize")}}：<br/>
          {{$t("lang.8000manghe")}}
        </p>
      </div>

      <div class="introduce_road_view">
        <div class="introduce_title" style="margin-bottom:2.25rem">
          <span></span>
          <p>{{$t("lang.luxiantu")}}</p>
        </div>
        <p class="introduce_text" style="margin-left:2.6rem">
          {{$t("lang.womenjiangchangqi")}}<br/><br/>
          {{$t("lang.womenweinezha")}}
        </p>
        <div class="nft_road_content">
          <div style="margin-top:1.9rem">
            <div class="left_road">
              <div class="left_road_border"><p></p><p></p></div>
              <p class="road_title">10% SALES</p>
              <p class="road_value">{{$t("lang.xianshanghualang")}}</p>
            </div>
            <div class="left_road">
              <div class="left_road_border"><p></p><p></p></div>
              <p class="road_title">40% SALES</p>
              <p class="road_value">{{$t("lang.xianxiaip")}}</p>
            </div>
            <div class="left_road">
              <div class="left_road_border"><p></p><p></p></div>
              <p class="road_title">60% SALES</p>
              <p class="road_value">{{$t("lang.goumaixuni")}}</p>
            </div>
            <div class="left_road">
              <div class="left_road_border"><p></p><p></p></div>
              <p class="road_title">80% SALES</p>
              <p class="road_value">{{$t("lang.OpenSea")}}</p>
            </div>
          </div>
          <span></span>
          <div style="margin-top:8.8rem">
            <div class="right_road">
              <div class="right_road_border"><p></p><p></p></div>
              <p class="road_title">30% SALES</p>
              <p class="road_value">{{$t("lang.nezhachaowan")}}</p>
            </div>
            <div class="right_road">
              <div class="right_road_border"><p></p><p></p></div>
              <p class="road_title">50% SALES</p>
              <p class="road_value">{{$t("lang.nezhadujia")}}</p>
            </div>
            <div class="right_road">
              <div class="right_road_border"><p></p><p></p></div>
              <p class="road_title">60% SALES</p>
              <p class="road_value">{{$t("lang.xianxiashuzi")}}</p>
            </div>
            <div class="right_road" style="margin-bottom: 1.7rem">
              <div class="right_road_border"><p></p><p></p></div>
              <p class="road_title">100% SALES</p>
              <p class="road_value">{{$t("lang.qidianjuhui")}}</p>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>
<script>

import PcModal from '../../components/PcModal.vue'
// 交易hash
export default {
  name: "detail",
  components: {
    PcModal
  },
  data() {
    return {
      
    };
  },
  computed: {
    
  },
  methods: {
    goPage(route) {
      if(!this.$store.state.userAddress) {
        this.$emit('openLinkWallet');
        return 
      }
      // if(route == "castnft" && !this.$store.state.castCountTime) {
      //   this.$router.push('/commingOpenNft')
      //   return 
      // }
      this.$router.push(route)
    }
  },
  mounted() {
    
  }
};
</script>

<style scoped >
  .top_view {
    background: url('./../../assets/image/dropnftbg.png') no-repeat;
    background-size: 100% 100%;
    padding-bottom: 6.85rem;
  }
  .nftbanner_view {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    background-size: 100% auto;
    padding: 4rem 6.2rem 4.65rem 6.2rem;
    position: relative;
    border-radius: 1rem;
  }
  .nft_banner {
    width: 100%;
    height: auto;
    display: block;
  }
  .nft_banner_text {
    position: absolute;
    left: 9.3rem;
    top: 12.35rem;
    width: 29rem;
    line-height: 1.6rem;
    font-size: 0.5rem;
    color: #FFFFFF;
  }
  /* 盲盒 */
  .manghe_view {
    width: 100%;
    height: 57.25rem;
    background: url('../../assets/image/manghebg.png') no-repeat;
    background-size:  auto 100%;
    padding-top: 1.15rem;
    margin-bottom: 0.35rem;
  }
  .manghe_title {
    font-size: 2rem;
    font-weight: 600;
    color: #26493E;
    line-height: 2.8rem;
    text-align: center;
    margin-bottom: 0.5rem;
  }
  .manghe_title1 {
    font-size: 0.75rem;
    font-weight: 600;
    color: #4A4A4A;
    line-height: 1.05rem;
    margin-bottom: 2.95rem;
    text-align: center;
  }
  .manghe_nft {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .manghe_nft_item {
    width: 27rem;
    height: 42.4rem;
    background: url('../../assets/image/nftitembg1.png') no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .manghe_nft_item:last-child {
    margin-left: 2.6rem;
    background: url('../../assets/image/nftitembg2.png') no-repeat;
    background-size: cover;
  }
  .nft_item_title {
    font-size: 2rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4A4A4A;
    line-height: 2.8rem;
    margin-bottom: 1.5rem;
    margin-top: 3.15rem;
  }
  .nft_item_price {
    font-size: 1rem;
    font-weight: 400;
    color: #4A4A4A;
    margin-bottom: 2rem;
  }
  .nft_item_price span {
    font-size: 3rem;
  }
  .nft_item_text {
    font-size: 0.75rem;
    font-weight: 600;
    color: #8B8B8B;
    margin-bottom: 3rem;
    width: 70%;
    text-align: center;
  }
  .nft_item_btn {
    width: 10rem;
    height: 3rem;
    background: #393939;
    border-radius: 3.25rem;
    text-align: center;
    line-height: 3rem;
    font-size: 0.75rem;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 3rem;
    cursor: pointer;
  }
  .nft_item_img {
    width: 15rem;
    height: 15rem;
    border-radius: 1rem;
  }
  /* 关于 */
  .introduce_view {
    width: 100%;
    background: url('../../assets/image/nftinterducebg.png') no-repeat;
    background-size: 100% auto;
    padding-top: 0.35rem;
  }
  .introduce_rule_view {
    margin: 0 10.3rem;
    height: 36rem;
    background: url('./../../assets/image/nftrulebg.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 12.7rem;
  }
  .introduce_title {
    display: flex;
    align-items: center;
  }
  .introduce_title span {
    width: 0.6rem;
    height: 2.8rem;
    background: #4A4A4A;
    margin-right: 2rem;
  }
  .introduce_title p {
    font-size: 2rem;
    font-weight: 600;
    color: #26493E;
  }
  .title_nezha {
    margin-left: 6.4rem;
    margin-bottom: 3.5rem;
  }

  .introduce_text {
    font-size: 0.75rem;
    font-weight: 600;
    color: #4A4A4A;
    line-height: 1.5rem;
  }
  .text_nezha {
    width: 46.35rem;
    margin: 0 auto;
  }

  .introduce_road_view {
    margin: 0 10.3rem;
    padding-bottom: 12.7rem;
  }
  .introduce_road_view img {
    width: 36.5rem;
    height: auto;
    display: block;
    margin: 0 auto;
    margin-top: 5.95rem;
  }

  .introduce_flex {
    display: flex;
    align-items: center;
  }
  .introduce_flex_img {
    width: 33.55rem;
    height: 33.55rem;
    padding: 2rem;
    background: #FFFFFF;
    border-radius: 1rem;
  }
  .introduce_flex_img img {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
  }

  /* 路线图 */
  .nft_road_content {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 6rem;
  }
  .nft_road_content span {
    width: 4px;
    background: #8EFFCB;
  }
  .left_road {
    text-align: right;
    padding-right: 5.45rem;
    position: relative;
    margin-bottom: 7.7rem;
  }
  .left_road_border {
    display: flex;
    align-items: center; 
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -0.5rem;
  }
  .left_road_border p:first-child {
    width: 1rem;
    height: 1rem;
    background: #8EFFCB;
    border-radius: 50%;
  }
  .left_road_border p:last-child {
    width: 2.5rem;
    height: 4px;
    background: #8EFFCB;
  }

  .right_road {
    text-align: left;
    padding-left: 5.45rem;
    position: relative;
    margin-bottom: 7.7rem;
  }
  .right_road_border {
    display: flex;
    align-items: center; 
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -0.5rem;
  }
  .right_road_border p:first-child {
    width: 2.5rem;
    height: 4px;
    background: #8EFFCB;
  }
  .right_road_border p:last-child {
    width: 1rem;
    height: 1rem;
    background: #8EFFCB;
    border-radius: 50%;
  }
  .road_title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #4A4A4A;
    line-height: 2rem;
    margin-bottom: 1rem;
  }
  .road_value {
    font-size: 1rem;
    font-weight: bold;
    color: #4A4A4A;
  }

</style>