<template>
  <div class="detail">
    <div class="detail_title">
      <p class="title_text">{{$t("lang.rengou")}}</p>
      <div class="price_view">
        <p>${{($store.state.UMI_USDC * 1).toFixed(3)}}</p>
        <p>{{$t("lang.UMIjiage")}}</p>
      </div>
    </div>
    <div class="card_view">
      <div class="card_info">
        <div class="card_info_top">
          <div class="coin_name_view">
            <div class="name_view">
              <div class="coin">
                <img src="./../../assets/image/nft.png" alt="">
                <p>UMI</p>
              </div>
              <p class="separate" v-if="buyType==1">/</p>
              <div class="coin" v-if="buyType==1">
                <img src="./../../assets/image/nft2.png" alt="">
                <p>USDC</p>
              </div>
            </div>
            <div class="get_coin" @click="openGetUMIModal" :style="!(info.get_can*1) && {opacity:'0.7',cursor:'not-allowed'}">{{$t("lang.lingquUMI")}}</div>
          </div>
          <div class="buy_detail_info">
            <div class="buy_detail_info_item">
              <p>{{$t("lang.shuocangshouyi")}}</p>
              <p>{{buyType ==1 ? '18.00%' : '9.00%'}}</p>
            </div>
            <div class="buy_detail_info_item">
              <p> {{$t("lang.rengouzhekou")}}</p>
              <p>{{(discount * 100).toFixed(2)}}%</p>
            </div> 
            <div class="buy_detail_info_item">
              <p> {{$t("lang.qigoumenkan")}}</p>
              <p>${{buyType==2 ? '2000.00' : '5000.00'}}</p>
            </div>
          </div>
        </div>
        <div class="card_info_bottom">
          <div class="card_info_bottom_item">
            <p>{{info.buy_ed}} UMI</p>
            <p>{{$t("lang.yirengou")}}</p>
          </div>
          <div class="card_info_bottom_item">
            <p>{{info.get_ed}} UMI</p>
            <p>{{$t("lang.yisifang")}}</p>
          </div>
          <div class="card_info_bottom_item">
            <p>{{info.get_can}} UMI</p>
            <p>{{$t("lang.kelingqu")}}</p>
          </div>
          <div class="card_info_bottom_item">
            <p>{{info.get_will}} UMI</p>
            <p>{{$t("lang.yilingqu")}}</p>
          </div>
          <!-- <div class="card_info_bottom_item">
            <p>2,000,000 UMI</p>
            <p>{{$t("lang.rengouzongliang")}}</p>
          </div> -->
          
        </div>
      </div>
      <div class="card_buy">
        <div class="card_buy_title">
          <p class="left_title">{{$t("lang.rengoujine")}}</p>
          <p class="right_title">50Gwei</p>
        </div>
        <div class="input_view" :style="isfocus && {background: '#EEF8F4'}">
          <input 
            type="number"
            v-model="inputValue"
            :disabled="!$store.state.userAddress || !($store.state.UMI_USDC*1)"
            @blur="isfocus = false"
            @focus="isfocus = true"
            :style="isfocus && {background: '#EEF8F4',caretColor: '#6fcaa0'}"
          />
          <div class="input_right">
            <img :src="moneyUnit=='ETH'? require('./../../assets/image/coin_ETH.png'):require('./../../assets/image/coin_USDC.png')" alt="" class="input_right_coin">
            <p class="input_right_name">{{moneyUnit}}</p>
            <!-- <img src="./../../assets/image/select_icon.png" alt="" style="width:1.05rem;height:1.05rem">
            <div class="select_coin">
              <el-select v-model="moneyUnit" placeholder="请选择" style="width:100%;height:100%">
                <el-option label="ETH" value="ETH"></el-option>
                <el-option label="USDC" value="USDC"></el-option>
              </el-select>
            </div> -->
          </div>
        </div>
        <p class="input_balance">{{$t("lang.yue")}}：{{ numberTofixed($store.state.balance[`${moneyUnit}Balance`])}}{{moneyUnit }}</p>

        <div class="card_buy_title">
          <p class="left_title">{{buyType == 1 ? $t("lang.duiyingUMI-USDC") : $t("lang.duiyingUMI")}}</p>
          <!-- <p class="right_title1" @click="changeBuyType">{{buyType==1?$t("lang.zuLPrengou"):$t("lang.danbirengou")}}</p> -->
          <p></p>
        </div>
        <div class="input_view" style="background:#fcfcfc">
          <input type="text" readonly :value="moneyUMI"/>
          <div class="input_right">
            <img src="./../../assets/image/nft.png" alt="" class="input_right_coin">
            <p class="input_right_name">UMI</p>
          </div>
        </div>
        <div class="discount_view">
          <!-- 1-30天内折扣为 0.15；31-90天内折扣为0.10； 91天以后折扣为0.05； -->
          <p>{{$t("lang.UMIzhekou")}}：{{discount * 100}}%</p> 
          <p v-if="buyType == 1">{{$t("lang.youhui")}} {{(inputValue * discount).toFixed(3)}}</p>
          <!-- <p v-else>1 {{moneyUnit}} = {{moneyUnit=='ETH'? UMI_ETH : (1 / $store.state.UMI_USDC).toFixed(3)}} UMI</p> -->
        </div>
        <div class="input_view"  v-if="buyType == 1" style="background:#fcfcfc">
          <input type="text" readonly :value="moneyUSDC">
          <div class="input_right">
            <img src="./../../assets/image/coin_USDC.png" alt="" class="input_right_coin">
            <p class="input_right_name">USDC</p>
          </div>
        </div>
        <div v-else style="height:4rem"></div>
        <p class="tips_text">{{$t("lang.daoqilingqu")}}</p>
        <p class="get_noin_num"><span>{{rewards ? `${rewards}+100` : '0.00'}}</span> {{rewards ? `(${$t("lang.gasbutie")})` : ''}}</p>
        <!-- 按钮区域 -->
        <div class="buy_btn" v-if="!$store.state.userAddress" @click="openLinkWallet">{{$t("lang.lianjieqianbao")}}</div>
        <div class="buy_btn_nobalance" v-else-if="noBalance">{{$t("lang.yuebuzu")}}</div>
        <div v-else>
          <!-- 授权的按钮 -->
          <div class="buy_btn" v-if="moneyUnit=='USDC' && !USDCAllow">
            <UmiButton
              :loading="approvingStatus==2"
              :text="approvingStatusTxt"
              @clickFn="approveFn('USDC')"
            />
          </div>
          <!-- 已认购 -->
          <div class="buy_btn_nobalance get_ed" v-if="(info.buy_ed*1 > 0)" >{{$t('lang.goumaichenggong')}}</div>
          <!-- 购买按钮 -->
          <div class="buy_btn" v-else v-show="USDCAllow" :style="buyingStatus==3&& {background:'#EEEEEE',color:'#C7C7C7'}">
            <UmiButton
              :loading="buyingStatus==2"
              :text="buyingStatusTxt"
              @clickFn="buyUMI"
              :disable="disbaleBuy"
            />
          </div>
        </div>

      </div>
    </div>
    <!-- <p class="bottom_tips">{{$t("lang.zijinyongtu2")}}</p> -->
    <!-- modal loading -->
    <PcModal v-if="showModal" @closeModal="closePCmodal" :title="modalTitle">
      <!-- loading -->
      <div slot="content" v-if="modalType == 0" class="buy_succes_modal"><modalLoading/></div>
      <!-- 交易已提交 -->
      <div slot="content" v-if="modalType == 1" class="buy_succes_modal">
        <div class="buy_succes_modal_content">
          <img src="../../assets/image/arrow_top.png" alt="" style="width:3.8rem;margin:0 auto;margin-bottom:0.75rem">
          <p style="text-align:center;margin-bottom:20px">{{$t("lang.yitijiao")}}</p>
        </div>
        <div class="buy_succes_modal_btn" @click="closePCmodal">{{$t("lang.guanbi")}}</div>
      </div>
      <!-- 交易成功 -->
      <div slot="content" v-if="modalType == 2" class="buy_succes_modal">
        <div class="buy_succes_modal_content">
          <p>{{$t("lang.rengouchengong")}}</p>
        </div>
        <div class="buy_succes_modal_btn" @click="closePCmodal">{{$t("lang.guanbi")}}</div>
      </div>
      <!-- 失败 -->
      <div slot="content" v-if="modalType ==3" class="buy_succes_modal">
        <div class="buy_succes_modal_content">
          <p>{{$t("lang.jiaoyijujie")}}</p>
        </div>
        <div class="buy_succes_modal_btn" @click="closePCmodal">{{$t("lang.guanbi")}}</div>
      </div>
    </PcModal>
    

    <!-- 领取UMImodal -->
    <PcModal  v-if="showGetUMI" @closeModal="closeGetUMI" :title="$t('lang.lingquUMI')">
      <div slot="content" class="get_UMI_modal_content">
        <div class="UMI_nums">
          <img src="./../../assets/image/coin_UMI.png" alt="">
          <p>{{info.get_can}}UMI</p>
        </div>
        <div class="get_umi_btn can_get" v-if="getUMIStatus==1" @click="getUMIFn">{{$t("lang.querenlingqu")}}</div>
        <div class="get_umi_btn get_ing" v-if="getUMIStatus==2">{{$t("lang.querenlingqu")}}</div>
        <div class="get_umi_btn get_ed" v-if="getUMIStatus==3">{{$t("lang.lingquchengong")}}</div>
        <div class="get_status_view">
          <div class="get_status_view_img">
            <img src="./../../assets/image/waite.png" alt="" v-if="getUMIStatus==1" style="width:0.8rem">
            <loadingIcon v-if="getUMIStatus==2" svgW="1.3rem" svgH="0.8rem"/>
            <img src="./../../assets/image/success.png" alt="" v-if="getUMIStatus==3">
          </div>
          
          <p>{{getUMIStatus==1 ? `${$t("lang.dailingqu")}...` : (getUMIStatus==2 ? `${$t("lang.lingquzhong")}...` : $t("lang.lingquchengong"))}}</p>
        </div>
      </div>
    </PcModal>

  </div>
</template>
<script>

import utils from "@/utils/utils";
import {Notification, Message,Loading } from 'element-ui';
import PcModal from './../../components/PcModal.vue'
import modalLoading from '../../components/loading.vue';
import UmiButton from '../../components/UmiButton.vue';
import LoadingIcon from '../../components/loadingIcon.vue';
var shownotify = false;
export default {
  name: "detail",
  components: {
    PcModal,
    modalLoading,
    UmiButton,
    LoadingIcon
  },
  data() {
    return {
      buyType: 2,
      // isApprove: true,
      approvingStatus: 1, // 1可点击授权 2 授权中不能点击
      approvingStatusTxt: this.$t('lang.shouquanrengou'),

      ETHAllow: null,
      USDCAllow: null,
      
      
      buyingStatus: 1, // 1可点击购买 2 购买中不能点击 3 购买成功不可点击
      buyingStatusTxt:  this.$t('lang.querengoumai'),
      disbaleBuy: true,
      moneyUnit: 'USDC',
      inputValue: '',
      showModal: false,
      modalType: 0, // 0 loading, 1 已提交  2 成功 3 失败
      info: {
        buy_ed: 0,
        get_will: 0,
        get_ed: 0,
        get_can: 0
      },
      moneyUMI: '',
      moneyUSDC: '',
      // 领取nft成功
      getNFTModal: false,
      // 折扣
      discount: 0,
      // modal  title
      modalTitle: '',
      // 到期领取奖励
      rewards: 0,
      noBalance: false,

      // get umi modal 
      showGetUMI: false,
      getUMIStatus: 1,
      isfocus: false // =输入框是否聚焦
    };
  },
  mounted() {
    if(!this.$store.state.userAddress) {
      this.$emit('openLinkWallet');
      return 
    }
    this.noBalance = !((this.$store.state.balance.USDCBalance*1) > 0)
    // this.buyType = this.$route.query.type || 2;
    this.getAllow();
    this.getInfo();
    this.getDiscount();     
  },
  computed: {
    UMI_ETH() {
      const { UMI_USDC, USDC_ETH } = this.$store.state;
      const res = utils.division(utils.division(1,UMI_USDC),USDC_ETH)
      return Number(res).toFixed(3)
    }
  },
  watch: {
    // 监听输入框
    inputValue(){
      let value = '';
      let value1 = '';
      const { UMI_USDC, USDC_ETH } = this.$store.state;
      if(this.inputValue * 1) {
        const res =  this.buyType == 1 ? this.inputValue / (UMI_USDC*(1 + (1 - this.discount))) : this.inputValue/ (1-this.discount) /  UMI_USDC  //  : this.inputValue / (UMI_USDC * USDC_ETH);
        const res1 =  this.inputValue / (1 + (1 - this.discount)) // : this.inputValue / USDC_ETH;
        value = res.toFixed(3);
        value1 = res1.toFixed(3);
      }
      this.moneyUMI = value;
      this.moneyUSDC = value1;
      this.rewards = this.geRewards(value);
      // 余额不足的情况 展示余额不足
      if(this.inputValue * 1 > this.$store.state.balance[`${this.moneyUnit}Balance`]) {
        this.noBalance = true;
      } else {
        this.noBalance = false;
      }
      // 认购按钮 是否可以点击认购条件
      if((this.inputValue * 1 >= (this.buyType==2 ? 2000 : 5000)) && (this.inputValue * 1 < 50000) ) {
        this.disbaleBuy = false;
      } else {
        this.disbaleBuy = true
      }
    },
    // 购买单位
    moneyUnit() {
      this.inputValue = '';
    },
    // 弹窗内容类型
    modalType() {
      if(this.modalType == 3) {
        this.modalTitle = this.$t("lang.jiaoyishibai");
      } else if(this.modalType == 1) {
        this.modalTitle = this.$t("lang.jiaoyichenggong");
      } else {
        this.modalTitle =  '';
      }
    },
    // 弹窗显示 关闭
    showModal() {
      if(!this.showModal) {
        this.modalTitle =  '';
        this.modalType = 0;
      }
    },
    "$store.state.balance.USDCBalance"() {
      if(this.$store.state.balance.USDCBalance) {
        this.noBalance = false;
      }
    },
    "$store.state.DAO_contract"() {
      this.getInfo();
      this.getAllow();
      this.getDiscount();     
    },
    
    buyType() {
      this.inputValue = '';
      this.moneyUMI = '';
      this.moneyUSDC = '';
      this.rewards = '';
    }
  },
  methods: {
    // 
    openLinkWallet() {
      this.$emit("openLinkWallet")
    },
    // 到期领取奖励计算
    geRewards(value) {
      const { UMI_USDC, USDC_ETH } = this.$store.state;
      try {
        if(this.buyType == 1) { // lp
          const P = this.moneyUnit=='ETH' ? (UMI_USDC *USDC_ETH ) : UMI_USDC;
          const lptop = this.$utils.multiplication(2*1.18,this.inputValue);
          const lpbom = this.$utils.multiplication( P,(1 + ( 1 - this.discount )) );
          const res = this.$utils.division(lptop,lpbom)
          return Number(res).toFixed(3)
        } else { // UMI
          const singletop = this.$utils.multiplication(this.inputValue/(1-this.discount), 1.09); // 1.09:  1 + r;   r为9%
          const res = this.$utils.division(singletop,UMI_USDC)
          return Number(res).toFixed(3)
        }
      } catch (error) {
        return ''
      }
      // const lp = (2* this.inputValue * 118) / (this.UMI_ETH * (100 + ( 1- this.discount )*100));
    },
    getDiscount() {
      const { lockInfo, discount } = this.$store.state;
      const time = new Date().getTime() - Number(lockInfo.start+'000');
      // <!-- 1-30天内折扣为 0.15；31-90天内折扣为0.10； 91天以后折扣为0.05； -->
      const day = time / 1000 / 3600 / 24;
      if(day < 30) {
        this.discount =  ((100 - discount[0]) / 100).toFixed(2) * 1;
      } else if (day > 90) {
        this.discount = ((100 - discount[2]) / 100).toFixed(2) * 1;
      } else {
        this.discount = ((100 - discount[1]) / 100).toFixed(2) * 1;
      }
    },
    lookNft() {
      this.$router.push('/mynft');
    },
    getNft() {
      this.closePCmodal();
      this.getNFTModal = true;
    },
    closePCmodal() {
      this.showModal = false;
      this.modalType = 0;
    },
    changeBuyType() {
      if(this.buyType == 1) {
        this.buyType = 2;
      } else {
        this.buyType = 1;
      }
    },
    async getAllow() {
      const { UMI_contract, userAddress, USDC_contract, } = this.$store.state;
      const USDCallow = await USDC_contract.methods.allowance(userAddress,this.$api.DAO_Addr).call({from: userAddress});
      this.USDCAllow = utils.mathpow(USDCallow,this.$api.USDC_Demical) * 1;
      // const ETHallow = await USDC_contract.methods.allowance(UMI_contract,API.UMI_Addr).call({from: userAddr});
    },
    async getInfo(reoload,type) {
      const { userAddress, DAO_contract } = this.$store.state;
      try {
        var res1  = await DAO_contract.methods.getBondsReward(userAddress).call({from: userAddress});  
      } catch (error) {
      }
      if(reoload) {
        const again = type == 2 ? this.info.get_ed == this.numberTofixed(utils.mathpow(res1.takedAmount,this.$api.UMI_Demical)) : this.buy_ed == this.numberTofixed(utils.mathpow(res1.origAmount,this.$api.UMI_Demical))
        if(again) {
          setTimeout(()=> {
            this.getInfo(reoload,type)
          },2000)
          return 
        }
      }
       // 新的合约 改了 只需要调用getBondsReward  就可以拿 所有需要的数据了
      const info  = {
        buy_ed: this.numberTofixed(utils.mathpow(res1.origAmount,this.$api.UMI_Demical)), // 已认购
        get_will: this.numberTofixed(utils.mathpow(res1.takedAmount,this.$api.UMI_Demical)), // 已领取
        get_ed: this.numberTofixed(utils.mathpow((res1.takedAmount*1)+(res1.unlockAmount*1),this.$api.UMI_Demical)), // 已释放
        get_can: this.numberTofixed(utils.mathpow((res1.unlockAmount*1),this.$api.UMI_Demical)), // 可领取
      }
      this.info = info;
      
    },
    async approveFn(type) {
      const { UMI_contract, userAddress, USDC_contract, } = this.$store.state;
      const contract = type == 'ETH' ? null : USDC_contract;
      try {
        this.approvingStatusTxt = this.$t("lang.dengdaishouquan");
        this.approvingStatus = 2;
        const res = await contract.methods.approve(this.$api.DAO_Addr,utils.mathlog(1000000000000,this.$api[`USDC_Demical`])).send({from: userAddress});
        this.USDCAllow = 100000;
        this.approvingStatus = 1;
        this.$notify({
          title: '',
          duration: 8000,
          dangerouslyUseHTMLString: true,
          message: `<div class="notify_success_view_pc"><img src="${require("../../assets/image/success.png")}"/><p>${this.$t('lang.shouquanchenggong')}</p></div>`
        });
      } catch (error) {
        this.USDCAllow = null;
        this.approvingStatus = 1;
        this.approvingStatusTxt = this.$t('lang.shouquanrengou');
      }
      
    },
    buyUMI() {
      const type = this.buyType == 1 ? 5 : 4;
      // const minValue = type==5 ? 5000 : 2000;
      // if ((this.moneyUMI* 1) < minValue) {
      //   this.$message(`购买数量不能低于${minValue}！`)
      //   return 
      // }
      // if(this.moneyUnit == 'ETH') {
      //   this.useETHBuy(type)
      // } else {
        this.useUSDCBuy(type)
      // }

    },
    // usdc 购买
    async useUSDCBuy(type) {
      shownotify = false;
      this.showModal = true;
      this.buyingStatusTxt = this.$t('lang.dengdaiqueren');
      this.buyingStatus = 2;
      const { DAO_contract, userAddress, USDC_contract, } = this.$store.state;
      const num = this.$utils.mathlog(this.inputValue, this.$api.USDC_Demical);
      DAO_contract.methods[this.buyType==1?'bondsLP':'bonds'](num).send({from: userAddress})
      .on('transactionHash', (hash)=>{ // 交易hash
        this.modalType = 1;
        !this.showModal && (this.showModal = true);
      })
      .on('confirmation', (confirmationNumber, receipt)=>{ // 
        
      })
      .on('receipt', (receipt)=>{ // 交易已广播
        if(!shownotify) {
          const text = this.$t("lang.zhixingchenggong");
          this.$notify({
            title: '',
            duration: 8000,
            dangerouslyUseHTMLString: true,
            message: `<div class="notify_success_view_pc"><img src="${require("../../assets/image/success.png")}"/><p>${text}</p></div>`
          });
          
          this.inputValue = '';
          this.moneyUMI = '';
          this.moneyUSDC = '';
          this.rewards = '';
          setTimeout(()=> {
            this.showModal = false;
            this.buyingStatus = 3 ;
            this.buyingStatusTxt =  this.$t('lang.rengouchengong');
          },500)
          shownotify = true;
          this.regetData();
          this.getInfo('reload',1);
        }
      })
      .on('error',(error, receipt)=>{ // 交易失败
        this.modalType = 3;
        this.buyingStatus = 1;
        !this.showModal && (this.showModal = true);
        this.buyingStatusTxt =  this.$t('lang.querengoumai');
      })
    },
    numberTofixed(val,fix=3) {
      const res = (val || 0) * 1;
      return res.toFixed(fix)
    },

    // 领取UMI
    getUMIFn() {
      this.getUMIStatus = 2;
      const { DAO_contract, userAddress } = this.$store.state;
      DAO_contract.methods
        .withdrawBonds()
        .send({ from: userAddress })
        .on("transactionHash", (hash) => {
          // 交易hash
        })
        .on("receipt", (receipt) => {
          
          this.getUMIStatus = 3;
          this.$notify({
            title: '',
            duration: 8000,
            dangerouslyUseHTMLString: true,
            message: `<div class="notify_success_view_h5"><img src="${require("../../assets/image/success.png")}"/><p>${this.$t('lang.lingquchengong')}</p></div>`
          });
          setTimeout(()=>{ this.showGetUMI = false },2000)
          this.regetData();
          this.getInfo('reoload',2);

        })
        .on("error", (error, receipt) => {
          // 交易失败
          this.modalType = 1;
          this.getUMIStatus = 1;
        });
    },
    openGetUMIModal() {
      if(!(this.info.get_can * 1 )) {
        return 
      }
      this.showGetUMI = true;
    },
    closeGetUMI() {
      this.showGetUMI = false;
    },
    // 认购成功 或这领取成功 需要重新获取数据
    async regetData(only) {
      const { UMI_contract, USDC_contract, userAddress, balance } = this.$store.state;
      const UMIres = await UMI_contract.methods.balanceOf(userAddress).call();
      const UMIBalance = utils.mathpow(UMIres,this.$api.UMI_Demical);
      // USDC
      const USDCres = await USDC_contract.methods.balanceOf(userAddress).call();
      const USDCBalance = utils.mathpow(USDCres,this.$api.USDC_Demical);
        
        if(only) {
          if(balance.UMIBalance == UMIBalance) {
            setTimeout(()=>{ this.regetData(only)},3000);
            return 
          }
        } else {
          if(balance.USDCBalance == USDCBalance) {
            setTimeout(()=>{ this.regetData(only)},3000);
            return 
          }
        }
        this.$store.commit('setBalance',{UMIBalance,USDCBalance});
    }

  }
};
</script>

<style scoped >
  .detail {
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 0.5rem;
    padding: 4.8rem 6.75rem;
    box-sizing: border-box;
  }
  .detail_title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4.85rem;
  }
  .title_text {
    font-size: 1.5rem;
    font-weight: 600;
    color: #26493E;
  }
  .price_view {
    font-size: 1rem;
    font-weight: 500;
    color: #26493E;
  }
  .price_view p:first-child {
    margin-bottom: 0.8rem;
    font-size: 1.25rem;
  }
  .card_view {
    display: flex;
  }
  .card_info {
    flex: 1;
    margin-right: 1rem;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 1rem 0px rgba(231,248,245,1);
    border-radius: 1.5rem;
    
    box-sizing: border-box;
    
  }
  .card_buy {
    width: 32.5rem;
    min-height: 34rem;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 1rem 0px rgba(231,248,245,1);
    border-radius: 1.5rem;
    padding: 2.65rem 2.75rem;
    box-sizing: border-box;
  }
  .card_info_top {
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 1rem 0px rgba(231,248,245,1);
    border-radius: 1.5rem 1.5rem 0px 0px;
    padding: 2.65rem 0.75rem;
    margin-bottom: 2.9rem;
  }
  .coin_name_view {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
    padding: 0 2.75rem;
    box-sizing: border-box;
  }
  .name_view {
    display: flex;
    align-items: center;
  }
  .coin {
    display: flex;
    align-items: center;
  }
  .coin img {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.55rem;
  }
  .coin p,.separate {
    font-size: 1.5rem;
    font-weight: 600;
    color: #26493E;
  }
  .separate {
    margin: 0 1.25rem;
  }
  .get_coin {
    width: 10rem;
    height: 3rem;
    background: #D8F3E6;
    border-radius: 3rem;
    font-size: 1rem;
    font-weight: 500;
    color: #26493E;
    line-height: 3rem;
    text-align: center;
    cursor: pointer;
  }
  .buy_detail_info {
    display: flex;
  }
  .buy_detail_info_item {
    flex: 1;
    min-width: 33.3%;
    max-width: 33.34%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .buy_detail_info_item p:first-child {
    font-size: 0.8rem;
    font-weight: 600;
    color: #9A9A9A;
    margin-bottom: 1rem;
  }
  .buy_detail_info_item p:last-child {
    font-size: 1rem;
    font-weight: 600;
    color: #26493E;
  }
  .card_info_bottom {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0.75rem;
  }
  .card_info_bottom_item {
    flex: 1;
    min-width: 33.3%;
    max-width: 33.34%;
    margin-bottom: 4.15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    word-break: break-all;
  }
  .card_info_bottom_item p:last-child {
    font-size: 0.8rem;
    font-weight: 600;
    color: #9A9A9A;
    margin-top: 1rem;
  }
  .card_info_bottom_item p:first-child {
    font-size: 1rem;
    font-weight: 600;
    color: #26493E;
  }
  .card_buy_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .left_title {
    font-size: 0.8rem;
    font-weight: 500;
    color: #9A9A9A;
  }
  .right_title {
    font-size: 0.8rem;
    font-weight: 500;
    color: #26493E;
    line-height: 1.1rem;
    -webkit-text-stroke: 1px #26493E;
    text-stroke: 1px #26493E;
    background: url('./../../assets/image/gas_icon.png') no-repeat left center / 1.05rem 1.1rem;
    padding-left: 1.5rem;
  }
  .right_title1 {
    font-size: 0.8rem;
    font-weight: 500;
    background: url('./../../assets/image/change.png') no-repeat left center / 1.05rem auto;
    padding-left: 1.5rem;
    color: #6FCAA0;
    cursor: pointer;
  }
  .input_view {
    display: flex;
    align-items: center;
    width: 100%;
    height: 3rem;
    background: #F8F8F8;
    border-radius: 3rem;
    margin-bottom: 5px;
    overflow: hidden;
  }
  .input_view input {
    flex: 1;
    height: 100%;
    line-height: 2rem;
    padding: 0 1rem;
    box-sizing: border-box;
    border: none;
    outline: none;
    background: none;
    font-size: 1rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #26493E;
  }
  .select_coin {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1;
    opacity: 0;
  }
  .input_right {
    flex-shrink: 0;
    width: 8rem;
    height: 2rem;
    display: flex;
    align-items: center;
    border-left: 2px solid #EEEEEE;
    padding-left: 1rem;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
  }
  .input_right_coin {
    width: 1.5rem;
    height: 1.5rem;
  }
  .input_right_name {
    min-width: 3.4rem;
    font-size: 1rem;
    font-weight: 600;
    color: #26493E;
    margin: 0 0.5rem;
  }
  .input_balance {
    font-size: 0.7rem;
    font-weight: 500;
    color: #9A9A9A;
    padding-right: 1rem;
    text-align: right;
    margin-bottom: 2.75rem;
  }
  .discount_view {
    width: 100%;
    padding: 0 1rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.75rem;
  }
  .discount_view p {
    font-size: 0.7rem;
    font-weight: 500;
    color: #9A9A9A;
  }
  .tips_text {
    font-size: 0.7rem;
    font-weight: 500;
    color: #9A9A9A;
    text-align: center;
    margin-top: 1.85rem;
  }
  .get_noin_num {
    font-size: 0.8rem;
    color: #769991;
    text-align: center;
    margin-bottom: 1rem;
  }
  .get_noin_num span {
    font-size: 1rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #26493E;
  }
  .buy_btn,.buy_btn_nobalance {
    width: 25rem;
    height: 3rem;
    background: #6FCAA0;
    border-radius: 3rem;
    font-size: 1rem;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    line-height: 3rem;
    cursor: pointer;
    margin: auto;
    overflow: hidden;
  }
  .buy_btn_nobalance {
    background: #D8F3E6;
    cursor: not-allowed;
  }
  .buy_btn:hover {
    background: #8DDDB8;
  }
  .bottom_tips {
    font-size: 0.7rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #769991;
    margin-top: 0.75rem;
  }
  /* umi 领取弹窗 */
  .get_UMI_modal_content {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .UMI_nums {
    width: 100%;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .UMI_nums img {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }
  .UMI_nums p {
    font-size: 1rem;
    font-weight: 600;
    color: #26493E;
  }
  .get_umi_btn {
    width: 100%;
    height: 3rem;
    background: #B4E3CD;
    border-radius: 1.5rem;
    line-height: 3rem;
    text-align: center;
    font-size: 0.8rem;
  }
  .can_get {
    background: #6FCAA0;
    color: #FFFFFF;
    cursor: pointer;
  }
  .can_get:hover {
    background: #8DDDB8;
  }
  .get_ing {
    background: #B4E3CD;
    color: #FFFFFF;
    cursor: not-allowed;
  }
  .get_ed {
    background: #EEEEEE;
    color: #c7c7c7;
    cursor: not-allowed;
  }
  .get_status_view {
    margin-top: 1.5rem;
    padding-top: 2rem;
    width: 100%;
    display: flex;
    align-items: center; 
    border-top: 1px solid #E0E3E8;
  }
  .get_status_view_img {
    width: 1rem;
    min-height: 1rem;
    margin-right: 0.5rem;
  }
  .get_status_view img {
    width: 100%;
  }
  .get_status_view p {
    color: #4A4A4A;
    font-size: 0.75rem;
    letter-spacing: 1px;
  }
  
  
</style>
<style>
.buy_succes_modal {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.buy_succes_modal_content {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 11px;
}
.buy_succes_modal_content p {
  width: 100%;
  text-align: left;
  font-size: 0.8rem;
  color: #26493E;
  padding: 0 1rem;
}
.buy_succes_modal_btn {
  width: 100%;
  height: 3rem;
  background: #6FCAA0;
  border-radius: 8px;
  text-align: center;
  line-height: 3rem;
  color: #FFFFFF;
  font-size: 0.8rem;
  cursor: pointer;
}
.buy_succes_modal_btn:hover {
  background: #8DDDB8;
}


</style>