// 英文   key由 文字中的四个字的拼音组成
export const lang = {
  'shouye':'Home',
  'rengou':'Purchase',
  'zhiya':'Stake',
  'lianjieqianbao':'Connect Wallet',
  'quanqiulingxian':'The World-leading Crypto Native DAO',
  'zhuanzhuyu':'Building DeFi, Web3 and Metaverse by Community and Crypto technology to capture the industry growth dividend for the community.',
  'canyurengou':'Buy Now',
  'daibifenpei':'Token Distribution',
  'zongfaxingliang':'Total Supply',
  'chubeijin':'Reserve Fund',
  'shequeDAO':'DAO Treasury',
  'liudongxingLP':'Liquidity LP',
  'tuandui':'Team Incentive',
  'rengoujiangli':'Purchase Rewards',
  'luxiantu':'Roadmap',
  'jiamijijin':'Pioneer of Crypto Fund',
  'umiCashchengli':'May, 2018 umiCash Establishment',
  'umiCashv1shangxian':'May, 2019 umiCash APP Launch',
  'umiCashzhishujijin':'May, 2020 umiCash Index Fund Realease',
  'umiCashxiaomubiao':'July, 2021 umiCash Small Goal Realease',
  'hunhedapan':'Sept, 2021 Hybrid Market Fund 10 Net Worth Rose Up to 10.0',
  'umiCashzuoshishangban':'Dec, 2021 umiCash Market Maker Version Launch',
  'umiDeFichanpinshangxian':'Q1 umiDeFi Products Realease',
  'umiDAOzhililuntan':'Q2 umiDAO Governance Forum Realease',
  'UMIDAOzhiliguifan':'UMIDAO Governance Standard Launch',
  'UMIDAOweiyuanhui':'UMIDAO Committee Election',
  'umiCustodyjiamizhiguan':'umiCustody Asset Management',
  'SatQuantDEXlianghua':'SatQuant Quantitive DEX',
  'UMIDAOshequqianbao':'UMIDAO Community Wallet Multisig-distribution',
  'umiCashProjiamilianghua':'umiCashPro Crypto Quantization',
  'FundDappjiamijijin':'Crypto Fund DAPP',
  'shequn':'Community',
  'rengouyibiao':'Purchase Dashboard',
  'woderengou':'My Purchase',
  'rengouzongliang':'Total Purchase (Phase II)',
  'rengouzhekou':'Discount',
  'shuocangshouyi':'Lockup Reward',
  'zongshouyi':'Total ROI',
  'UMIjiage':'UMI Price',
  'rengoujindu':'Progress Rate',
  'yiwancheng':'Completed',
  'shengyu':'Remaining',
  'danrengouUMI':'UMI Purchase',
  'shuoding30':'6-month lockup releasing 40% followed by a every month linear release 10%',
  'rengouzichan':'Funding Assets',
  'zijinyongtu1':"Use of Funds: 80% of the purchase fund is used for the community reserve fund, which is managed by the community's multisig wallet; and the 20% is used for project operating.",
  'qigoujine':'Minimum Purchase',
  'zuLPrengou' :'Provide LP Purchase',
  'shuoding7':'6-month lockup releasing 40% followed by a every month linear release 10%',
  'zijinyongtu2':'Use of Funds: 80% of the purchase fund is used for the community reserve fund, and 20% for project operating. The remaining fund is used to group UMI-USDC LP and pour into uniswap pool.',
  'lingquUMI':'Claim UMI',
  'qigoumenkan':'Minimum Purchase',
  'yirengou':'Purchased',
  'yilingqu':'Claimed Amount',
  'kelingqu':'Claimable Amount',
  'daifafang':'Pending Amount',
  'nianhuaAPR':'APR',
  'rengoujine':'Amount',
  'yue':'Balances',
  'duiyingUMI-USDC':'Est. Pool Allocation',
  'duiyingUMI': 'Buy',
  'danbirengou':'Single UMI Purchase',
  'UMIzhekou':'UMI Discount',
  'youhui':'Discounts',
  'daoqilingqu':'You Will Get',
  'pizhunrengou':'Approve',
  'rengouchengong':'Purchase Completed',
  'lingqurengouNFT':'Claim Purchase NFT',
  'gongxi':'Congratulations',
  'lingquchengong':'Claim successfully',
  'dianjichakan':'Check',
  'wodeNFT':'My NFT',
  'yiqiNFT':'Phase I NFT',
  'dangqiandizhi':'The current address is not in the whitelist',
  'ninzaiyiqi':'You are on the Phase I NFT white list',
  'lingquyiqi':'Claim Phase I NFT',
  'nftmingzi1':'UMIDAO Membership（Phase I）',
  'nftmingzi2':'UMIDAO Membership（Phase II）',
  'bianhao':'Token ID',
  'chuangjianshijian':'Create Date',
  'heyuedizhi':'Contract Add',
  'jiaoyihaxi':'Txn Hash',
  'querengoumai':'Confirm Claim',
  'yuebuzu':'Insufficient balance',
  'querenlingqu':'Confirm Claim',
  'zhiyamenkan':'Minimum Stake',
  'zongshuocang':'TVL',
  'zichanmingchen':'Asset',
  'zhiyazhouqi':'Stake Term',
  'shuhui':'Redeem',
  'souquanUMIDAO':'Authorize UMIDAO to stake ',
  'shouquan':'Authorize',
  'zhiyayue':'Your Staking Balance',
  'daoqijiangli':'Total Reward',
  'jiaogeshijian':'Vesting Term',
  'shuhuizhiya':'Redeem and Stake',
  'daifafangjiang':'Pending Rewards',
  'kelingqujiang':'Claimable Rewards',
  'daoqihou':'Time until fully vested',
  'daoqiriqi':'Vesting Date',
  '2022_6_1':'June 1st,2022',
  'dengdaiqueren':'Waiting For Confirmation',
  'querenzheci':'Confirm this transaction in your wallet',
  'wodeqianbao':'My Wallet',
  'duankailianjie':'Disconnect',
  'lianjieshiba':'Connection Failed',
  'lianjiebeijujie':'connection failed, connection is refused',
  'wanluocuowu':'Network Failed',
  'qingqiehuanwang':'Network failed, please switch to Mainnet',
  'qiehuanwangluo':'Switch to Mainnet',
  'pizhunshibai':'Approval Failed',
  'pizhunjujie':'approval request is denied',
  'jiaoyishibai':'Transaction Failed',
  'jiaoyijujie':'transaction request is denied',
  'lianjieMetamask':'Connect to your MetaMask Wallet',
  'lianjieWalletConnect':'Connect with WalletConnect',
  'zhiyachengong':'Stake successfully',
  
  'yizhiya':'Staked', // 增加
  'month1': '1 month',
  'month3': '3 months',
  'month6': '6 months',
  'month12': '12 months',
  'gasbutie': 'Gas Bonus',
  // 
  'jiaoyichenggong': 'Transaction Succeed',
  'guanbi': 'Close',
  'yitijiao': 'Submitted',
  'zhixingchenggong': 'Transaction Executed',
  'dengdaiqueren': 'Waiting for Confirmation',
  'dailingqu': 'Pending Claim',
  'lingquzhong': 'Claiming',
  
  'dengdaishouquan': 'Waiting for Authorization',
  'shouquanzhong': 'Authorizing',
  'shouquanchenggong': 'Authorization Succeed',
  'jiaoyizhong': 'Trading',
  'dengdaishuhui': 'Waiting for Redemption',
  'shuhuizhong': 'Redeeming',
  'shuhuichenggong': 'Redemption succeed',
  'zhiyazhong': 'Staking',
  'goumaichenggong': 'Purchase Succeed',
  'yisifang': 'Released',
  'shouquanrengou': 'Authorize Purchase',
  'zhiyashijian': 'Stake Term',
'keyong1': 'Available UMI  ',
    'shurutokenid': 'Enter LP TokenID',
  'tokeindcuowu': 'TokenID Incorrect',
  'qianbao': 'Wallet',

  'nftbannertxt': 'In the future Metaverse, capital monopolizes technology to control civilians. In the night sky, a giant machinery dragon boat streaks across the skyscrapers. On the ground, the poor people in the corner of the slum are trembling. Here are only capital and nonentities, filled with crimes and losses. Holding MagicTech, NEZHA intends to tear up the mystery of capital and burns all the lies to ashes with fire so as to seek the concealed truth',
  'nezhamanghe': 'NEZHA Blind Box',
  'tongguozhuzao': 'Getting NEZHA NFT through minting, claiming airdrop through purchasing UMI and staking LP',
  'zhuzao': 'Mint',
  'meixiaochannft': 'Mint 3 NFT per hour, 72 NFT per day',
  'kaishizhuzao': 'Mint Now',
  'kongtou': 'Airdrop',
  'rengouzhiyalingqunft': 'Claim NFT airdrop through purchased UMI and staked LP',
  'lingqukongtou': 'Claim Now',
  'fenpeizhuzaoguize':'Distributing and Minting Rule',
  'nezhaoriental': 'The NEZHA Oriental’s total supply is 10,000, among them, 1,000 are reserved for the team, 1,000 are airdropped for the community and 8,000 are minted for sale',
  'tuanduibaoliu': 'Team Reserved',
  'kaifatuandui': '500 for developer team, 500 for IP team',
  'shequkongtou': "Community Airdrop",
  'rengouzhiyabaimingdan': "1,000  white list of purchase/stake",
  'zhuzaofashou': "Mint Sale",
  '8000mint': "8,000  Mint 1-3, Max. 3 NFTs",
  'zhuzaoguize': "Mint Rule",
  '8000manghe': "8,000 for blind box minting, and it’s sold through MINT channel on home page. Blind box mint 3 NEZHA per hour, 72 NEZHA per day, users can purchase Max. 3 NEZHA NFTs",
  'womenjiangchangqi': "We will carry out NFT planning in accordance with this roadmap for a long time",
  'womenweinezha': "We have planned a development route for NEZHA. Once achieved the goal of sales percentage, we will strive to achieve the stated goals",
  "xianshanghualang": "Online Gallery",
  "nezhachaowan": "Development of NEZHA Tide Play Derivatives",
  "xianxiaip": "Offline IP Joint Exhibition Tour",
  "nezhadujia": "NEZHA Exclusive Products/ Limited Edition Collection",
  "goumaixuni": "Purchase Virtual Land to Display Meta Space",
  "xianxiashuzi": "Offline Digital Art Auction",
  "OpenSea": "OpenSea Buy-back",
  "qidianjuhui": "Celebration & Parties",
  "zhuzaozongliang": "Mint Sum",
  "zhuzajiage": "Mint Price",
  "yixuan":"Selected",
  "zongji":"sum",
  "zhuzaowancheng":"Mint Done",
  "ccobanquan":"NEZHA is minted with CC0 copyright declaration agreement",
  "kongtounezha":"Airdrop NEZHA",
  "kongtouzongliang":"Airdrop Sum",
  "yikongtou":"Airdropped",
  "wodekongtou":"My Airdrop",
  "buzaibaimingdan":"Out of White List",
  "zhuzaochenggong":"Mint Succeed",

  "yaoqinglianjie": 'Invitation link',
  "fuzhi": "Copy",
  "fuzhichenggong": "Copy Succeed",
  "dakaiqianbao": "Open your Decentralized Wallet",
  "lianjiefuzhi": "Paste the copied website link to the Browser in Wallet and go to mint"
}