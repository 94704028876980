<template>
  <div class="pledge">
    <!-- 质押弹窗 -->
    <div class="pledge_dialog"  @click="clickPledgeBg">
      <div class="pledge_dialog_view">
        <div class="pledge_dialog_close" @click="closePledge_dialog">
          <img src="./../../assets/image/close.png" alt="" />
        </div>
        <div class="pledge_dialog_title">
          <img :src="pledgeData.img" alt="" />
          <p>{{ pledgeData.name }}</p>
        </div>
        <div class="pledge_dialog_price">
          <div>
            <p class="pledge_dialog_price_title">{{ 'LPs' }}</p>
            <p class="pledge_dialog_price_value">{{((this.$store.state.balance.LPBalacnce*1)|| '0')}}LP</p>
          </div>
          <div>
            <p class="pledge_dialog_price_title">APR</p>
            <p class="pledge_dialog_price_value">{{ pledgeData.apr }}</p>
          </div>
        </div>

        <div class="pledge_dialog_tab">
          <p
            :class="`${
              pledgeTab == 1 && 'pledge_dialog_tab_item_choose'
            } pledge_dialog_tab_item`"
            style="margin-right: 3rem"
            @click="changepledgeTab(1)"
          >
            {{ $t("lang.zhiya") }}
          </p>
          <p
            :class="`${
              pledgeTab == 2 && 'pledge_dialog_tab_item_choose'
            } pledge_dialog_tab_item`"
            @click="changepledgeTab(2)"
          >
            {{ $t("lang.shuhui") }}
          </p>
        </div>
        <!-- LP 授权区域 -->
        <div class="pledge_approve_view" v-if="LPApproveView">
          <p>
            {{$t("lang.souquanUMIDAO")}}<span>{{ pledgeData.name }}</span>
          </p>
          <div class="approve_btn canclick">
            <UmiButton
              :disable="approveStatus==2"
              :text="$t('lang.shouquan')"
              @clickFn="approveFn(2)"
            />
          </div>
        </div>
        <!--  -->
        <div class="pledge_input_view" v-show="pledgeInputView" :style="isfocus && {background: '#EEF8F4'}">
          <input
            type="number"
            :placeholder="$t('lang.shurutokenid')"
            name=""
            id="" 
            v-model="pledgeTokenid" 
            @blur="isfocus = false"
            @focus="isfocus = true"
            :style="isfocus && {background: '#EEF8F4',caretColor: '#6fcaa0'}"
           
          />
          <!-- <p class="userCoin" v-if="pledgeData.name == 'UMI'">{{ getUseCoin()}}</p> -->
          <div class="pledge_input_view_coin">
            <img :src="pledgeData.img" alt="" />
            <p>{{  "LP" }}</p>
          </div>
        </div>

        <div
          class="approve_btn"
          v-if="noBalance"
          style="background: #D8F3E6;cursor: not-allowed;"
        >
          {{ $t("lang.yuebuzu") }}
        </div>
        <div
          v-else
          class="approve_btn canClick"
          v-show="pledgeInputView"
        >
          <UmiButton
            :disable="stakeStatus==1 || !canPledge"
            :text="$t('lang.zhiya')"
            @clickFn="pledgeFn"
          />
        </div>

        <div
          class="approve_btn canClick"
          v-show="pledgeTab == 2"
        >
          <UmiButton
            :disable="redeemStatus==2 || !canRedeem || !date_redeem"
            :text="$t('lang.shuhui')"
            @clickFn="redeemFn"
          />
        </div>
        <div
          class="approve_btn canClick"
          v-show="pledgeTab == 2"
        >
          <UmiButton
            :disable="redeemStatus==2 || !canRedeem || !date_redeem"
            :text="$t('lang.shuhuizhiya')"
            @clickFn="redeemPledgeFn"
          />
        </div>

        <div class="pledge_dialog_info" v-show="pledgeTab == 2">
          <p>{{ $t("lang.daifafangjiang") }}</p>
          <p>{{(info.willsendNum * 1).toFixed(3)}} UMI</p>
        </div>
        <div class="pledge_dialog_info" v-show="pledgeTab == 2">
          <p>{{ $t("lang.kelingqujiang") }}</p>
          <p>{{(info.canGetNum * 1).toFixed(3)}} UMI</p>
        </div>
        <div class="pledge_dialog_info" v-show="pledgeTab == 1">
          <p>{{ $t("lang.zhiyayue") }}</p>
          <p>
            {{ (info.stakeNum * 1).toFixed(3) }}
            {{  "UMI"  }}
          </p>
        </div>
        <div class="pledge_dialog_info" v-show="pledgeTab == 1">
          <p>{{ $t("lang.daoqijiangli") }}</p>
          <p>{{ (info.willsendNum * 1).toFixed(3) }} UMI</p>
        </div>
        <!-- <div class="pledge_dialog_info">
          <p>{{ $t("lang.nianhuaAPR") }}</p>
          <p>{{ pledgeData.apr }}</p>
        </div> -->
        <div class="pledge_dialog_info" v-show="pledgeTab == 1">
          <p>{{ $t("lang.zhiyashijian") }}</p>
          <p>{{ $t("lang.month" + pledgeData.time) }}</p>
        </div>
        <div class="pledge_dialog_info" v-show="pledgeTab == 2">
          <p>{{ $t("lang.daoqiriqi") }}</p>
          <p>{{ info.endTime }}</p>
        </div>
        <!-- 交易状态 -->
        <div class="transcation_view">
          <!-- approve -->
          <div class="transcation_content" v-if="(LPApproveView) && pledgeTab==1">
            <div class="get_status_view_img">
              <img src="./../../assets/image/waite.png" alt="" v-if="approveStatus==1">
              <loadingIcon v-if="approveStatus==2" svgW="1.3rem" svgH="1.3rem"/>
              <img src="./../../assets/image/success.png" alt="" v-if="approveStatus==3">
            </div>
            <p>{{approveStatusTxt}}</p>
          </div>
          <!-- tokenid 错误 -->
          <div class="transcation_content" v-if="canPledge && tokenidErr && pledgeTab==1 ">
            <div class="get_status_view_img" style="height:18px">
              <img src="./../../assets/image/error.png" alt="" >
            </div>
            <p>{{tokenidErr}}</p>
          </div>
          <!-- stake -->
          <div class="transcation_content" v-if="pledgeInputView && canPledge && stakeStatus ">
            <div class="get_status_view_img">
              <loadingIcon v-if="stakeStatus==1" svgW="1.3rem" svgH="1.3rem"/>
              <img src="./../../assets/image/success.png" alt="" v-if="stakeStatus==2">
            </div>
            <p>{{stakeStatusTxt}}</p>
          </div>
          <!-- redeem -->
          <div class="transcation_content" v-if=" canRedeem && pledgeTab==2">
            <div class="get_status_view_img">
              <img src="./../../assets/image/waite.png" alt="" v-if="redeemStatus==1">
              <loadingIcon v-if="redeemStatus==2" svgW="1.3rem" svgH="1.3rem"/>
              <img src="./../../assets/image/success.png" alt="" v-if="redeemStatus==3">
            </div>
            <p>{{redeemStatusTxt}}</p>
          </div>
          <!--赎回的提示文字 -->
          <p class="redeem_tip" v-show="pledgeTab==2 && $i18n.locale=='zh'">多笔质押展示最后一笔质押到期时间，赎回未到期的不发放奖励</p>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import utils from "@/utils/utils";
import abi from './../../assets/js/XRC20abi'
import PcModal from "./../../components/PcModal.vue";
import modalLoading from "../../components/loading.vue";
import UmiButton from '../../components/UmiButton.vue';
import LoadingIcon from '../../components/loadingIcon.vue';
var shownotify = false;
var timer = null;
export default {
  name: "detail",
  components: {
    PcModal,
    modalLoading,
    UmiButton,
    LoadingIcon
  },
  props: ["pledgeData", "LPAllow" ],
  data() {
    return {
      // 质押 or 赎回
      pledgeTab: 1,
     
      // 质押lp 
      pledgeTokenid: '',

      // 能否质押
      canPledge: false,
      //  质押信息
      info: {
        stakeNum: '0.000',
        willsendNum: '0.000',
        canGetNum: '0.000',
        endTime: ''
      },
      canRedeem: false,
      date_redeem: true, // 当质押为12 个月的时候，需要到期才能赎回

      approveStatus: 1, // 1等待授权  2授权中 3授权成功
      approveStatusTxt: `${this.$t("lang.dengdaishouquan")}...`,

      noBalance: false,

      stakeStatus: null, // null '' 1交易中 2质押成功
      stakeStatusTxt: '',

      redeemStatus: 1, // 1等待赎回  2赎回中 3赎回成功
      redeemStatusTxt: `${this.$t("lang.dengdaishuhui")}...`,

      tokenidErr: '', // 查询tokenid 错误获取tokenid 余额不足

      isfocus: false // =输入框是否聚焦
    };
  },
  computed: {
    
    LPApproveView() {
      return (
        this.pledgeTab == 1 && !this.LPAllow
      );
    },
    pledgeInputView() {
      return this.pledgeTab == 1 && !!this.LPAllow;
      
    },
  },
  watch: {
    
    "$store.state.userAddress"() {
      this.getPrize();
    },
    "info.stakeNum" () {
      if(this.info.stakeNum*1 > 0) {
        this.canRedeem = true;
      }
    },
    pledgeTokenid() {
      if(this.pledgeTokenid) {
        this.canPledge = true;
      }
    }
  },
  mounted() {
    this.getPrize();
  },
  methods: {
    
    // 点击 背景 关闭弹窗
    clickPledgeBg(e) {
      if(e.target.className == 'pledge_dialog') {
        this.$emit('close');
      }
    },
    // genuine当前日期 获取到期 日期
    getDuedate(start,monthNum) {
      if(!start || start *1 == 0 ) {
        return ''
      }
      var s = new Date((start+'000')*1);
      var year = s.getFullYear(); //获取当前日期的年份
      var month = s.getMonth() + 1; //获取当前日期的月份
      var day = s.getDate(); //获取当前日期的日
      var days = new Date(year, month, 0);
      days = days.getDate(); //获取当前日期中的月的天数
      var hour = s.getHours();
      var min = s.getMinutes();
      var year2 = year;
      var month2 = parseInt(month) + parseInt(monthNum);
      if (month2 > 12) {
        year2 =
          parseInt(year2) +
          parseInt(parseInt(month2) / 12 == 0 ? 1 : parseInt(month2) / 12);
        month2 = parseInt(month2) % 12;
      }
      var day2 = day;
      var days2 = new Date(year2, month2, 0);
      days2 = days2.getDate();
      if (day2 > days2) {
        day2 = days2;
      }
      if (month2 < 10) {
        month2 = "0" + month2;
      }

      var t2 = `${year2}-${month2}-${day2} ${hour<10 ? '0'+hour : hour}:${min<10 ? '0'+min : min} `;
      return t2;
    },
   
    async approveFn(type) { // 
      const { userAddress, LP_contract } = this.$store.state;
      this.approveStatus = 2;
      this.approveStatusTxt = this.$t("lang.shouquanzhong")+'...'
      try {
        this.approvingStatus2 = 2;
        const res = await LP_contract.methods
        .setApprovalForAll(
          this.$api.DAO_Addr,
          true
        )
        .send({ from: userAddress });
        this.$emit('approvedLP',true);
        this.approveStatus = 3;
        this.approveStatusTxt = this.$t("lang.shouquanchenggong");
      } catch (error) {
        this.approveStatus = 1;
        this.approveStatusTxt = this.$t("lang.dengdaishouquan")+'...';
      }
    },
    pledgeFn() {
      const { time } = this.pledgeData;
      let lockType = "";
      
      this.stakeStatus = 1;
      this.stakeStatusTxt = this.$t("lang.zhiyazhong")+'...';
      
      lockType = (time == "6" ? 2 : 3);
      this.LPPledge(this.pledgeTokenid, lockType);
    },
    // 认购成功 或这领取成功 需要重新获取数据
    async regetData(type) {
      const { UMI_contract, LP_contract, userAddress, balance } = this.$store.state;
      const UMIres = await UMI_contract.methods.balanceOf(userAddress).call();
      const UMIBalance = utils.mathpow(UMIres,this.$api.UMI_Demical);
      const LPBalacnce = await LP_contract.methods.balanceOf(userAddress).call();
        if(balance.LPBalacnce == LPBalacnce) {
          timer = setTimeout(()=>{ this.regetData()},3000);
          return 
        }
        
        this.$store.commit('setBalance',{UMIBalance,LPBalacnce});
    },
    
    async LPPledge(num, lockType) {
      const { DAO_contract, userAddress } = this.$store.state;
      this.tokenidErr = '';
      try {
        const res = await DAO_contract.methods.queryLpByTokenId(num*1).call({from: userAddress})
        
        const nft_umi = utils.mathpow(res[1],this.$api.UMI_Demical);
        if(nft_umi*1 < 2000) {
          this.tokenidErr = this.$t("lang.yuebuzu");
          this.stakeStatus = null;
          this.stakeStatusTxt = '';
          return 
        }
      } catch (error) {
        this.stakeStatus = null;
        this.stakeStatusTxt = '';
        this.tokenidErr = this.$t("lang.tokeindcuowu");
        return 
      }
      
      shownotify = false;
      DAO_contract.methods
        .stakeLP(num*1, lockType)
        .send({ from: userAddress })
        .on("transactionHash", (hash) => {
          // 交易hash
        })
        .on("receipt", (receipt) => {
          // 交易已广播
          if(!shownotify) {
            this.stakeStatus = 2;
            this.stakeStatusTxt = this.$t("lang.zhiyachengong");
            const text = this.$t("lang.zhiyachengong");
            this.canRedeem = true;
            this.$notify({
              title: '',
              duration: 8000,
              dangerouslyUseHTMLString: true,
              message: `<div class="notify_success_view_pc"><img src="${require("../../assets/image/success.png")}"/><p>${text}</p></div>`
            });
            this.pledgeTokenid = '';
            timer = setTimeout(()=> {
              this.stakeStatus = null;
              this.stakeStatusTxt = '';
              this.getPrize(true);
            },2000)
            shownotify = true;
            this.regetData();
          }
        })
        .on("error", (error, receipt) => {
          // 交易失败
          this.stakeStatus = null;
          this.stakeStatusTxt = '';
        });
    },
  
    //  查询 奖励
    async getPrize(reget) {
      try {
        const { time } = this.pledgeData;
        let lockType = (time == "6" ? 2 : 3);
        const arrIndex = time=='6' ? 2 : 3;
        const { DAO_contract, userAddress, USDC_contract } = this.$store.state;
        const res = await DAO_contract.methods
          .getLpLineReward(userAddress,lockType)
          .call({ from: userAddress });
        const info = {
          stakeNum:  utils.mathpow((res.stakedAmount[arrIndex] || 0),this.$api.UMI_Demical),
          willsendNum:  utils.mathpow((res.targetRewardAmount[arrIndex] || 0),this.$api.UMI_Demical),
          canGetNum:  utils.mathpow((res.realRewardAmount[arrIndex] || 0),this.$api.UMI_Demical),
          endTime: this.getDuedate(res.startDay,this.pledgeData.time),
        }
        this.date_redeem = this.pledgeData.time==12 ? (new Date().getTime() > new Date(info.endTime).getTime() ) : true;
        if(this.info.stakeNum == info.stakeNum && reget) {
          timer = setTimeout(()=>{this.getPrize(reget)},2000)
        }
        this.info = info;
      } catch (error) {
        
      }
    },

    // 赎回
    redeemFn(status) {
      this.redeemStatus = 2;
      this.redeemStatusTxt = this.$t("lang.shuhuizhong")+'...';
      
      this.LPRedeem(status);
    },
   
    async LPRedeem() {
      shownotify = false;
      const { time } = this.pledgeData;
      let lockType = (time == "6" ? 2 : 3);
      const { DAO_contract, userAddress } = this.$store.state;
      DAO_contract.methods
        .withdrawLP(lockType)
        .send({ from: userAddress })
        .on("transactionHash", (hash) => {
          // 交易hash
        })
        .on("receipt", (receipt) => {
          if(!shownotify) {
            this.redeemStatus = 3;
            const text = this.$t("lang.shuhuichenggong");
            this.canRedeem = false;
            this.$notify({
              title: '',
              duration: 8000,
              dangerouslyUseHTMLString: true,
              message: `<div class="notify_success_view_pc"><img src="${require("../../assets/image/success.png")}"/><p>${text}</p></div>`
            });
            timer = setTimeout(()=> {
              this.redeemStatus = null;
              this.redeemStatusTxt = '';
              this.getPrize(true);
            },2000)
            shownotify = true;

            this.regetData()
          }
        })
        .on("error", (error, receipt) => {
          // 交易失败
          this.redeemStatus = 1;
          this.redeemStatusTxt = this.$t("lang.dengdaishuhui")+'...';
        });
    },
    // 赎回并质押
    redeemPledgeFn() {
      const { time } = this.pledgeData;
      let lockType = (time == "6" ? 2 : 3);
      shownotify = false;
      this.redeemStatus = 2;
      this.redeemStatusTxt = this.$t("lang.zhiyazhong")+'...';
      const { DAO_contract, userAddress } = this.$store.state;
      DAO_contract.methods
        .withdrawLPandLP(lockType)
        .send({ from: userAddress })
        .on("transactionHash", (hash) => {
          // 交易hash
        })
        .on("receipt", (receipt) => {
          // 交易已广播
          if(!shownotify) {
            this.redeemStatus = 3;
            const text = this.$t("lang.zhiyachengong");
            this.canRedeem = true;
            this.$notify({
              title: '',
              duration: 8000,
              dangerouslyUseHTMLString: true,
              message: `<div class="notify_success_view_pc"><img src="${require("../../assets/image/success.png")}"/><p>${text}</p></div>`
            });
            this.pledgeTokenid = '';
            timer = setTimeout(()=> {
              this.redeemStatus = null;
              this.redeemStatusTxt = '';
              this.getPrize(true);
            },2000)
            shownotify = true;
            this.regetData();
          }
        })
        .on("error", (error, receipt) => {
          // 交易失败
          this.redeemStatus = 1;
          this.redeemStatusTxt = this.$t("lang.dengdaishuhui")+'...';
        });
    },
    changepledgeTab(val) {
      this.pledgeTab = val;
    },
    closePledge_dialog() {
      this.$emit('close');
    },
    
  },
  destroyed() {
    clearTimeout(timer);
  }
};
</script>

<style scoped >
.pledge {
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 0.5rem;
  padding: 5.1rem 6.75rem;
  box-sizing: border-box;
}
.title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #26493e;
  margin-bottom: 5rem;
}
.buy_sencond {
  display: flex;
  align-items: center;
  margin-bottom: 4.4rem;
  justify-content: space-between;
  margin-bottom: 3.4rem;
}
.buy_sencond_item {
  flex: 1;
}
.buy_sencond_item p {
  font-size: 1rem;
  font-weight: 600;
  color: #9A9A9A;
  margin-bottom: 1.25rem;
  text-align: center;
}
.buy_sencond_item p:last-child {
  margin-bottom: 0;
  color: #26493e;
  font-size: 1.5rem;
}
.nft_card {
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 1.2rem 0px rgba(216, 243, 230, 1);
  border-radius: 1.5rem;
  padding: 4.05rem;
  box-sizing: border-box;
}
.nft_list_title,
.nft_list_row {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.nft_list_title div:first-child {
  width: 4.35rem;
  flex-shrink: 0;
  margin-right: 2.4rem;
}
.nft_list_title div:last-child {
  width: 11rem;
  flex-shrink: 0;
}
.nft_list_title p {
  font-size: 0.8rem;
  font-weight: bold;
  color: #9a9a9a;
  flex: 1;
}
.card_list_info_one {
  text-align: left;
  margin-right: 2.4rem;
}
.card_list_info_two {
  text-align: center;
  margin-right: 2.4rem;
}
.card_list_info_three {
  text-align: center;
}
.nft_list_row {
  margin-bottom: 2.5rem;
  align-items: center;
}
.nft_list_icon {
  width: 4.35rem;
  height: 2.5rem;
  flex-shrink: 0;
  margin-right: 2.4rem;
}
.nft_list_icon img {
  height: 2.5rem;
  width: auto;
  margin: auto;
  display: block;
}
.nft_list_row p {
  font-size: 0.8rem;
  font-weight: bold;
  color: #26493e;
  flex: 1;
}
.nft_list_btn {
  width: 10rem;
  flex-shrink: 0;
  height: 2.5rem;
  background: #6fcaa0;
  border-radius: 2.5rem;
  text-align: center;
  line-height: 2.5rem;
  font-size: 0.8rem;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}
.nft_list_btn:hover {
  background: #8DDDB8;
}


/* pledge_dialog */
.pledge_dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 90;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}
.pledge_dialog_view {
  width: 50rem;
  min-height: 35rem;
  background: #ffffff;
  border-radius: 1.5rem;
  padding: 2.25rem 3.5rem;
  box-sizing: border-box;
  position: relative;
}
.pledge_dialog_close {
  position: absolute;
  right: 2.5rem;
  top: 2.25rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pledge_dialog_close img {
  width: 1rem;
  height: 1rem;
}
.pledge_dialog_title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3.5rem;
}
.pledge_dialog_title img {
  height: 2rem;
  margin-right: 0.75rem;
}
.pledge_dialog_title p {
  font-size: 1.2rem;
  font-weight: bold;
  color: #26493e;
}
.pledge_dialog_price {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.pledge_dialog_price div:first-child {
  margin-right: 23rem;
}
.pledge_dialog_price_title {
  font-size: 0.8rem;
  font-weight: bold;
  color: #9a9a9a;
  margin-bottom: 0.7rem;
  text-align: center;
}
.pledge_dialog_price_value {
  font-size: 1rem;
  font-weight: bold;
  color: #26493e;
  text-align: center;
}
.pledge_dialog_tab {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pledge_dialog_tab_item {
  width: 2rem;
  font-size: 1rem;
  font-weight: 500;
  color: #9a9a9a;
  padding-bottom: 8px;
  cursor: pointer;
}
.pledge_dialog_tab_item_choose {
  border-bottom: 2px solid #26493e;
  color: #26493e;
}
.pledge_approve_view {
  margin-top: 1rem;
  color: #9a9a9a;
  font-size: 0.7rem;
}
.pledge_approve_view p {
  height: 2.5rem;
  text-align: center;
  line-height: 2.5rem;
  margin: auto;
}
.pledge_approve_view span {
  color: #26493e;
  font-weight: 600;
}
.pledge_input_view {
  display: flex;
  align-items: center;
  width: 27rem;
  height: 2.5rem;
  background: #f8f8f8;
  border-radius: 3rem;
  margin: auto;
  margin-top: 1rem;
  overflow: hidden;
}
.pledge_input_view .userCoin {
  font-size: 0.7rem;
  color: #BDBDBD;
  font-weight: 500;
  margin-right: 0.5rem;
  flex-shrink: 0;
}
.pledge_input_view input {
  flex: 1;
  height: 2rem;
  padding: 0 1.5rem;
  background: none;
  box-sizing: border-box;
  font-size: 1.2rem;
  font-weight: 500;
  color: #26493E;
  border: none;
  outline: none;
}
.pledge_input_view input::placeholder {
  font-size: 1rem;
  color: #BDBDBD;
}
.pledge_input_view_coin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.4rem;
  height: 2rem;
  border-left: 2px solid #eeeeee;
  flex-shrink: 0;
}
.pledge_input_view_coin img {
  height: 1.25rem;
  width: auto;
  margin-right: 5px;
}
.pledge_input_view_coin p {
  font-size: 1rem;
  font-weight: bold;
  color: #26493e;
}
.approve_btn {
  margin: auto;
  width: 20rem;
  height: 3rem;
  background: #6fcaa0;
  border-radius: 3rem;
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 3rem;
  cursor: pointer;
  overflow: hidden;
}
.canClick:hover {
  background: #8DDDB8;
}
.pledge_dialog_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
}
.pledge_dialog_info p {
  font-size: 1rem;
  font-weight: bold;
  color: #26493e;
}

/* 交易状态 */
.transcation_view {
  width: 100%;
  height: 3rem;
  border-top: 1px  solid #E0E3E8;
  margin-top: 1.25rem;
  display: flex;
  align-items: flex-end;
  position: relative;
}
.redeem_tip {
  position: absolute;
  right: 5px;
  top: 0;
  height: 3rem;
  font-size: 0.75rem;
  color: #333;
  display: flex;
  align-items: flex-end;
}
.transcation_content {
  display: flex;
  align-items: center;
}
.get_status_view_img {
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
}
.get_status_view_img img {
  width: 1rem;
  min-height: 1rem;
}
.transcation_content p {
  color: #4A4A4A;
  font-size: 0.75rem;
  letter-spacing: 1px;
}
</style>
