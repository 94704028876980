
let data = {
  // pool: '0xd7badc7737E00E257d3FCeaB6b7Dc25385BE382d', //储备金入账地址
  // pool1: '0xEB0Cf004179084C6B065ab7e71c2FFa2955BAe5e', //LP流动性入账地址

  UMI_Addr: '0xAAc62C7816bD274Dd1587540BdB5BEF84E096152', 

  USDC_Addr: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',

  DAO_Addr: '0x79502e312e9174784Bd907831Fd1A95dA6B7C90F',

  NFT_Addr: '0x8E22d541dEe9CcF303a6870f775C3A5d4A2D8A7D',

  ETH_Addr: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',

  LP_Addr: '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',

  UMI_Demical: 8,
  USDC_Demical: 6,
  ETH_Demical: 18,
  chainId: 1 // 测试网 3  主网 1
}
const key = process.env.NODE_ENV;
// switch (key) {
//   // 打包测试环境
//   case 'test':
//     obj = {
//       httpIp: '',
//       httpHash: ''
//     }
//     break;
//     // 在线调试环境
//   case 'development':
//     obj = {
//       httpIp: 'http://api.okstarter.finance',
//       // httpIp: 'http://api-bsc.testok.218cai.cn',
//       httpHash: 'https://www.oklink.com/okexchain-test/tx/'
//     }
//     break;
//     // 打包正式环境
//   default:
//     obj = {
//       httpIp: 'http://api.okstarter.finance',
//       // httpIp: 'http://api-bsc.testok.218cai.cn',
//       httpHash: 'https://www.oklink.com/okexchain-test/tx/',
      
//     }
//     break;
// }



export default data