// 俄语   key由 文字中的四个字的拼音组成
export const lang = {
  'shouye':'Главная',
  'rengou':'Покупка',
  'zhiya':'Залог',
  'lianjieqianbao':'Подключить кошелек',
  'quanqiulingxian':'Ведущий в мире крипто-нативный DAO',
  'zhuanzhuyu':'Создание DeFi, Web3 и Metaverse с помощью сообщества и криптотехнологий для получения дивидендов от роста отрасли для сообщества.',
  'canyurengou':'Купить',
  'daibifenpei':'Распределение токенов',
  'zongfaxingliang':'Поставка токенов',
  'chubeijin':'Зарезервировать фонд',
  'shequeDAO':'Сообщество DAO',
  'liudongxingLP':'Поставщик ликвидности',
  'tuandui':'Команда',
  'rengoujiangli':'Вознаграждения за покупку',
  'luxiantu':'Дорожная карта',
  'jiamijijin':'Первопроходец среди криптовалютных фондов',
  'umiCashchengli':'Май 2018 г., основание umiCash',
  'umiCashv1shangxian':'Май 2019 г., запуск приложения umiCash',
  'umiCashzhishujijin':'Май 2020 г., публикация индексного фонда umiCash',
  'umiCashxiaomubiao':'Июль 2021 г., публикация базовой цели umiCash',
  'hunhedapan':'Сентябрь 2021 г., рост чистой стоимости фонда рынка гибридных инструментов 10 до 10.0',
  'umiCashzuoshishangban':'Декабрь 2021 г., запуск версии маркет-мейкер umiCash',
  'umiDeFichanpinshangxian':'1-й квартал., публикация продуктов umiDeFi',
  'umiDAOzhililuntan':'2-й квартал., публикация форума управления umiDAO',
  'UMIDAOzhiliguifan':'квартал., запуск стандарта управления UMIDAO',
  'UMIDAOweiyuanhui':'Выборы комитета UMIDAO',
  'umiCustodyjiamizhiguan':'Управление активами umiCustody',
  'SatQuantDEXlianghua':'Количественный продукт SatQuant DEX',
  'UMIDAOshequqianbao':'квартал., распределение нескольких подписей кошелька сообщества UMIDAO',
  'umiCashProjiamilianghua':'Разделение криптовалюты umiCashPro',
  'FundDappjiamijijin':'Криптовалютный фонд DAPP',
  'shequn':'Сообщество',
  'rengouyibiao':'Панель управления покупками',
  'woderengou':'Мои покупки',
  'rengouzongliang':'Общая покупка (фаза II)',
  'rengouzhekou':'Скидка',
  'shuocangshouyi':'Вознаграждение за локап',
  'zongshouyi':'Общий ROI',
  'UMIjiage':'Цена UMI',
  'rengoujindu':'Скорость выполнения',
  'yiwancheng':'Завершено',
  'shengyu':'Остаток',
  'danrengouUMI':'Единичная покупка UMI',
  'shuoding30':'6-месячная блокировка с освобождением 40%,и последующее освобождение 10% в месяц',
  'rengouzichan':'Фондовые активы',
  'zijinyongtu1':"Использование фондов: 80% фонда покупок используется для резервного фонда сообщества, который управляется с помощью кошелька с несколькими подписями, а 20% фонда используется для управления проектом.",
  'qigoujine':'Минимальная покупка',
  'zuLPrengou' :'Организация покупки поставщиком ликвидности',
  'shuoding7':'6-месячная блокировка с освобождением 40%,и последующее освобождение 10% в месяц',
  'zijinyongtu2':'Использование фондов: 80% фонда покупок используется для резервного фонда сообщества, а 20% фонда используется для управления проектом. Оставшийся фонд используется для группирования поставщика ликвидности UMI-USDC и попадает в пул uniswap.',
  'lingquUMI':'Получить UMI',
  'qigoumenkan':'Минимальная покупка',
  'yirengou':'Куплено',
  'yilingqu':'Полученный объем',
  'kelingqu':'Объем для получения',
  'daifafang':'Объем в ожидании',
  'nianhuaAPR':'APR',
  'rengoujine':'Объем покупки',
  'yue':'Балансы',
  'duiyingUMI-USDC':'Est. Pool Allocation',
  'duiyingUMI': 'Купить',
  'danbirengou':'Единична покупка UMI',
  'UMIzhekou':'Скидка UMI',
  'youhui':'Скидки',
  'daoqilingqu':'Вы получите',
  'pizhunrengou':'Одобрить',
  'rengouchengong':'Покупка завершена',
  'lingqurengouNFT':'Купить NFT',
  'gongxi':'Поздравляем',
  'lingquchengong':'Получено',
  'dianjichakan':'Проверить',
  'wodeNFT':'Мой NFT',
  'yiqiNFT':'NFT фазы I',
  'dangqiandizhi':'Текущий адрес не указан в белом списке',
  'ninzaiyiqi':'Вы указаны в белом списке NFT фазы I',
  'lingquyiqi':'Получить NFT фазы I',
  'nftmingzi1':'Статус участника UMIDAO (фаза I)',
  'nftmingzi2':'Статус участника UMIDAO (фаза II)',
  'bianhao':'ID токена',
  'chuangjianshijian':'Дата создания',
  'heyuedizhi':'Контрактный адрес',
  'jiaoyihaxi':'Хэширование сделки',
  'querengoumai':'Подтвердить получение',
  'yuebuzu':'Недостаточный баланс',
  'querenlingqu':'Подтвердить получение',
  'zhiyamenkan':'Минимальная ставка',
  'zongshuocang':'TVL',
  'zichanmingchen':'Актив',
  'zhiyazhouqi':'У ловят залога',
  'shuhui':'Обменять',
  'souquanUMIDAO':'Авторизовать UMIDAO на передачу залога поставщику ликвидности ',
  'shouquan':'Авторизовать',
  'zhiyayue':'Ваш залоговый баланс',
  'daoqijiangli':'Общее вознаграждение',
  'jiaogeshijian':'Срок вестирования',
  'shuhuizhiya':'Обменять и заложить',
  'daifafangjiang':'Вознаграждения на ожидании',
  'kelingqujiang':'Вознаграждения для получения',
  'daoqihou':'Время до полного вестирования',
  'daoqiriqi':'Дата вестирования',
  '2022_6_1':'1 января 2022 г.',
  'dengdaiqueren':'Ожидание подтверждения',
  'querenzheci':'Подтвердите транзакцию в кошельке',
  'wodeqianbao':'Мой кошелек',
  'duankailianjie':'Отключить',
  'lianjieshiba':'Сбой подключения',
  'lianjiebeijujie':'Сбой подключения, в подключении отказано',
  'wanluocuowu':'Сбой сети',
  'qingqiehuanwang':'Сбой сети, подключитесь к основной сети',
  'qiehuanwangluo':'Переключить на основную сеть',
  'pizhunshibai':'Сбой одобрения',
  'pizhunjujie':'Отказано в запросе одобрение',
  'jiaoyishibai':'Сбой транзакции',
  'jiaoyijujie':'Отказано в запросе транзакции',
  'lianjieMetamask':'Подключить к кошельку MetaMask',
  'lianjieWalletConnect':'Подключить через WalletConnect',
  'zhiyachengong':'Успешный залог',

  'yizhiya':'Заложенный', // 增加
  'month1': '1 месяц',
  'month3': '3 месяца',
  'month6': '6 месяца',
  'month12': '12 месяца',
  'gasbutie': 'Бонус Gas',
  // 
  'jiaoyichenggong': 'Транзакция прошла успешно',
  'guanbi': 'Закрывать',
  'yitijiao': 'Транзакция отправлена',
  'zhixingchenggong': 'Транзакция выполнена успешно',
  'dengdaiqueren': 'Ожидание подтверждения',
  'dailingqu': 'В ожидании получения претензии',
  'lingquzhong': 'Идет утверждение',

  'dengdaishouquan': 'Ожидание авторизации',
  'shouquanzhong': 'Идет авторизация',
  'shouquanchenggong': 'Авторизация выполнена успешно',
  'jiaoyizhong': 'Трейдинг',
  'dengdaishuhui': 'Ожидание погашения',
  'shuhuizhong': 'Идет погашения',
  'shuhuichenggong': 'Погашение выполнено успешно',
  'zhiyazhong': 'Делать ставку',
  'goumaichenggong': 'Покупка успешно завершена',
  'yisifang': 'Освобождено',
  'shouquanrengou': 'Авторизовать покупки',
  'zhiyashijian': 'Срок действия ставки',
  'keyong1': 'Доступный UMI  ',
    'shurutokenid': 'Введить TokenID LP',
  'tokeindcuowu': 'TokenID неправильный',
  'qianbao': 'Кошелек',

  'nftbannertxt': 'В будущей метавселенной капитал монополизирует технологии, чтобы контролировать обычных граждан. Высотные здания появляются в большом количестве в сумерках, гигантские механические лодки-драконы проходят по небу между зданиями, а на земле бедняки в углах трущоб дрожат.Здесь есть только капитал и муравьи, наполненные преступлениями и потерями. NEZHA, который держит в руках черную технологию, хочет разорвать туман капитала, сжечь огнем всю ложь дотла и попытаться найти скрытую правду',
  'nezhamanghe': 'Мистери бокс NEZHA',
  'tongguozhuzao': 'Получение NEZHANFTчерез монетный двор, претендуя на выброску с воздуха через покупку UMI и ставку LP',
  'zhuzao': 'Монетный двор',
  'meixiaochannft': 'С помощью монетного двора генерирует3 NFT в час, генерирует 72 NFT в день',
  'kaishizhuzao': 'Монетный двор сейчас',
  'kongtou': 'Выброска с воздуха',
  'rengouzhiyalingqunft': 'Получение выброски с воздухаNFT через покупку UMI и ставку LP',
  'lingqukongtou': 'Получение выброски с воздуха',
  'fenpeizhuzaoguize':'Правило распределения и монетного двора',
  'nezhaoriental': 'Общий запас NEZHAOriental составляет 10,000, среди них 1,000 зарезервированы для команды, 1,000 выброшены с воздуха для сообщества, 8,000 проданы монетным двором',
  'tuanduibaoliu': 'Зарезервированы для команды',
  'kaifatuandui': '500 для команды разработчиков, 500 для команды IP',
  'shequkongtou': "Выброска с воздуха сообществом",
  'rengouzhiyabaimingdan': "1,000 белых списков покупки/ставки",
  'zhuzaofashou': "Продажа монетного двора",
  '8000mint': "8,000 монетного двора 1-3, макс.3 NFT",
  'zhuzaoguize': "Правила монетного двора",
  '8000manghe': "Монетный двор в 8,000 Мистери боксов, проданный через канал монетного двора на домашней странице. Мистери бокс генерирует 3 NEZHA в час, а каждый день генерирует 72 NEZHA, пользователь может покупать NEZHANFT максимум 3",
  'womenjiangchangqi': "Мы будем осуществлять планирование NFT в соответствии с дорожной картой в течение длительного времени",
  'womenweinezha': "Мы запланировали маршрут развития дляNEZHA. Как только будет достигнута цель по проценту продаж, мы будем стремиться к достижению заявленных целей",
  "xianshanghualang": "Онлайн-Галерея",
  "nezhachaowan": "Разработка производных продуктов модных игр NEZHA",
  "xianxiaip": "Совместный тур по выставке Оффлайн IP",
  "nezhadujia": "Эксклюзивные товары NEZHA/Коллекция ограниченной серии",
  "goumaixuni": "Покупать виртуальную землю для отображения пространства метавселенной",
  "xianxiashuzi": "Офлайн-аукцион Цифрового Искусства",
  "OpenSea": "Обратный выкуп OpenSea",
  "qidianjuhui": "Праздничные обряды& Вечеринки",
  "zhuzaozongliang": "Сумма монетного двора",
  "zhuzajiage": "Цена монетного двора",
  "yixuan":"Выбранный",
  "zongji":"Итого",
  "zhuzaowancheng":"Готов монетный двор",
  "ccobanquan":"NEZHANFT принимает соглашение об авторском праве CC0 для реализации монетного двора",
  "kongtounezha":"Выброска с воздуха NEZHA",
  "kongtouzongliang":"Сумма выброски с воздуха",
  "yikongtou":"Выброшено с воздуха",
  "wodekongtou":"Моя Выброска с воздуха",
  "buzaibaimingdan":"Вне Белого списка",
  "zhuzaochenggong":"Монетный двор успешен",

  "yaoqinglianjie": 'Ссылка на приглашение',
  "fuzhi": "Копировать",
  "fuzhichenggong": "Копировать успешно",
  "dakaiqianbao": "Открой свой децентрализованный кошелек",
  "lianjiefuzhi": "Копируя ссылку на сайт, литье происходит в браузере кошелька"
}