// 中文  key由 文字中的四个字的拼音组成
export const lang = {
  'shouye':'首页',
  'rengou':'认购',
  'zhiya':'质押',
  'lianjieqianbao':'连接钱包',
  'quanqiulingxian':'全球领先的加密原住民社区自治组织',
  'zhuanzhuyu':'由社区和加密技术构建DeFi、Web3、元宇宙，为社区捕获行业增长红利。',
  'canyurengou':'参与认购',
  'daibifenpei':'代币分配',
  'zongfaxingliang':'总发行量',
  'chubeijin':'储备金',
  'shequeDAO':'社区DAO',
  'liudongxingLP':'流动性LP',
  'tuandui':'团队',
  'rengoujiangli':'认购奖励',
  'luxiantu':'路线图',
  'jiamijijin':'umiCash加密基金开创者',
  'umiCashchengli':'2018.5 umiCash成立',
  'umiCashv1shangxian':'2019.5 umiCash APP v1.0上线',
  'umiCashzhishujijin':'2020.5 umiCash指数基金上线',
  'umiCashxiaomubiao':'2021.7 umiCash小目标上线',
  'hunhedapan':'2021.9 混合大盘基金10净值突破10.0',
  'umiCashzuoshishangban':'2021.12 umiCash做市商版本发布',
  'umiDeFichanpinshangxian':'Q1 umiDeFi产品上线',
  'umiDAOzhililuntan':'Q2 umiDAO治理论坛上线',
  'UMIDAOzhiliguifan':'UMIDAO治理规范发布',
  'UMIDAOweiyuanhui':'UMIDAO委员会选举',
  'umiCustodyjiamizhiguan':'umiCustody加密资管产品',
  'SatQuantDEXlianghua':'SatQuant DEX量化产品',
  'UMIDAOshequqianbao':'UMIDAO社区钱包多签分配',
  'umiCashProjiamilianghua':'umiCashPro加密量化',
  'FundDappjiamijijin':'Fund Dapp加密基金',
  'shequn':'社群',
  'rengouyibiao':'认购仪表盘',
  'woderengou':'我的认购',
  'rengouzongliang':'认购总量（2期）',
  'rengouzhekou':'认购折扣',
  'shuocangshouyi':'锁仓收益',
  'zongshouyi':'总收益',
  'UMIjiage':'UMI价格',
  'rengoujindu':'认购进度',
  'yiwancheng':'已完成',
  'shengyu':'剩余',
  'danrengouUMI':'UMI认购',
  'shuoding30':'锁定6个月释放40%，随后每月线性释放10%',
  'rengouzichan':'认购资产',
  'zijinyongtu1':'资金用途：认购金的80%用于社区储备金，由社区多签钱包管理；20%用于项目运营资金。',
  'qigoujine':'起购金额',
  'zuLPrengou' :'组LP认购',
  'shuoding7':'锁定6个月释放40%，随后每月线性释放10%',
  'zijinyongtu2':'资金用途：认购UMI的80%用于社区储备金，20%用于项目运营资金；其余资金用于组UMI-USDC LP对，并注入uniswap资金池。',
  'lingquUMI':'领取UMI',
  'qigoumenkan':'起购门槛',
  'yirengou':'已认购',
  'yilingqu':'已领取',
  'kelingqu':'可领取',
  'daifafang':'待发放',
  'nianhuaAPR':'年化APR',
  'rengoujine':'认购金额',
  'yue':'余额',
  'duiyingUMI-USDC':'对应UMI-USDC LP',
  'duiyingUMI': '对应UMI',
  'danbirengou':'单币认购',
  'UMIzhekou':'UMI折扣',
  'youhui':'优惠',
  'daoqilingqu':'到期领取UMI数量',
  'pizhunrengou':'批准认购',
  'rengouchengong':'认购成功',
  'lingqurengouNFT':'领取认购NFT',
  'gongxi':'恭喜',
  'lingquchengong':'领取成功',
  'dianjichakan':'点击查看',
  'wodeNFT':'我的NFT',
  'yiqiNFT':'一期NFT',
  'dangqiandizhi':'当前地址不在白名单中',
  'ninzaiyiqi':'您已在一期NFT白名单',
  'lingquyiqi':'领取一期NFT',
  'nftmingzi1':'UMIDAO Membership（Phase I）',
  'nftmingzi2':'UMIDAO Membership（Phase II）',
  'bianhao':'编号',
  'chuangjianshijian':'创建时间',
  'heyuedizhi':'合约地址',
  'jiaoyihaxi':'交易哈希',
  'querengoumai':'确认购买',
  'yuebuzu':'余额不足',
  'querenlingqu':'确认领取',
  'zhiyamenkan':'质押门槛',
  'zongshuocang':'TVL',
  'zichanmingchen':'资产名称',
  'zhiyazhouqi':'质押周期',
  'shuhui':'赎回',
  'souquanUMIDAO':'授权UMIDAO质押',
  'shouquan':'授权',
  'zhiyayue':'你的质押余额',
  'daoqijiangli':'到期奖励',
  'jiaogeshijian':'交割时间',
  'shuhuizhiya':'赎回并质押',
  'daifafangjiang':'待发放奖励',
  'kelingqujiang':'可领取奖励',
  'daoqihou':'到期后',
  'daoqiriqi':'到期日期',
  '2022_6_1':'2022年6月1日',
  'dengdaiqueren':'等待确认',
  'querenzheci':'确认这次交易在你的钱包',
  'wodeqianbao':'我的钱包',
  'duankailianjie':'断开连接',
  'lianjieshiba':'连接失败',
  'lianjiebeijujie':'连接失败，连接被拒绝',
  'wanluocuowu':'网络错误',
  'qingqiehuanwang':'网络错误，请切换到主网',
  'qiehuanwangluo':'切换到主网',
  'pizhunshibai':'批准失败',
  'pizhunjujie':'批准请求被拒绝',
  'jiaoyishibai':'交易失败',
  'jiaoyijujie':'交易请求被拒绝',
  'lianjieMetamask':'连接到你的MetaMask钱包',
  'lianjieWalletConnect':'连接到WalletConnect',
  'zhiyachengong':'质押成功',

  'yizhiya':'已质押',
  'month1': '1个月',
  'month3': '3个月',
  'month6': '6个月',
  'month12': '12个月',
  'gasbutie': 'Gas补贴',
  // 
  'jiaoyichenggong': '交易成功',
  'guanbi': '关闭',
  'yitijiao': '已提交交易',
  'zhixingchenggong': '交易执行成功',
  'dengdaiqueren': '等待确认',
  'dailingqu': '待领取',
  'lingquzhong': '领取中',

  'dengdaishouquan': '等待授权',
  'shouquanzhong': '授权中',
  'shouquanchenggong': '授权成功',
  'jiaoyizhong': '交易中',
  'dengdaishuhui': '等待赎回',
  'shuhuizhong': '赎回中',
  'shuhuichenggong': '赎回成功',
  'zhiyazhong': '质押中',
  'goumaichenggong': '购买成功',
  'yisifang': '已释放',
  'shouquanrengou': '授权认购',
  'zhiyashijian': '质押时间',
'keyong1': 'UMI可用  ',
    'shurutokenid': '输入LP TokenID',
  'tokeindcuowu': 'TokenID 错误',
  'qianbao': '钱包',

  'nftbannertxt': '在未来元宇宙中，资本垄断了科技用以控制普通公民。夜幕下高楼林立，巨型机械龙船从楼宇间的天空划过，地面上，贫民窟角落的穷人们在颤栗。这里只有资本和蝼蚁，弥漫着犯罪和迷途。手握黑科技的NEZHA想去撕开资本的迷雾，用火把一切谎言烧成灰烬试图，去寻找那个被掩盖的真相……',
  'nezhamanghe': 'NEZHA盲盒',
  'tongguozhuzao': '通过铸造可获得NEZHA NFT，参与认购UMI与LP质押可领取空投NFT',
  'zhuzao': '铸造',
  'meixiaochannft': '每小时产出3个NFT，每天产出72个NFT',
  'kaishizhuzao': '开始铸造',
  'kongtou': '空投',
  'rengouzhiyalingqunft': '通过认购UMI和质押LP来领取NFT空投',
  'lingqukongtou': '领取空投',
  'fenpeizhuzaoguize':'分配与铸造规则',
  'nezhaoriental': 'NEZHA Oriental共产出10000个NEZHA，其中团队保留1000个，社区空投1000个，铸造发售8000个',
  'tuanduibaoliu': '团队保留',
  'kaifatuandui': '开发团队500个，IP团队500个',
  'shequkongtou': "社区空投",
  'rengouzhiyabaimingdan': "1000 个认购/质押白名单",
  'zhuzaofashou': "铸造发售",
  '8000mint': "8000个 MINT1-3个之间，最多3个NFT",
  'zhuzaoguize': "铸造规则",
  '8000manghe': "8000个盲盒MINT，通过主页的铸造渠道进行发售。盲盒每1小时产生3个NEZHA，每天产生72个，用户可认购至多3个数量的NEZHA NFT",
  'womenjiangchangqi': "我们将长期按照该路线图进行NFT规划",
  'womenweinezha': "我们为NEZHA计划了一条发展路线。一旦达到目标销售百分比，我们将开始努力实现既定目标",
  "xianshanghualang": "线上画廊",
  "nezhachaowan": "NEZHA潮玩衍生品开发",
  "xianxiaip": "线下IP联合巡展",
  "nezhadujia": "NEZHA独家商品/限量版收藏品",
  "goumaixuni": "购买虚拟土地展示元宇宙空间",
  "xianxiashuzi": "线下数字艺术品拍卖",
  "OpenSea": "OpenSea回购",
  "qidianjuhui": "庆典&聚会",
  "zhuzaozongliang": "铸造总量",
  "zhuzajiage": "铸造价格",
  "yixuan":"已选",
  "zongji":"总计",
  "zhuzaowancheng":"铸造完成",
  "ccobanquan":"本NEZHA NFT采用CC0版权申明协议进行铸造",
  "kongtounezha":"空投NEZHA",
  "kongtouzongliang":"空投总量",
  "yikongtou":"已空投",
  "wodekongtou":"我的空投",
  "buzaibaimingdan":"不在白名单内",
  "zhuzaochenggong":"铸造成功",

  "yaoqinglianjie": '邀请链接',
  "fuzhi": "复制到粘贴板",
  "fuzhichenggong": "复制成功",
  "dakaiqianbao": "打开您的去中心化钱包",
  "lianjiefuzhi": "将网站链接复制后，在钱包浏览器中打开进行铸造"

}