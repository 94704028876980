// 韩文 key由 文字中的四个字的拼音组成
export const lang = {
  'shouye':'홈피',
  'rengou':'구입',
  'zhiya':'저당',
  'lianjieqianbao':'지갑연결',
  'quanqiulingxian':'글로벌 선도적인 암호화 원주민 커뮤니티 자치 단체 DAO',
  'zhuanzhuyu':'커뮤니티와 암호화 기술에 의한 DeFi, Web3, 메타버스 구축으로 커뮤니티를 위해 업계 신장 보당금을 포착합니다',
  'canyurengou':'구입참여',
  'daibifenpei':'토큰배분',
  'zongfaxingliang':'총발행량',
  'chubeijin':'적립금',
  'shequeDAO':'커뮤니티DAO',
  'liudongxingLP':'유동성LP',
  'tuandui':'팀',
  'rengoujiangli':'구입리워드',
  'luxiantu':'로드맵',
  'jiamijijin':'umiCash암호화펀드선도자',
  'umiCashchengli':'2018.5 umiCash설립',
  'umiCashv1shangxian':'2019.5 umiCash APP v1.0출시',
  'umiCashzhishujijin':'2020.5 umiCash지수펀드출시',
  'umiCashxiaomubiao':'2021.7 umiCash소목표출시',
  'hunhedapan':'2021.9하이브리드마켓펀드10 순자산10.0도달',
  'umiCashzuoshishangban':'2021.12 umiCash마켓메이커버전 발표',
  'umiDeFichanpinshangxian':'Q1 umiDeFi제품출시',
  'umiDAOzhililuntan':'Q2 umiDAO거버넌스포럼 출시',
  'UMIDAOzhiliguifan':'UMIDAO거버넌스표준 발표',
  'UMIDAOweiyuanhui':'UMIDAO위원회선거',
  'umiCustodyjiamizhiguan':'umiCustody암호자산관리제품',
  'SatQuantDEXlianghua':'SatQuant DEX수량화제품',
  'UMIDAOshequqianbao':'UMIDAO커뮤니티지갑멀티분배',
  'umiCashProjiamilianghua':'umiCashPro암호수량화',
  'FundDappjiamijijin':'Fund Dapp암호화펀드',
  'shequn':'커뮤니티',
  'rengouyibiao':'대시보드구입',
  'woderengou':'나의구입',
  'rengouzongliang':'총구입량(2기)',
  'rengouzhekou':'구입할인',
  'shuocangshouyi':'록업수익',
  'zongshouyi':'총수익',
  'UMIjiage':'UMI가격',
  'rengoujindu':'구입진행률',
  'yiwancheng':'완료',
  'shengyu':'잔여',
  'danrengouUMI':'단일구입UMI',
  'shuoding30':'6개월 록업하고 40%릴리즈, 그후 매뭘 10%씩 리니어 릴리즈',
  'rengouzichan':'자산구입',
  'zijinyongtu1':'펀드용도: 구입금액80%는 커뮤니티 멀티지갑으로 관리하는 커뮤니티적립금에, 20%는 프로젝트운영에 사용.',
  'qigoujine':'최소구입',
  'zuLPrengou' :'LP구입 그룹화',
  'shuoding7':'6개월 록업하고 40%릴리즈, 그후 매뭘 10%씩 리니어 릴리즈',
  'zijinyongtu2':'펀드용도: 구입UMI80%는 커뮤니티적립금에, 20%는 프로젝트운영에 사용. 나머지 펀드는 UMI-USDC LP그룹화에 사용하여 uniswap풀에 주입.',
  'lingquUMI':'UMI수령',
  'qigoumenkan':'최소구입',
  'yirengou':'구입완료',
  'yilingqu':'수령완료',
  'kelingqu':'수령가능',
  'daifafang':'보류금액',
  'nianhuaAPR':'연이율APR',
  'rengoujine':'금액',
  'yue':'잔액',
  'duiyingUMI-USDC':'Est. Pool Allocation',
  'duiyingUMI':'대응UMI',
  'danbirengou':'단일UMI구입',
  'UMIzhekou':'UMI할인',
  'youhui':'할인',
  'daoqilingqu':'만기수령UMI수량',
  'pizhunrengou':'구입승인',
  'rengouchengong':'구입성공',
  'lingqurengouNFT':'구입NFT수령',
  'gongxi':'축하',
  'lingquchengong':'수령성공',
  'dianjichakan':'클릭조회',
  'wodeNFT':'나의NFT',
  'yiqiNFT':'1기NFT',
  'dangqiandizhi':'화이트리스트에 없는 주소',
  'ninzaiyiqi':'1기NFT화이트리스트에 있음',
  'lingquyiqi':'1기NFT수령',
  'nftmingzi1':'UMIDAO Membership（Phase I）',
  'nftmingzi2':'UMIDAO Membership（Phase II）',
  'bianhao':'번호',
  'chuangjianshijian':'신설시간',
  'heyuedizhi':'콘트랙트주소',
  'jiaoyihaxi':'해시거래',
  'querengoumai':'구입확인',
  'yuebuzu':'잔액부족',
  'querenlingqu':'수령확인',
  'zhiyamenkan':'최소저당',
  'zongshuocang':'TVL',
  'zichanmingchen':'자산명',
  'zhiyazhouqi':'저당기간',
  'shuhui':'되찾기',
  'souquanUMIDAO':'UMIDAO에 저당 인가',
  'shouquan':'인가',
  'zhiyayue':'귀하의 저당잔액',
  'daoqijiangli':'만기리워드',
  'jiaogeshijian':'인계기간',
  'shuhuizhiya':'되찾아 저당',
  'daifafangjiang':'발급준비 리워드',
  'kelingqujiang':'수령가능 리워드',
  'daoqihou':'만기후',
  'daoqiriqi':'만기일',
  '2022_6_1':'202WalletConnect에 연결2년 6월 1일',
  'dengdaiqueren':'확인대기',
  'querenzheci':'지갑에서의 거래임을 확인',
  'wodeqianbao':'나의지갑',
  'duankailianjie':'연결차단',
  'lianjieshiba':'연결실패',
  'lianjiebeijujie':'연결실패, 연결이 거부됨',
  'wanluocuowu':'네트워크오류',
  'qingqiehuanwang':'네트워크오류, 메인넷에 연결',
  'qiehuanwangluo':'메인넷으로 전환',
  'pizhunshibai':'승인실패',
  'pizhunjujie':'승인청구가 거부됨',
  'jiaoyishibai':'거래실패',
  'jiaoyijujie':'거래청구가 거부됨',
  'lianjieMetamask':'MetaMask지갑에 연결',
  'lianjieWalletConnect':'WalletConnect에 연결',
  'zhiyachengong':'저당 성공',
  
  'yizhiya':'저당완료', // 增加
  'month1': '1 개월',
  'month3': '3 개월',
  'month6': '6 개월',
  'month12': '12 개월',
  'gasbutie': 'Gas보너스',
  // 
  'jiaoyichenggong': '거래 성공',
  'guanbi': '닫기',
  'yitijiao': '제출된 거래',
  'zhixingchenggong': '거래 실행 성공',
  'dengdaiqueren': '확인대기',
  'dailingqu': '수령 대기',
  'lingquzhong': '수령 중',

  'dengdaishouquan': '승인 대기',
  'shouquanzhong': '승인 중',
  'shouquanchenggong': '승인 성공',
  'jiaoyizhong': '거래 중',
  'dengdaishuhui': '회수 대기',
  'shuhuizhong': '회수 중',
  'shuhuichenggong': '회수 성공',
  'zhiyazhong': '저당 중',
  'goumaichenggong': '구매 성공',
  'yisifang': '방출됨',
  'shouquanrengou': '구매 승인',
  'zhiyashijian': '저당 기간',
'keyong1': '사용가능UMI  ',
    'shurutokenid': 'LP TokenID 입력',
  'tokeindcuowu': 'TokenID 오류',
  'qianbao': '지갑',

  'nftbannertxt': '미래 메타버스에서 자본이 기술을 독점하여 민간인을 통제한다. 밤하늘에 고층 빌딩이 숲을 이루고, 거대한 기계 용선이 그 빌딩 사이의 하늘을 가로 지난다. 지상에서는 빈민가 한구석의 가난한 사람들이 떨고 있다. 여기에는 자본과 비천만 있고, 범죄와 미망으로 가득 차 있다. 매직테크를 장악한 NEZHA는 자본의 신비를 벗겨내고, 모든 거짓을 모조리 태워버려 숨겨진 진실을 찾고자 한다…',
  'nezhamanghe': 'NEZHA블라인드 박스',
  'tongguozhuzao': '민트를 통해 NEZHA NFT를 얻을 수 있고, UMI 구매와 LP 스테이킹에 참여하면 에어드랍 NFT수령 가능',
  'zhuzao': '민트',
  'meixiaochannft': '시간당 3개 NFT 산출, 일당 72개 NFT 산출',
  'kaishizhuzao': '민트 시작',
  'kongtou': '에어드랍',
  'rengouzhiyalingqunft': 'UMI 구매와 LP 스테이킹을 통한 NFT 에어드랍 수령',
  'lingqukongtou': '에어드랍 수령',
  'fenpeizhuzaoguize':'배분과 민트 규칙',
  'nezhaoriental': 'NEZHA Oriental의 NEZHA 총 산출량 10,000개, 그중 팀 보류용 1,000개, 커뮤니티 에어드랍용 1,000개, 민트 발매용 8,000개',
  'tuanduibaoliu': '팀 보류',
  'kaifatuandui': '개발팀 500개, IP팀 500개',
  'shequkongtou': "커뮤니티 에어드랍",
  'rengouzhiyabaimingdan': "1,000개 구매/스테이킹 화이트 리스트",
  'zhuzaofashou': "민트 발매",
  '8000mint': "8,000개 MINT 1-3개 사이, 최고 3개 NFT",
  'zhuzaoguize': "민트 규칙",
  '8000manghe': "홈페이지의 민트 채널을 통해 8,000개 블라인드 박스 MINT 발매. 블라인드 박스에서 시간당 3개 NEZHA 발생, 일당 72개 발생, 사용자는 최고 3개 수량의 NEZHA NFT 구매 가능",
  'womenjiangchangqi': "이 로드맵에 따라 NFT 계획 장기간 실시 예정",
  'womenweinezha': "NEZHA를 위한 발전 루트 계획, 판매율 목표를 달성하면 정해진 목표 달성에 주력",
  "xianshanghualang": "온라인 갤러리",
  "nezhachaowan": "NEZHA 트렌드 플레이 파생품 개발",
  "xianxiaip": "오프라인 IP 공동 순회전시",
  "nezhadujia": "NEZHA 전용상품/한정판컬렉션",
  "goumaixuni": "메타버스 전시를 위한 가상 토지 구매",
  "xianxiashuzi": "오프라인 디지털 예술품 경매",
  "OpenSea": "OpenSea 바이백",
  "qidianjuhui": "축전 & 파티",
  "zhuzaozongliang": "총 민트량",
  "zhuzajiage": "민트 가격",
  "yixuan":"선택완료",
  "zongji":"총계",
  "zhuzaowancheng":"민트 완료",
  "ccobanquan":"본 NEZHA NFT는 CCO 저작권 선언 계약서를 적용하여 민트 실시",
  "kongtounezha":"에어드랍NEZHA",
  "kongtouzongliang":"총 에어드랍 수량",
  "yikongtou":"에어드랍 완료",
  "wodekongtou":"나의 에어드랍",
  "buzaibaimingdan":"화이트 리스트에 없음",
  "zhuzaochenggong":"민트 성공",

  "yaoqinglianjie": '요청 링크',
  "fuzhi": "클립보드에 복사",
  "fuzhichenggong": "복사 성공",
  "dakaiqianbao": "탈중심화된 지갑을 열어라",
  "lianjiefuzhi": "웹 사이트 링크를 복사한 후 지갑 브라우저에서 열어 주조하세요"
}