<template>
  <div class="castnft_page">

    <!-- <img src="../../assets/image/castnft_pagebg.png" alt="" class="castnft_pagebg"> -->
    <div class="castnft_title">
      <!-- <img src="../../assets/image/castnft_title.png" alt="" @click="$router.back()"> -->
      <p>{{$t("lang.zhuzao")}}NEZHA</p>
    </div>
    <div class="cast_info">
      <div>
        <p class="cast_info_title">{{$t("lang.zhuzaozongliang")}}</p>
        <p class="cast_info_value">8000 NFT</p>
      </div>
      <div>
        <p class="cast_info_title">{{$t("lang.zhuzajiage")}}</p>
        <p class="cast_info_value">{{($store.state.NFTPrice *1).toFixed(1)}} ETH</p>
      </div>
      <div>
        <p class="cast_info_title">{{$t("lang.zhuzaoguize")}}</p>
        <p class="cast_info_value">3 NFT/1 H</p>
      </div>
    </div>
    <div class="not_start_view" v-if="$store.state.notMint">
      <img src="./../../assets/image/waitingbg.png" alt="" style="width:100%;">
      <div class="comming_mint_btn">COMING TO MINT</div>
    </div>
    <div class="cast_content" v-else>
      <div class="cast_content_bg">
      <div class="cast_content_top">
        <div class="cast_content_top_left">{{countTime.hour}}:{{countTime.minutes}}:{{countTime.seconds}}</div>
        <p class="cast_content_top_right">{{canCastNum}}/{{castedNum}}</p>
      </div>
      <div class="cast_content_mid">
        <img src="./../../assets/image/castnftimg.png" alt="" class="manghe_icon">
        <div class="num_view">
          <img src="./../../assets/image/minus.png" alt="" @click="minusBuyNft">
          <input type="number" v-model="nftNum" readonly>
          <img src="./../../assets/image/add.png" alt="" @click="addBuyNft">
        </div>
      </div>
      </div>
      <div class="cast_content_bottom">
        <img src="./../../assets/image/nftselect.png" alt="" class="left_select">
        <p class="select_num">{{$t("lang.yixuan")}}{{nftNum}}/3，{{$t("lang.zongji")}}<span>{{nftNum && $store.state.NFTPrice && (nftNum * $store.state.NFTPrice).toFixed(3)}}ETH</span></p>
        <span style="flex: 1"></span>
        <p class="balbance_eth">{{$t("lang.yue")}}：{{($store.state.balance.ETHBalance *1).toFixed(4)}}ETH</p>
        <!-- <div class="cast_bnt" @click="buyNft">铸造</div> -->

        <div class="cast_bnt" v-if="!$store.state.userAddress" @click="openLinkWallet">{{$t("lang.lianjieqianbao")}}</div>
        <div class="cast_bnt" style="background:#979797;cursor:not-allowed" v-else-if="($store.state.NFTPrice * nftNum) > ($store.state.balance.ETHBalance)">{{$t("lang.yuebuzu")}}</div>
        <div class="cast_bnt" :style="{background: buyingStatus==2?'#979797':'#4A4A4A',color: buyingStatus==3?'#8B8B8B':'#fff'}" v-else>
          <UmiButton
            :loading="buyingStatus==2"
            :text="buyingStatusTxt"
            @clickFn="buyNft"
            :disable="disbaleBuy"
            btnColor="auto"
            circleColor="#fff"
          />
        </div>
      </div>
    </div>

    <div class="cast_nft_tips" v-if="$store.state.castCountTime">
      <!-- <span>0</span> -->
      <img src="./../../assets/image/nft_tip_img.png" alt="" style="width:15px;margin-right:10px">
      <p>{{$t("lang.ccobanquan")}}</p>
    </div>

    <!-- 弹窗 -->
    <PcModal v-if="showModal" @closeModal="closePCmodal" :title="modalTitle">
      <!-- loading -->
      <div slot="content" v-if="modalType == 0" class="buy_succes_modal"><modalLoading/></div>
      <!-- 交易已提交 -->
      <div slot="content" v-if="modalType == 1" class="buy_succes_modal">
        <div class="buy_succes_modal_content">
          <img src="../../assets/image/arrow_top.png" alt="" style="width:3.8rem;margin:0 auto;margin-bottom:0.75rem">
          <p style="text-align:center;margin-bottom:20px">{{$t("lang.yitijiao")}}</p>
        </div>
        <div class="buy_succes_modal_btn" style="background:#4A4A4A" @click="closePCmodal">{{$t("lang.guanbi")}}</div>
      </div>
      <!-- 交易成功 -->
      <div slot="content" v-if="modalType == 2" class="buy_succes_modal">
        <div class="buy_succes_modal_content">
          <p>{{$t("lang.rengouchengong")}}</p>
        </div>
        <div class="buy_succes_modal_btn" @click="closePCmodal">{{$t("lang.guanbi")}}</div>
      </div>
      <!-- 失败 -->
      <div slot="content" v-if="modalType ==3" class="buy_succes_modal">
        <div class="buy_succes_modal_content">
          <img src="../../assets/image/error_gray.png" alt="" style="width:3.8rem;margin:0 auto;margin-bottom:3rem">
        </div>
        <div class="buy_succes_modal_btn" style="background:#4A4A4A" @click="closePCmodal">{{$t("lang.guanbi")}}</div>
      </div>
    </PcModal>

    <!-- 铸造成功 -->
    <div class="cast_success_modal" v-if="castSuccess">
      <p class="cast_success_modal_title1">{{$t("lang.gongxi")}}!</p>
      <p class="cast_success_modal_title2">{{$t("lang.zhuzaochenggong")}}</p>
      <div class="cast_success_nft">
        <div
          class="cast_success_nft_item_info"
          v-for="(item,index) in castNftList" 
          :key="index+'castNftList'"
        >
          <p>NEZHA</p>
          <p>#{{item}}</p>
        </div>
      </div>
      <div class="close_cast_success_modal" @click="$router.push('/mynft')">{{$t("lang.wodeNFT")}}</div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import PcModal from './../../components/PcModal.vue'
import modalLoading from '../../components/loading.vue';
import UmiButton from '../../components/UmiButton.vue';
import LoadingIcon from '../../components/loadingIcon.vue';
// 交易hash
let successOnce = false;
export default {
  name: "detail",
  components: {
    PcModal,
    modalLoading,
    UmiButton,
    LoadingIcon
  },
  data() {
    return {
      nftNum: '1',

      showModal: false,
      modalType: 0, // 0 loading, 1 已提交  2 成功 3 失败
      modalTitle: '',

      buyingStatus: 1, // 1可铸造 2 铸造中 3 铸造完成
      buyingStatusTxt:  this.$t("lang.zhuzao"),

      disbaleBuy: false,
      ethBalance: 0,

      castNftList: [1,2,3],
      castSuccess: false,
      canBuyNum: 3,

      intervalObj: null,
      countTime: {
        hour: '--',
        minutes: '--',
        seconds: '--'
      },
      castedNum: 0, // 已铸造数量
      canCastNum: 0, // 可铸造
    };
  },
  computed: {
    
  },
  watch: {
    nftNum() {
      if((this.nftNum * 1) > this.canBuyNum) {
        this.nftNum = this.canBuyNum
      }
    },
    modalType() {
      if(this.modalType == 3) {
        this.modalTitle = this.$t("lang.jiaoyishibai");
      } else if(this.modalType == 1) {
        this.modalTitle = this.$t("lang.jiaoyichenggong");
      } else {
        this.modalTitle =  '';
      }
    },
    // 弹窗显示 关闭
    showModal() {
      if(!this.showModal) {
        this.modalTitle =  '';
        this.modalType = 0;
      }
    },
    "$store.state.castCountTime" () {
      const num = this.$store.state.castCountTime - new Date().getTime()
      if(num) {
        this.countDown(num);
      }
    }
    
  },
  methods: {
    openLinkWallet() {
      this.$emit("openLinkWallet")
    },
    closePCmodal() {
      this.showModal = false;
      this.modalType = 0;
    },
    minusBuyNft() {
      if(this.nftNum == 1) {
        return 
      }
      this.nftNum = this.nftNum - 1;
    },
    addBuyNft() {
      if(this.nftNum == this.canBuyNum) {
        return 
      }
      this.nftNum = (this.nftNum * 1) + 1;
    },
    async buyNft() {
      if(this.buyingStatus != 1) {
        return
      }
      successOnce = false;
      this.showModal = true;
      this.buyingStatus = 2;
      const { NFT_contract,userAddress, NFTList } = this.$store.state;
      const gasPrice  = await this.web3js.eth.getGasPrice();
      
      const num = this.web3js.utils.toWei((this.nftNum * this.$store.state.NFTPrice)+''); //获取当前gas 价格
      
      NFT_contract.methods.mint(this.nftNum*1).send({from: userAddress,gasPrice,value: num })
      .on('transactionHash', (hash)=>{ // 交易hash
        this.modalType = 1;
        !this.showModal && (this.showModal = true);
      })
      .on('confirmation', (confirmationNumber, receipt)=>{ // 
      })
      .on('receipt', async (receipt)=>{ // 交易已广播
        if(!successOnce) {
          successOnce = true;
          let list = [];
          if((this.nftNum*1) > 1) {
            const itemlist = receipt.events.Transfer;
            for(let i = 0; i<itemlist.length ; i++) {
              
              list.push(itemlist[i].returnValues.tokenId)
            }
            
          } else {
            const returnValue = receipt.events.Transfer.returnValues;
            
            list = [
              returnValue.tokenId
            ]
          }
          this.castNftList = list;
          this.castSuccess = true;
          this.showModal = false;
          this.buyingStatus = 3;
          this.buyingStatusTxt = this.$t("lang.zhuzaowancheng");
          this.getInfo();
          this.getBalance();
          this.$store.commit('setNFTList',[...NFTList,...list])
        }
      })
      .on('error',(error, receipt)=>{ // 交易失败
        if(!successOnce) {
          this.modalType = 3;
          this.buyingStatus = 1;
          !this.showModal && (this.showModal = true);
          this.buyingStatusTxt =  this.$t("lang.zhuzao");
        }
      })
    },
    async getInfo() {
      const { NFT_contract } = this.$store.state;
      const res1 = await NFT_contract.methods.currentLeftNft().call();
      const res2 = await NFT_contract.methods.alreadyMintNftCount().call();
      
      this.castedNum = 8000 - res2;
      this.canCastNum = res1;
      if(res2 == 8000) {
        this.disbaleBuy = true;
        this.buyingStatus = 3
      }
    },
    async getBalance() {
      const { userAddress,balance } = this.$store.state;
      const ETHres = await this.web3js.eth.getBalance(userAddress);
      const ETHBalance = this.$utils.mathpow(ETHres,this.$api.ETH_Demical);
      if(balance.ETHBalance == ETHBalance) {
        setTimeout(()=>{this.getBalance()},1000)
        return 
      }
      this.$store.commit('setBalance',{...balance,ETHBalance})
    },
    countDown(nums) {
      let clock = setInterval(() => {
          nums-=1000;
          if(nums > 0){
            let h = Math.floor(nums / 1000 / 3600)  //计算小时数
            let m = Math.floor(nums / (1000*60)%60)  //计算分钟数
            let s = Math.floor(nums / 1000%60)  //计算秒数
            this.countTime = {
              hour: h < 10 ? '0'+h : h,
              minutes: m < 10 ? '0'+m : m,
              seconds: s < 10 ? '0'+s : s
            }
          }else{
            this.intervalObj && clearInterval(this.intervalObj);
          }
      },1000);
      this.intervalObj = clock;
    }
  },
  mounted() {
    if(!this.$store.state.userAddress) {
      this.$emit('openLinkWallet');
      return 
    }
    this.getInfo();
    this.countDown(this.$store.state.castCountTime - new Date().getTime())
  }
};
</script>

<style scoped >
  .castnft_page {
    width: 100%;
    background: url('../../assets/image/castnft_pagebg.png') no-repeat;
    background-size: calc(100% - 6.2rem - 2.3rem) auto;
    background-position-x: 2.3rem;
    background-position-y: 3.7rem;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 4.9rem 6.25rem;
    border-radius: 0.5rem;
    position: relative;
  }
  .castnft_pagebg {
    position: absolute;
    left: 2.3rem;
    top: 3.7rem;
    width: calc( 100% - 2.3rem - 6.25rem);
    height: auto;
  }
  .castnft_title {
    display: flex;
    align-items: center;
    margin-bottom: 1.3rem;
  }
  .castnft_title img {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    cursor: pointer;
  }
  .castnft_title p {
    font-size: 1.5rem;
    font-weight: bold;
    color: #26493E;
  }

  .cast_info {
    width: 100%;
    height: 7.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4.8rem;
    background: rgba(105, 200, 157, 0.3);
    border-radius: 0.5rem;
    text-align: center;
    margin-bottom: 4rem;
  }
  .cast_info_title {
    font-size: 1rem;
    font-weight: 600;
    color: #26493E;
    margin-bottom: 1.25rem;
  }
  .cast_info_value {
    font-size: 1.5rem;
    font-weight: 600;
    color: #26493E;
  }

  .cast_content {
    width: 100%;
    height: 46rem;
    background: url('./../../assets/image/castnftcontentbg.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .cast_content_bg {
    background: url('./../../assets/image/castcontentbg.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    flex: 1;
  }
  .cast_content_top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.6rem;
    padding-left: 1.7rem;
    padding-right: 5.65rem;
  }
  .cast_content_top_left {
    padding: 1.3rem 2.65rem;
    font-size: 2.5rem;
    font-weight: bold;
    color: #26493E;
    background: linear-gradient(rgba(255,255,255,0.4),rgba(255,255,255,0));
    border-radius: 1rem;
  }
  .cast_content_top_right {
    font-size: 1.5rem;
    font-weight: bold;
    color: #26493E;
  }
  .cast_content_mid {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .manghe_icon {
    width: auto;
    height: 20rem;
    
  }
  .num_view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .num_view img {
    width: 2.75rem;
    height: auto;
    cursor: pointer;
  }
  .num_view input {
    width: 10rem;
    height: 2.65rem;
    background: rgba(238, 248, 244, 0.4);
    border-radius: 1.33rem;
    margin: 0 2rem;
    line-height: 2.65rem;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 600;
    color: #4A4A4A;
    border: none;
    outline: none;
  }
  .cast_content_bottom {
    width: 100%;
    height: 7.35rem;
    background: rgba(255,255,255,0.4);
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    padding-left: 3.75rem;
    padding-right: 5rem;
  }
  .left_select {
    width: 2rem;
    height: 2rem;
    margin-right: 2rem;
  }
  .select_num {
    font-size: 0.75rem;
    color: #9A9A9A;
    font-weight: 600;
  }
  .select_num span {
    font-size: 1.5rem;
    font-weight: bold;
    color: #393939;
    padding-left: 0.5rem;
  }
  .balbance_eth {
    font-size: 0.75rem;
    font-weight: 600;
    color: #7D7D7D;
    margin-right: 2rem;
  }
  .cast_bnt {
    width: 15rem;
    height: 3rem;
    cursor: pointer;
    background: #4A4A4A;
    border-radius: 3rem;
    line-height: 3rem;
    text-align: center;
    font-size: 1.2rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    overflow: hidden;
  }
  .cast_nft_tips {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 0.7rem;
  }

  .cast_nft_tips p {
    font-size: 0.75rem;
    font-weight: 600;
    color: #7D7D7D;
  }

  .cast_success_modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 91;
  }
  .cast_success_modal_title1 {
    font-size: 2rem;
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 1.3rem;
  }
  .cast_success_modal_title2 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 2.4rem;
  }
  .cast_success_nft {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40rem;
  }
  .cast_success_nft_item_info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4rem;
  }
  .cast_success_nft_item_info p {
    font-size: 2.08rem;
    font-weight: 600;
    color: #fff;
  }
  .close_cast_success_modal {
    margin-top: 3rem;
    width: 22.5rem;
    height: 4rem;
    background: #FFFFFF;
    border-radius: 2rem;
    text-align: center;
    line-height: 4rem;
    font-size: 1rem;
    font-weight: bold;
    color: #4A4A4A;
    cursor: pointer;
  }

  .comming_mint_btn {
    margin: 0 auto;
    background: #979797;
    width: 21.75rem;
    height: 4.35rem;
    line-height: 4.35rem;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 600;
    color: #FFFFFF;
    border-radius: 3.3rem;
    margin-top: 2rem;
  }

</style>