// 西班牙  key由 文字中的四个字的拼音组成
export const lang = {
  'shouye':'Inicio',
  'rengou':'Comprar',
  'zhiya':'Stake',
  'lianjieqianbao':'Conectar Cartera',
  'quanqiulingxian':'La organización autónoma líder en el mundo para las comunidades indígenas criptográficas DAO',
  'zhuanzhuyu':'DeFi, Web3 y Metacosmity, construidos por comunidades y tecnologías criptográficas, capturan dividendos de crecimiento de la industria para la comunidad.',
  'canyurengou':'Comprar Ahora',
  'daibifenpei':'Distribución de Token',
  'zongfaxingliang':'Provisión Total',
  'chubeijin':'Fondo de Reserva',
  'shequeDAO':'Comunidad DAO',
  'liudongxingLP':'Liquidez LP',
  'tuandui':'Grupo',
  'rengoujiangli':'Recompensas de Compra',
  'luxiantu':'Mapa de ruta',
  'jiamijijin':'Pionero de Criptofondo umiCash',
  'umiCashchengli':'May, 2018 Establecimiento de umiCash',
  'umiCashv1shangxian':'May,2019 Lanzamiento de la APP umiCash',
  'umiCashzhishujijin':'May, 2020 Lanzamiento de Fondo Índice de umiCash',
  'umiCashxiaomubiao':'Jul, 2021 Lanzamiento de de Gol Pequeño de umiCash',
  'hunhedapan':'Sep, 2021 El Valor Neto de Fondo de Mercado Híbrido 10 superó 10,0',
  'umiCashzuoshishangban':'Dic, 2021 Lanzamiento de la Versión de Creador del Mercador de umiCash',
  'umiDeFichanpinshangxian':'T1 Lanzamiento de Productos de umiDeFi',
  'umiDAOzhililuntan':'T2 Lanzamiento del Foro de Gobernanza de umiDAO',
  'UMIDAOzhiliguifan':'Publicación del Estándar de Gobernanza de UMIDAO',
  'UMIDAOweiyuanhui':'Elección del Comité UMIDAO',
  'umiCustodyjiamizhiguan':'Gestión de Activos de umiCustody',
  'SatQuantDEXlianghua':'Producto Cuantificado DEX de SatQuant',
  'UMIDAOshequqianbao':'Distribución Multisig de la Cartera de Comunidad UMIDAO',
  'umiCashProjiamilianghua':'Cuantificación Criptográfica de umiCashPro',
  'FundDappjiamijijin':'Fondo Criptográfico DAPP',
  'shequn':'Comunidad',
  'rengouyibiao':'Dashboard de Compras',
  'woderengou':'Mi compra',
  'rengouzongliang':'Compra Total (Fase II)',
  'rengouzhekou':'Descuento',
  'shuocangshouyi':'Ganancia de Lockup',
  'zongshouyi':'ROI total',
  'UMIjiage':'Precio de UMI',
  'rengoujindu':'Tasa de Progreso',
  'yiwancheng':'Tasa de progreso ',
  'shengyu':'Restante',
  'danrengouUMI':'Compra Única de UMI',
  'shuoding30':'Bloqueo de 6 meses seguido de una liberación del 40 %, y una liberación  posterior lineal mensual de 10%',
  'rengouzichan':'Activos de Financiación',
  'zijinyongtu1':"Uso de Fondos: el 80% del fondo de compras se utiliza para el fondo de reserva de la comunidad, que es administrado por la cartera multisig de la comunidad; y el 20% de ello se utiliza para la operación del proyecto.",
  'qigoujine':'Compra Mínima',
  'zuLPrengou' :'Proporcionar Compra de LP',
  'shuoding7':'Bloqueo de 6 meses seguido de una liberación del 40 %, y una liberación  posterior lineal mensual de 10%',
  'zijinyongtu2':'Uso de Fondos: el 80% del fondo de compras de UMI se utiliza para el fondo de reserva de la comunidad, y el 20% de ello para la operación del proyecto. El fondo restante se utiliza para agrupar UMI-USDC LP, y se vierte en el cash pooling de Uniswap.',
  'lingquUMI':'Reclamar UMI',
  'qigoumenkan':'Compra Mínima',
  'yirengou':'Comprado',
  'yilingqu':'Importe reclamado',
  'kelingqu':'Importe Reclamable',
  'daifafang':'Importe Pendiente',
  'nianhuaAPR':'APR',
  'rengoujine':'Importe de Compra',
  'yue':'Saldos',
  'duiyingUMI-USDC':'Est. Pool Allocation',
  'duiyingUMI': 'Comprar',
  'danbirengou':'Compra Única de UMI',
  'UMIzhekou':'Descuento UMI',
  'youhui':'Descuentos',
  'daoqilingqu':'Obtendrá',
  'pizhunrengou':'Aprobar',
  'rengouchengong':'Compra Completada',
  'lingqurengouNFT':'Reclamar Comprar NFT',
  'gongxi':'Enhorabuena',
  'lingquchengong':'Reclamo con éxito',
  'dianjichakan':'Comprobar',
  'wodeNFT':'Mi NFT',
  'yiqiNFT':'NFT Fase I',
  'dangqiandizhi':'La dirección actual no está en la lista blanca',
  'ninzaiyiqi':'Usted está en la lista blanca de NFT Fase I',
  'lingquyiqi':'Reclamar NFT Fase I',
  'nftmingzi1':'Membresía de UMIDAO (Fase I)',
  'nftmingzi2':'Membresía de UMIDAO (Fase II)',
  'bianhao':'ID de Token',
  'chuangjianshijian':'Fecha de Creación',
  'heyuedizhi':'Dirección de Contrato',
  'jiaoyihaxi':'Txn Hash',
  'querengoumai':'Confirmar el Reclamo',
  'yuebuzu':'Saldo insuficiente',
  'querenlingqu':'Confirmar el Reclamo',
  'zhiyamenkan':'Stake mínimo',
  'zongshuocang':'TVL',
  'zichanmingchen':'Activo',
  'zhiyazhouqi':'Plazo de participación',
  'shuhui':'Redimir',
  'souquanUMIDAO':'Autorizar a UMIDAO para apostar ',
  'shouquan':'Autorizar',
  'zhiyayue':'Su saldo de de Staking',
  'daoqijiangli':'Recompensa Total ',
  'jiaogeshijian':'Término de Adquisición',
  'shuhuizhiya':'Redimir y Stake',
  'daifafangjiang':'Recompensas Pendientes',
  'kelingqujiang':'Recompensas Reclamables',
  'daoqihou':'Tiempo hasta la Adquisición por completo ',
  'daoqiriqi':'Fecha de Adquisición',
  '2022_6_1':'1 de junio, 2022',
  'dengdaiqueren':'Esperando la Confirmación',
  'querenzheci':'Confirme esta transacción en su cartera',
  'wodeqianbao':'Mi Cartera',
  'duankailianjie':'Desconectar',
  'lianjieshiba':'Conexión Fallida',
  'lianjiebeijujie':'se falló la conexión, la conexión es rechazada',
  'wanluocuowu':'Red Fallida',
  'qingqiehuanwang':'Se falló la red, por favor, cambie a la Red Principal',
  'qiehuanwangluo':'Cambiar a la Red Principal',
  'pizhunshibai':'Aprobación Fallida',
  'pizhunjujie':'la solicitud de aprobación es denegada',
  'jiaoyishibai':'Transacción Fallida',
  'jiaoyijujie':'la solicitud de transacción es denegada',
  'lianjieMetamask':'Conéctese a su cartera MetaMask',
  'lianjieWalletConnect':'Conéctese con WalletConnect',
  'zhiyachengong':'Apostar con éxito',
  
  'yizhiya':'Financiado', // 增加
  'month1': '1 mes',
  'month3': '3 meses',
  'month6': '6 meses',
  'month12': '12 meses',
  'gasbutie': 'Bono Gas',
  // 
  'jiaoyichenggong': 'Transacción exitosa',
  'guanbi': 'Cerrar',
  'yitijiao': 'Presentado',
  'zhixingchenggong': 'Transacción ejecutada',
  'dengdaiqueren': 'Esperando la Confirmación',
  'dailingqu': 'Esperando a recogir',
  'lingquzhong': 'Recogida en curso',

  'dengdaishouquan': 'Esperando la autorización',
  'shouquanzhong': 'Autorizando',
  'shouquanchenggong': 'Autorización exitosa',
  'jiaoyizhong': 'Transacción en curso',
  'dengdaishuhui': 'Esperando la redención',
  'shuhuizhong': 'Redención en curso',
  'shuhuichenggong': 'Redención exitosa',
  'zhiyazhong': 'Prometido',
  'goumaichenggong': 'Compra exitosa',
  'yisifang': 'Liberado',
  'shouquanrengou': 'Autorizar la compra',
  'zhiyashijian': 'Tiempo de compromiso',
'keyong1': 'UMI está disponible  ',
    'shurutokenid': 'Introduzca el LP TokenID',
  'tokeindcuowu': 'TokenID Incorrecto',
  'qianbao': 'Billetera',

  'nftbannertxt': 'En el futuro metacosmos, el capital monopoliza la tecnología para controlar a los civiles. En el cielo nocturno, un barco dragón de maquinaria gigante atraviesa los rascacielos. En el suelo, la gente pobre en la esquina del barrio pobre está temblando. Aquí solo hay capital y personas de estatus débil o bajo, llenas de crímenes y pérdidas. Nezha, que posee tecnología negra, quiere abrir la niebla del capital, quemar todas las mentiras en cenizas con fuego y tratar de encontrar la verdad que ha sido encubierta.',
  'nezhamanghe': 'Caja ciega NEZHA',
  'tongguozhuzao': 'NEZHA NFT está disponible a través de la acuñación, y los lanzamientos en paracaídas se pueden recibir participando en la suscripción de las promesas de UMI y LP',
  'zhuzao': 'Fundición',
  'meixiaochannft': 'Producción de 3 NFT por hora, 72 NFT por día = Menta 3 NFT por hora, 72 NFT por día',
  'kaishizhuzao': 'Iniciar la fundición',
  'kongtou': 'Lanzamiento en paracaídas',
  'rengouzhiyalingqunft': 'Reclamar los lanzamientos en paracaídas de NFT a través de UMI comprado y LP apostado',
  'lingqukongtou': 'Recoge los lanzamientos en paracaídas',
  'fenpeizhuzaoguize':'Regla de distribución y acuñación',
  'nezhaoriental': 'El suministro total del NEZHA Oriental es de 10,000, entre ellos, 1,000 están reservados para el equipo, 1,000 son lanzados desde el aire para la comunidad y 8,000 se acuñan para la venta.',
  'tuanduibaoliu': 'Equipo reservado',
  'kaifatuandui': '500 para el equipo de desarrolladores, 500 para el equipo de IP',
  'shequkongtou': "Los lanzamientos en paracaídas de comunitario",
  'rengouzhiyabaimingdan': "Listas blancas de suscripciones/promesas de 2000 piezas",
  'zhuzaofashou': "Mint Sale Vender la fundición",
  '8000mint': "8000 piezas Entre MINT1-3, Máx. 3 NFT",
  'zhuzaoguize': "Regla de la fundición",
  '8000manghe': "8,000 cajas ciegas MINT, y se vende a través del canal MINT en la página de inicio. Caja ciega menta 3 NEZHA por hora, 72 NEZHA por día, los usuarios pueden comprar Max. 3 NEZHA NFT.",
  'womenjiangchangqi': "Llevaremos a cabo la planificación de NFT de acuerdo con esta hoja de ruta durante mucho tiempo.",
  'womenweinezha': "Hemos planeado una ruta de desarrollo para NEZHA. Una vez alcanzado el objetivo del porcentaje de ventas, nos esforzaremos por alcanzar los objetivos establecidos",
  "xianshanghualang": "Galería en línea",
  "nezhachaowan": "Desarrollo derivado de NEZHA juego de marea",
  "xianxiaip": "Recorrido conjunto por la exposición IP fuera de línea",
  "nezhadujia": "NEZHA Productos exclusivos / Colección edición limitada",
  "goumaixuni": "Compra terreno virtual para mostrar espacio metacósmico",
  "xianxiashuzi": " Subasta de arte digital fuera de línea",
  "OpenSea": "Recompra de OpenSea",
  "qidianjuhui": "Celebración y fiestas",
  "zhuzaozongliang": "Cantidad total de fundición",
  "zhuzajiage": "Precio de fundición",
  "yixuan":"Seleccionado",
  "zongji":"Total",
  "zhuzaowancheng":"Completo de la fundición",
  "ccobanquan":"NEZHA se acuñó con el acuerdo de declaración de derechos de autor CC0",
  "kongtounezha":"Los lanzamientos en paracaídas de NEZHA",
  "kongtouzongliang":"Total cantidad de los lanzamientos en paracaídas",
  "yikongtou":"He terminado los lanzamientos en paracaídas",
  "wodekongtou":"Mi lanzamiento en paracaída",
  "buzaibaimingdan":"Fuera de la lista blanca",
  "zhuzaochenggong":"Fundición exitosa",

  "yaoqinglianjie": 'Enlace de Invitación',
  "fuzhi": "Copiar",
  "fuzhichenggong": "Copiar  exitosa",
  "dakaiqianbao": "Abra su cartera descentralizada",
  "lianjiefuzhi": "Después de copiar el enlace del sitio web, abra para fundición en el navegador de cartera"

}