<template>
  <div class="pc_modal">
    <div class="pc_modal_content">
      <div class="close_modal" @click="close"><img src="./../assets/image/close.png"/></div>
      <p class="title">{{title}}</p>
      <div class="modal_view">
         <slot name="content"></slot>
      </div>
      <div class="btn" v-if="btnText" @click="clickBtn">{{btnText}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pc_modal",
  props: [ 'title', 'btnText', 'notClose' ],
  data() {
    return {
      
    }
  },
  
  methods: {
    clickBtn() {
      this.$emit('btnClick');
    },
    close() {
      this.$emit('closeModal');
    }
  },
  mounted() {
    
  },
  
};
</script>

<style>


.pc_modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 91;
}
.pc_modal_content {
  width: 25rem;
  background: #FFFFFF;
  border-radius: 20px;
  position: relative;
  padding: 20px 1.3rem;
  box-sizing: border-box;
}
.title {
  height: 1.1rem;
  line-height: 1.1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #4A4A4A;
}
.modal_view {
  width: 100%;
  min-height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}
.btn {
  width: 100%;
  height: 60px;
  background: #6FCAA0;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 60px;
  color: #FFFFFF;
}
.btn:hover{
  background: #8DDDB8;
}
.close_modal {
  position: absolute;
  right: 1.3rem;
  top: 20px;
  width: 1.1rem;
  height: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.close_modal img {
  width: 1.1rem;
  height: 1.1rem;
}
.close_modal:hover {
  opacity: 0.7;
  filter: grayscale(100%);
}
</style>
