<template>
  <div class="index">
    <div class="banner_view">
      <p class="banner_title">umiDAO</p>
      <p class="banner_text1">{{$t("lang.quanqiulingxian")}}</p>
      <p class="banner_text2">
        {{$t("lang.zhuanzhuyu")}}
      </p>
      <div class="banner_join" @click="$router.push('/purchase')">
       {{$t("lang.canyurengou")}}
      </div>
    </div>

    <div class="chart_view">
      <p class="chart_title"> {{$t("lang.daibifenpei")}}</p>
      <div class="chart_box">
        <div class="chart_content" id="myChart"></div>
        <div class="chart_info">
          <div class="chart_total">
            <div class="chart_total_info">
              <img src="./../../assets/image/total_icon.png" alt="total_icon" />
              <p>36,500,000</p>
            </div>
            <p class="chart_total_info_title">{{$t("lang.zongfaxingliang")}}</p>
          </div>
          <div class="chart_item_info" v-for="(item, key) in list" :key="key">
            <div
              class="chart_item_color"
              :style="{ background: item.color }"
            ></div>
            <div>
              <p class="chart_item_value">{{ item.value }}</p>
              <p class="chart_item_title">{{$t("lang."+item.title)}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="road_view">
      <p class="road_title"> {{$t("lang.luxiantu")}}</p>
      <img
        src="./../../assets/image/roadImg.png"
        alt="road_line"
        class="road_line_bottom_img"
      />
       
    </div>
  </div>
</template>
<script>
import okprogress from "@/components/okProgress";
import cutdown from "@/components/cutdown";
import { Message } from "element-ui";
let myChart;
export default {
  name: "index",
  components: {
    okprogress,
    cutdown,
  },
  data() {
    return {
      //topList
      topList: [],
      list: [
        { color: "#6FCAA0", value: "12,000,000", title: "chubeijin" },
        { color: "#C6F3DD", value: "9,000,000", title: "shequeDAO" },
        { color: "#35D395", value: "6,500,000", title: "tuandui" },
        { color: "#8AE069", value: "5,500,000", title: "liudongxingLP" },
        { color: "#319692", value: "3,500,000", title: "rengoujiangli" },
      ],
      tab: 1,
      langObj: {
        chubeijin: this.$t("lang.chubeijin"),
        shequeDAO: this.$t("lang.shequeDAO"),
        tuandui: this.$t("lang.tuandui"),
        liudongxingLP: this.$t("lang.liudongxingLP"),
        rengoujiangli: this.$t("lang.rengoujiangli"),
      },
      pcecharts: null,
      echartOptions: {}
    };
  },
  watch: {
    "$i18n.locale"() {
      if(this.pcecharts) {
        this.pcecharts.clear();
        this.pcecharts.setOption({
        series: [
          {
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              position: "outer",
              alignTo: "none",
              bleedMargin: 5,
              formatter: "{d|{d}%}\n{b|{b}} ",
              rich: {
                d: {
                  color: "#6FCAA0",
                  fontSize: 13,
                  align: "center",
                  lineHeight: 20
                },
                b: {
                  color: "#26493E",
                  fontSize: 15,
                  fontWeight: "bold",
                  align: "center",
                  lineHeight: 20
                },
              },
              extraCssText: 'white-space: pre-wrap'
            },
            labelLine: {
              length: 20,
              length2: 60,
            },
            data: [
              {
                value: 12000,
                name: this.$t("lang.chubeijin"),
                itemStyle: { color: "#007C77" },
              },
              { value: 9000, name: this.$t("lang.shequeDAO"), itemStyle: { color: "#6FCAA0" } },
              { value: 6500, name: this.$t("lang.tuandui"), itemStyle: { color: "#8AE069" } },
              {
                value: 5500,
                name: this.$t("lang.liudongxingLP"),
                itemStyle: { color: "#35D395" },
              },
              {
                value: 3500,
                name: this.$t("lang.rengoujiangli"),
                itemStyle: { color: "#C6F3DD" },
              },
            ],
          },
        ],
      });
      }
    }
  },
  methods: {
    initPie() {
      myChart = this.$echarts.init(document.getElementById("myChart"));
      myChart.setOption({
        series: [
          {
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              position: "outer",
              alignTo: "none",
              bleedMargin: 5,
              formatter: "{d|{d}%}\n{b|{b}} ",
              rich: {
                d: {
                  color: "#6FCAA0",
                  fontSize: 13,
                  align: "center",
                  lineHeight: 20
                },
                b: {
                  color: "#26493E",
                  fontSize: 15,
                  fontWeight: "bold",
                  align: "center",
                  lineHeight: 20
                },
              },
            },
            labelLine: {
              length: 20,
              length2: 60,
            },
            data: [
              {
                value: 12000,
                name: this.$t("lang.chubeijin"),
                itemStyle: { color: "#007C77" },
              },
              { value: 9000, name: this.$t("lang.shequeDAO"), itemStyle: { color: "#6FCAA0" } },
              { value: 6500, name: this.$t("lang.tuandui"), itemStyle: { color: "#8AE069" } },
              {
                value: 5500,
                name: this.$t("lang.liudongxingLP"),
                itemStyle: { color: "#35D395" },
              },
              {
                value: 3500,
                name: this.$t("lang.rengoujiangli"),
                itemStyle: { color: "#C6F3DD" },
              },
            ],
          },
        ],
      });
      setTimeout(function (){
        window.onresize = function () {
          myChart.resize();
        }
      },200)
      this.pcecharts = myChart;
    },
  },
  mounted() {
    this.initPie();
    
  },
};
</script>

<style scoped >
.banner_view {
  width: 100%;
  height: 30rem;
  border-radius: 0.5rem;
  background: url("./../../assets/image/banner.png") no-repeat;
  background-size: 100% auto;
  background-color: rgba(255, 255, 255, 0.9);
  padding-left: 5.65rem;
  padding-top: 5.25rem;
  box-sizing: border-box;
}
.banner_title {
  font-size: 2.75rem;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #26493e;
  margin-bottom: 1.05rem;
}
.banner_text1 {
  font-size: 1.5rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #26493e;
  margin-bottom: 1.05rem;
}
.banner_text2 {
  font-size: 0.7rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9a9a9a;
  margin-bottom: 2.85rem;
  width: 90%;
  /* word-break: break-all; */
}
.banner_join {
  width: 10rem;
  height: 3rem;
  cursor: pointer;
  background: #6FCAA0;
  border-radius: 3rem;
  line-height: 3rem;
  text-align: center;
  font-size: 1.2rem;
  color: #fff;
}
.banner_join:hover {
  background: #8DDDB8;
}


.chart_view,
.road_view {
  width: 100%;
  height: 30rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 0.5rem;
  padding-top: 2.5rem;
  box-sizing: border-box;
  margin-top: 2.5rem;
}
.chart_title,
.road_title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #26493e;
  text-align: center;
}
.chart_box {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 4.9rem;
}
.chart_content {
  flex: 3;
  height: 16.05rem;
  /* margin-right: 10.7rem; */
}
.chart_info {
  flex: 1;
}
.chart_total {
  margin-bottom: 1rem;
}
.chart_total img {
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
}
.chart_total_info {
  display: flex;
  align-items: center;
}
.chart_total_info p {
  font-size: 1.5rem;
  color: #6fcaa0;
  font-weight: bold;
}
.chart_total_info_title {
  font-size: 0.7rem;
  color: #26493e;
  padding-left: 2rem;
  font-weight: 600;
}
.chart_item_info {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.chart_item_color {
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  margin-right: 1.5rem;
}
.chart_item_value {
  font-size: 0.6rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #6fcaa0;
}
.chart_item_title {
  font-size: 0.6rem;
  color: #26493e;
  font-weight: 600;
}

.road_view {
  height: auto;
}
.road_line_bottom_img {
  width: 100%;
  margin-bottom: -5px;
}

</style>
