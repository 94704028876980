<template>
  <!-- 首页 -->
  <div class="index">
    <div style="height: 20px"></div>
    <div class="top_banner">
      <div class="banner_top">
        <img src="./../../assets/image/banner.png" alt="">
        <div class="banner_top_text">
          <p style="margin-bottom:5px">umiDAO</p>
          <p>{{$t("lang.quanqiulingxian")}}</p>
        </div>
      </div>
      <p class="top_banner_text">{{$t("lang.zhuanzhuyu")}}</p>
      <div class="banner_btn_view">
        <div class="" @click="$router.push('/h5purchase')">{{$t("lang.canyurengou")}}</div>
      </div>
    </div>
    <div class="chart_view">
      <p class="card_title">{{$t("lang.daibifenpei")}}</p>
      <div class="chart_coin_title">
        <img src="./../../assets/image/nft.png" alt="">
        <p>{{$t("lang.zongfaxingliang")}}：36,500,000</p>
      </div>
      <div class="chart_content" id="myCharth5"></div>
      <div class="chart_info_item" v-for="(item, key) in list" :key="key">
        <div class="chart_info_item_icon" :style="{ background: item.color }"></div>
        <p>{{langObj[item.title]}}</p>
        <p>{{item.value}}</p>
      </div>
    </div> 
    <!-- 路线 -->
    <div class="road_view" style="padding: 18px 14px">
      <p class="road_view_title" style="padding: 0 6px">{{$t("lang.luxiantu")}}</p>
      <img src="./../../assets/image/roadImg_h5.png" alt="" class="road_line_bottom_img">
    </div>
  </div>
</template>

<script>
import API from "@/assets/js/API";
import Vue from 'vue';
import { Message } from 'element-ui';
export default {
  name: "index",
  data() {
    return {
      topList: [],
      list: [
        { color: "#6FCAA0", value: "12,000,000", title: "chubeijin" },
        { color: "#C6F3DD", value: "9,000,000", title: "shequeDAO" },
        { color: "#35D395", value: "6,500,000", title: "tuandui" },
        { color: "#8AE069", value: "5,500,000", title: "liudongxingLP" },
        { color: "#319692", value: "3,500,000", title: "rengoujiangli" },
      ],
      tab: 1,
      langObj: {
        chubeijin: 'Reserve Fund',
        shequeDAO: 'DAO        Treasury',
        tuandui: 'Team       Incentive',
        liudongxingLP: 'Liquidity LP',
        rengoujiangli: 'Purchase  Rewards',
      },
      pcecharts: null,
      echartOptions: {}
    };
  },
  computed: {
    
  },
  watch: {
    "$i18n.locale"() {
      if(this.pcecharts) {
        this.pcecharts.clear();
        this.pcecharts.setOption({
        series: [
          {
            type: "pie",
            radius: ["35%", "50%"],
            avoidLabelOverlap: false,
            label: {
              position: "outer",
              alignTo: "none",
              bleedMargin: 5,
              formatter(v) {
                let text = v.name;
                if(text.length > 9) {
                  text = `${text.slice(0,10)}\n${text.slice(10,text.length)}`
                }
                return `{d|${v.percent}%}\n{b|${text}}`
              },
              rich: {
                d: {
                  color: "#6FCAA0",
                  fontSize: 12,
                  align: "center",
                  lineHeight: 14,
                  
                },
                b: {
                  color: "#26493E",
                  fontSize: 13,
                  lineHeight: 14,
                  align: "left",
                },
              },
            },
            labelLine: {
              length: 8,
              length2: 17,
            },
            data: [
              {
                value: 12000,
                name: 'Reserve   Fund',
                itemStyle: { color: "#007C77" },
              },
              { value: 9000, name: 'DAO        Treasury', itemStyle: { color: "#6FCAA0" } },
              { value: 6500, name: 'Team       Incentive', itemStyle: { color: "#8AE069" } },
              {
                value: 5500,
                name: 'Liquidity LP',
                itemStyle: { color: "#35D395" },
              },
              {
                value: 3500,
                name: 'Purchase   Rewards',
                itemStyle: { color: "#C6F3DD" },
              },
            ],
          },
        ],
      });
      }
    }
  },
  methods: {
    initPie() {
      let myChart = this.$echarts.init(document.getElementById("myCharth5"));
      myChart.setOption({
        series: [
          {
            type: "pie",
            radius: ["35%", "50%"],
            avoidLabelOverlap: false,
            label: {
              position: "outer",
              alignTo: "none",
              bleedMargin: 5,
              formatter(v) {
                let text = v.name;
                if(text.length> 9) {
                  text = `${text.slice(0,10)}\n${text.slice(10,text.length)}`
                }
                return `{d|${v.percent}%}\n{b|${text}}`
              },
              rich: {
                d: {
                  color: "#6FCAA0",
                  fontSize: 12,
                  align: "center",
                  lineHeight: 14,
                  
                },
                b: {
                  color: "#26493E",
                  fontSize: 13,
                  lineHeight: 14,
                  align: "left",
                },
              },
            },
            labelLine: {
              length: 8,
              length2: 17,
            },
            data: [
              {
                value: 12000,
                name: 'Reserve   Fund',
                itemStyle: { color: "#007C77" },
              },
              { value: 9000, name: 'DAO        Treasury', itemStyle: { color: "#6FCAA0" } },
              { value: 6500, name: 'Team       Incentive', itemStyle: { color: "#8AE069" } },
              {
                value: 5500,
                name: 'Liquidity LP',
                itemStyle: { color: "#35D395" },
              },
              {
                value: 3500,
                name: 'Purchase   Rewards',
                itemStyle: { color: "#C6F3DD" },
              },
            ],
          },
        ],
      });
      this.pcecharts = myChart
    },
  },
  mounted() {
    this.initPie();
  }
}
</script>

<style scoped >
.top_banner {
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 0px 6px 0px rgba(147,188,175,1);
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
}
.banner_top {
  position: relative;
  margin-top: 20px;
}
.banner_top img  {
  width: 100%;
}
.banner_top_text {
  position: absolute;
  bottom: 8px;
  left: 20px;
}
.banner_top_text p {
  font-size: 17px;
  font-weight: 500;
  color: #26493E;
  line-height: 24px;
  text-align: left;
}
.top_banner_text {
  font-size: 12px;
  font-weight: 400;
  color: #9A9A9A;
  line-height: 16px;
  margin-top: 16px;
  margin-bottom: 29px;
  padding: 0 20px;
  box-sizing: border-box;
}
.banner_btn_view {
  width: 100%;
  padding: 0 30px;
  padding-bottom: 18px;
  box-sizing: border-box;
}
.banner_btn_view div {
  width: 100%;
  height: 48px;
  background: #6FCAA0;
  border-radius: 24px;
  text-align: center;
  line-height: 48px;
  font-size: 19px;
  font-weight: 500;
  color: #FFFFFF;
}
.chart_view,.road_view {
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 0px 6px 0px rgba(147,188,175,1);
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 18px 20px;
  box-sizing: border-box;
}
.chart_view {
  padding: 18px 0;
}
.card_title {
  font-size: 17px;
  font-weight: 500;
  color: #26493E;
  margin-bottom: 15px;
  padding: 0 20px;
}
.chart_coin_title {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 20px;
}
.chart_coin_title img {
  width: 21px;
  height: 21px;
  margin-right: 5px;
}
.chart_coin_title p {
  font-size: 13px;
  font-weight: 400;
  color: #9B9B9B;
}
.chart_content {
  width: 100%;
  height: 230px;
  margin: auto;
  margin-bottom: 30px;
}
.chart_info_item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DFE7ED;
  width: 100%;
  height: 40px;
  padding: 0 20px;
}
.chart_info_item_icon {
  width: 21px;
  height: 21px;
  margin-right: 10px;
  border-radius: 21px;
}
.chart_info_item p {
  font-size: 12px;
  font-weight: 500;
  color: #393939;
  flex: 1;
  text-align: left;
}
.chart_info_item p:last-child {
  font-size: 12px;
  font-weight: 400;
  color: #9B9B9B;
  flex: 1;
  text-align: right;
}
.chart_info_item:last-child {
  border-bottom: none;
}
.road_view {
 
  
}
.road_view_title {
  font-size: 17px;
  font-weight: 500;
  color: #26493E;
  
}
.road_line_bottom_img {
  width: 100%;
  margin-bottom: -10px;
}

</style>
