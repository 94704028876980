import Vue from 'vue'
import Router from 'vue-router'
import home from '@/views/pc/home'
import pcindex from '@/views/pc/index'
import purchase from '@/views/pc/buy'
import stake from '@/views/pc/pledge'
import purchaseDetail from '@/views/pc/buyDetail'
import mynft from '@/views/pc/mynft'
import nftIndex from '@/views/pc/nftIndex'
import castnft from '@/views/pc/castNft'
import dropnft from '@/views/pc/dropNft'

import h5index from '@/views/h5/index'
import h5home from '@/views/h5/home'
import h5purchase from '@/views/h5/buy'
import h5purchaseDetail from '@/views/h5/buyDetail'
import h5stake from '@/views/h5/pledge'
import h5stakeDetail from '@/views/h5/pledgeDetail'
import h5mynft from '@/views/h5/mynft'
import h5nftIndex from '@/views/h5/nftIndex'
import h5castnft from '@/views/h5/castNft'
import h5dropnft from '@/views/h5/dropNft'

Vue.use(Router)

const pageRouter =  new Router({
  routes: [
    {
      path: '/',
      name: 'PC首页',
      component: home,
      redirect: '/home',
      children:[
        {
          path: '/home',
          name: 'pcindex',
          component: pcindex,
        },
        {
          path: '/purchase',
          name: 'purchase',
          component: purchase,
        },
        {
          path: '/stake',
          name: 'stake',
          component: stake,
        },
        {
          path: '/purchaseDetail',
          name: 'purchaseDetail',
          component: purchaseDetail,
        },
        {
          path: '/mynft',
          name: 'mynft',
          component: mynft,
        },
        {
          path: '/nft',
          name: 'nft',
          component: nftIndex,
        },
        {
          path: '/mintnft',
          name: 'mintnft',
          component: castnft,
        },
        {
          path: '/dropnft',
          name: 'dropnft',
          component: dropnft,
        },
       
      ]
    },
    {
      path: '/h5home',
      name: 'H5首页',
      component: h5home,
      children:[
        {
          path: '/',
          name: 'h5index',
          component: h5index,
        },
        {
          path: '/h5purchase',
          name: 'h5purchase',
          component: h5purchase,
        },
        {
          path: '/h5purchaseDetail',
          name: 'h5purchaseDetail',
          component: h5purchaseDetail,
        },
        {
          path: '/h5stake',
          name: 'h5stake',
          component: h5stake,
        },
        {
          path: '/h5stakeDetail',
          name: 'h5stakeDetail',
          component: h5stakeDetail,
        },
        {
          path: '/h5mynft',
          name: 'h5mynft',
          component: h5mynft,
        },
        {
          path: '/h5nft',
          name: 'h5nft',
          component: h5nftIndex,
        },
        {
          path: '/h5mintnft',
          name: 'h5mintnft',
          component: h5castnft,
        },
        {
          path: '/h5dropnft',
          name: 'h5dropnft',
          component: h5dropnft,
        },
      ]
    },
    
  ]
})
pageRouter.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  next()
});
export default pageRouter;
