<template>
  <div>
    <router-view/>  
  </div>
</template>

<script>
import API from "@/assets/js/API";
export default {
  name: "App",
  data() {
    return {
      
    }
  },
  
  mounted() {
    const url = window.location.origin;
    const href = window.location.href;
    const param = href.split('?')[1] || '';
    const hash = window.location.hash;
    if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      
      if(!hash.includes('h5')) {
        window.location.href = `${url}/#/h5home${param ? '?'+param :''}`
      }
      
    } else {
      if(hash.includes('h5')) {
        window.location.href = `${url}/#/home${param ? '?'+param :''}`
      } else {
        
      }
     
    }
    
    this.$store.dispatch("getPrice")

  },
  methods: {
    // getContarctaddr() {
    //   this.$axios.get(`${API.httpIp}/api/pool/contract-address`)
    //   .then(res=>{
    //     // console.log('合约地址',res)
    //     const data = res.data;
    //     if(data.code == 200) {
    //       this.$store.commit('setContract',data.data.address);
    //     }
    //   })
    // }
    
  }
};
</script>

<style>

</style>
