<template>
  <div class="nft_page">
    <div class="nftbanner_view">
      <img src="./../../assets/image/nftbanner_phone.png" alt="" class="nft_banner">
      <p class="nft_banner_text">{{$t("lang.nftbannertxt")}}</p>
    </div>
    <!-- 盲盒 -->
    <div class="manghe_view">
      <p class="manghe_title">NEZHA ORIENTAL</p>
      <p class="manghe_title1">{{$t("lang.tongguozhuzao")}}</p>

      <p class="nft_item_title">{{$t("lang.zhuzao")}}</p>
      <p class="nft_item_price"><span>8000</span></p>
      <p class="nft_item_text">{{$t("lang.meixiaochannft")}}</p>
      <div class="nft_item_btn" @click="goPage('h5mintnft')">{{$t("lang.kaishizhuzao")}}</div>

      <p class="nft_item_title">{{$t("lang.kongtou")}}</p>
      <p class="nft_item_price"><span>1000</span></p>
      <p class="nft_item_text">{{$t("lang.rengouzhiyalingqunft")}}</p>
      <div class="nft_item_btn" @click="goPage('h5dropnft')">{{$t("lang.lingqukongtou")}}</div>

      <!-- <div class="manghe_nft">
        <div class="manghe_nft_item">
          <p class="nft_item_title">{{$t("lang.zhuzao")}}</p>
          <p class="nft_item_price"><span>8000</span></p>
          <p class="nft_item_text">{{$t("lang.meixiaochannft")}}</p>
          <div class="nft_item_btn" @click="goPage('mintnft')">{{$t("lang.kaishizhuzao")}}</div>
          <img src="../../assets/image/nftimg1.png" alt="" class="nft_item_img">
        </div>
        <div class="manghe_nft_item">
          <p class="nft_item_title">{{$t("lang.kongtou")}}</p>
          <p class="nft_item_price"><span>1000</span></p>
          <p class="nft_item_text">{{$t("lang.rengouzhiyalingqunft")}}</p>
          <div class="nft_item_btn" @click="goPage('dropnft')">{{$t("lang.lingqukongtou")}}</div>
          <img src="../../assets/image/nftimg2.png" alt="" class="nft_item_img">
        </div>
      </div> -->
    </div>
    <!-- 关于 -->
    <div class="introduce_view">
      <div class="introduce_rule_view">
        <div class="introduce_title title_nezha">
          <span></span>
          <p>{{$t("lang.fenpeizhuzaoguize")}}</p>
        </div>
        <p class="introduce_text text_nezha">
          {{$t("lang.nezhaoriental")}}<br/><br/>
          {{$t("lang.tuanduibaoliu")}}：{{$t("lang.kaifatuandui")}}<br/>
          {{$t("lang.shequkongtou")}}：{{$t("lang.rengouzhiyabaimingdan")}}<br/>
          {{$t("lang.zhuzaofashou")}}：{{$t("lang.8000mint")}}<br/><br/>

          {{$t("lang.zhuzaoguize")}}：<br/>
          {{$t("lang.8000manghe")}}
        </p>
      </div>

      <div class="introduce_title title_nezha">
        <span></span>
        <p>{{$t("lang.luxiantu")}}</p>
      </div>
      <div class="introduce_road_view">
        <!-- <p class="introduce_text" style="margin-left:2.6rem">
          {{$t("lang.womenjiangchangqi")}}<br/><br/>
          {{$t("lang.womenweinezha")}}
        </p> -->
        <div class="nft_road_content">
          <div class="nft_road_border"></div>
          <div class="road_item">
            <div class="left_road">
              <div class="left_road_border"><p></p><p></p></div>
              <p class="road_title">10% SALES</p>
              <p class="road_value">{{$t("lang.xianshanghualang")}}</p>
            </div>
            <div class="road_item50"></div>
          </div>
          <div class="road_item">
            <div class="road_item50"></div>
            <div class="right_road">
              <div class="right_road_border"><p></p><p></p></div>
              <p class="road_title">30% SALES</p>
              <p class="road_value">{{$t("lang.nezhachaowan")}}</p>
            </div>
          </div>
          <div class="road_item">
            <div class="left_road">
              <div class="left_road_border"><p></p><p></p></div>
              <p class="road_title">40% SALES</p>
              <p class="road_value">{{$t("lang.xianxiaip")}}</p>
            </div>
            <div class="road_item50"></div>
          </div>
          <div class="road_item">
            <div class="road_item50"></div>
            <div class="right_road">
              <div class="right_road_border"><p></p><p></p></div>
              <p class="road_title">50% SALES</p>
              <p class="road_value">{{$t("lang.nezhadujia")}}</p>
            </div>
          </div>
          <div class="road_item">
            <div class="left_road">
              <div class="left_road_border"><p></p><p></p></div>
              <p class="road_title">60% SALES</p>
              <p class="road_value">{{$t("lang.goumaixuni")}}</p>
            </div>
            <div class="road_item50"></div>
          </div>
          <div class="road_item">
            <div class="road_item50"></div>
            <div class="right_road">
              <div class="right_road_border"><p></p><p></p></div>
              <p class="road_title">60% SALES</p>
              <p class="road_value">{{$t("lang.xianxiashuzi")}}</p>
            </div>
          </div>
          <div class="road_item">
            <div class="left_road">
              <div class="left_road_border"><p></p><p></p></div>
              <p class="road_title">80% SALES</p>
              <p class="road_value">{{$t("lang.OpenSea")}}</p>
            </div>
            <div class="road_item50"></div>
          </div>
          <div class="road_item">
            <div class="road_item50"></div>
            <div class="right_road" style="margin-bottom: 1.7rem">
              <div class="right_road_border"><p></p><p></p></div>
              <p class="road_title">100% SALES</p>
              <p class="road_value">{{$t("lang.qidianjuhui")}}</p>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>
<script>

import PcModal from '../../components/PcModal.vue'
// 交易hash
export default {
  name: "detail",
  components: {
    PcModal
  },
  data() {
    return {
      
    };
  },
  computed: {
    
  },
  methods: {
    goPage(route) {
      if(!this.$store.state.userAddress) {
        this.$emit('openLinkWallet');
        return 
      }
      // if(route == "castnft" && !this.$store.state.castCountTime) {
      //   this.$router.push('/commingOpenNft')
      //   return 
      // }
      this.$router.push(route)
    }
  },
  mounted() {
    
  }
};
</script>

<style scoped >
  .nftbanner_view {
    width: 100%;
    position: relative;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 42px;
  }
  .nft_banner {
    width: 100%;
    height: auto;
    display: block;
  }
  .nft_banner_text {
    position: absolute;
    left: 15px;
    top: 60px;
    width: calc(100% - 18px - 15px);
    font-size: 6px;
    color: #FFFFFF;
  }
  /* 盲盒 */
  .manghe_view {
    width: 100%;
    background: url('../../assets/image/manghebg_phone.png') no-repeat;
    background-size:  auto 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 38px;
  }
  .manghe_title {
    font-size: 19px;
    font-weight: 600;
    color: #4A4A4A;
    text-align: center;
    margin-bottom: 8px;
  }
  .manghe_title1 {
    font-size: 10px;
    color: #4A4A4A;
    text-align: center;
    padding: 0 10px;
    margin-bottom: 43px;
  }
  
  .nft_item_title {
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #4A4A4A;
    margin-bottom: 5px;
  }
  .nft_item_price {
    font-size: 24px;
    font-weight: 500;
    color: #4A4A4A;
    margin-bottom: 5px;
  }
  .nft_item_price span {
    font-size: 3rem;
  }
  .nft_item_text {
    font-size: 10px;
    font-weight: 500;
    color: #4A4A4A;
    margin-bottom: 34px;
  }
  .nft_item_btn {
    width: 254px;
    height: 48px;
    background: #4A4A4A;
    box-shadow: 0px 0px 23px 0px rgba(111,202,160,0.4);
    border-radius: 63px;
    text-align: center;
    line-height: 48px;
    font-size: 19px;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 24px;
  }

  
  /* 关于 */
  .introduce_view {
    width: 100%;
    background: url('../../assets/image/nftinterducebg_phone.png') no-repeat;
    background-size: 100% auto;
    
  }
  .introduce_rule_view {
    height: 484px;
    background: url('./../../assets/image/nftrulebg_phone.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 90px;
    margin-bottom: 48px;
  }
  .introduce_title {
    display: flex;
    align-items: center;
    margin-bottom: 38px;
  }
  .introduce_title span {
    width: 6px;
    height: 27px;
    background: #4A4A4A;
    margin-right: 10px;
  }
  .introduce_title p {
    font-size: 19px;
    font-weight: 500;
    color: #26493E;
  }
  .title_nezha {
    margin-left: 49px;
  }

  .introduce_text {
    font-size: 10px;
    font-weight: 600;
    color: #4A4A4A;
    line-height: 19px;
  }
  .text_nezha {
    width: 230px;
    margin: 0 auto;
    margin-bottom: 98px;
  }

  .introduce_road_view {
    margin: 0 auto;
  }
  .introduce_road_view img {
    width: 36.5rem;
    height: auto;
    display: block;
    margin: 0 auto;
    margin-top: 5.95rem;
  }

  .introduce_flex {
    display: flex;
    align-items: center;
  }
  .introduce_flex_img {
    width: 33.55rem;
    height: 33.55rem;
    padding: 2rem;
    background: #FFFFFF;
    border-radius: 1rem;
  }
  .introduce_flex_img img {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
  }

  /* 路线图 */
  .nft_road_content {
    margin: 0 auto;
    position: relative;
    padding-top: 40px;
    padding-bottom: 100px;
    margin-bottom: 50px;
  }
  .nft_road_border  {
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -2px;
    width: 4px;
    height: 100%;
    background: linear-gradient(rgba(145,255,203,1),rgba(145,255,203,0.2));
  }

  .road_item {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .road_item50 {
    width: 50%;
    flex-shrink: 0;
  }

  .left_road {
    width: 50%;
    text-align: right;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .left_road p {
    padding-right: 10px;
  }
  .left_road_border {
    display: flex;
    align-items: center;
    margin-bottom: 10px; 
  }
  .left_road_border p:first-child {
    width: 12px;
    height: 12px;
    background: #8EFFCB;
    border-radius: 100%;
  }
  .left_road_border p:last-child {
    width: 20px;
    height: 4px;
    background: #8EFFCB;
  }

  .right_road {
    width: 50%;
    text-align: left;
    position: relative;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
  }
  .right_road p {
    padding-left: 10px;
  }
  .right_road_border {
    display: flex;
    align-items: center; 
    margin-bottom: 10px;
  }
  .right_road_border p:first-child {
    width: 20px;
    height: 4px;
    background: #8EFFCB;
  }
  .right_road_border p:last-child {
    width: 12px;
    height: 12px;
    background: #8EFFCB;
    border-radius: 100%;
  }
  .road_title {
    font-size: 19px;
    font-weight: bold;
    color: #4A4A4A;
    margin-bottom: 10px;
  }
  .road_value {
    font-size: 10px;
    font-weight: bold;
    color: #4A4A4A;
  }

</style>