<template>
  <div class="h5Container">
    <div class="header_view">
      <img
        src="./../../assets/image/logo.png"
        class="headerLogo"
        @click="clickTab(0)"
      />
      <div class="headerRight">
        <p :class="tab == 1 && 'selected'" @click="clickTab(1)">
          {{ $t("lang.rengou") }}
        </p>
        <p :class="tab == 2 && 'selected'" @click="clickTab(2)">
          {{ $t("lang.zhiya") }}
        </p>
        <p :class="tab == 3 && 'selected'" @click="clickTab(3)">NFT</p>
      </div>
    </div>
    <div class="router_view">
      <router-view @openLinkWallet="openDialog" />
      <div class="bottom_link">
        <div class="link_img">
          <a href="https://twitter.com/umiDAOHQ" target="_blank"
            ><img src="./../../assets/image/h5twitter.png" alt=""
          /></a>
          <a href="https://github.com/umidao/" target="_blank"
            ><img src="./../../assets/image/h5githup.png" alt=""
          /></a>
          <a href="https://discord.gg/gfug4sTDqB" target="_blank"
            ><img src="./../../assets/image/h5discord.png" alt=""
          /></a>
          <a href="https://umidao.medium.com" target="_blank"
            ><img src="./../../assets/image/h5face.png" alt=""
          /></a>
          <a href="https://doc.umidao.com" target="_blank"
            ><img src="./../../assets/image/h5doc.png" alt=""
          /></a>
        </div>
        <p>© 2022 umidao.com All rights reserved.</p>
      </div>
    </div>

    <div class="footer_bottom">
      <a href="https://uniswap.la" target="_blank" class="footer_bottom_icon">
        <img src="../../assets/image/uniswap.png" alt="" />
      </a>
      <div class="wallet_view">
        <div
          class="link_wallet"
          v-if="!$store.state.userAddress"
          @click="openDialog"
        >
          <img
            src="./../../assets/image/wallet_icon.png"
            alt=""
            v-if="!connecting"
          />
          <p>{{ connecting ? "loading" : $t("lang.lianjieqianbao") }}</p>
        </div>
        <div
          class="link_wallet"
          v-else
          @click="openDraw"
          style="min-width: 88px"
        >
          {{ $store.state.userAddress.slice(0, 4) }}...{{
            $store.state.userAddress.slice(-4)
          }}
        </div>
        <span class="copy_phone" @click="openInvite"></span>
      </div>
      <div>
        <div
          class="footer_bottom_icon"
          :style="showLang && { background: '#D8F3E6' }"
        >
          <img
            src="../../assets/image/lang.png"
            alt=""
            @click="showLang = !showLang"
          />
        </div>
        <div
          class="lang_select_bg"
          v-if="showLang"
          @click="showLang = false"
        ></div>
        <div class="lang_select" v-if="showLang">
          <div
            class="lang_select_item"
            v-for="(item, index) in langList"
            :key="index"
            @click="changeLang(item)"
          >
            <p
              :style="{
                fontWeight: item.value === langSelectValue ? 'bold' : '500',
              }"
            >
              {{ item.name }}
            </p>
            <img
              src="./../../assets/image/ok.png"
              alt="ok"
              v-show="item.value === langSelectValue"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 链接钱包弹窗 -->
    <div
      class="connect_wallet_dailog"
      v-if="showConnectWallet"
      @click="closeDialog"
    >
      <div class="connect_wallet">
        <div class="connect_wallet_item">
          <img
            src="./../../assets/image/fox.png"
            alt="fox"
            @click="linkWallet('MetaMask')"
          />
          <p class="connect_wallet_text1">MetaMask</p>
          <p class="connect_wallet_text2">{{ $t("lang.lianjieMetamask") }}</p>
        </div>
        <!-- <div class="connect_wallet_item">
          <img src="./../../assets/image/wallet.png" alt="fox" @click="linkWallet('WalletConnect')">
          <p class="connect_wallet_text1">WalletConnect</p>
          <p class="connect_wallet_text2">{{$t("lang.lianjieWalletConnect")}}</p>
        </div> -->
      </div>
    </div>

    <div class="wallet_info_draw" v-show="showDraw" @click="clickDrwaBg">
      <div class="draw_view">
        <p class="draw_title">{{ $t("lang.wodeqianbao") }}</p>
        <p class="draw_dollar"></p>
        <div class="draw_coin">
          <div class="draw_coin_icon">
            <img src="./../../assets/image/coin_UMI.png" alt="" />
          </div>
          <p class="draw_coin_name">UMI</p>
          <div class="draw_coin_value">
            <p>
              {{ ($store.state.balance.UMIBalance * 1).toFixed(3) || "0.00" }}
            </p>
            <!-- <p>${{($store.state.balance.UMIBalance * $store.state.UMI_USDC).toFixed(3)}}</p> -->
          </div>
        </div>
        <div class="draw_coin">
          <div class="draw_coin_icon">
            <img src="./../../assets/image/coin_USDC.png" alt="" />
          </div>
          <p class="draw_coin_name">USDC</p>
          <div class="draw_coin_value">
            <p>
              {{ ($store.state.balance.USDCBalance * 1).toFixed(3) || "0.000" }}
            </p>
            <!-- <p>${{($store.state.balance.USDCBalance*1).toFixed(3)}}</p> -->
          </div>
        </div>
        <div class="draw_coin">
          <div class="draw_coin_icon">
            <img src="./../../assets/image/coin_ETH.png" alt="" />
          </div>
          <p class="draw_coin_name">ETH</p>
          <div class="draw_coin_value">
            <p>
              {{ ($store.state.balance.ETHBalance * 1).toFixed(3) || "0.000" }}
            </p>
            <!-- <p>${{($store.state.balance.ETHBalance && $store.state.USDC_ETH) ? ($store.state.balance.ETHBalance / $store.state.USDC_ETH).toFixed(3): '0.000'}}</p> -->
          </div>
          <div class="add_wallet"></div>
        </div>
        <div class="draw_coin">
          <div class="draw_coin_icon">
            <img src="./../../assets/image/coin_LP.png" alt="" />
          </div>
          <p class="draw_coin_name">UMI-USDC LP</p>
          <div class="draw_coin_value">
            <p>{{ $store.state.balance.LPBalacnce || "0" }}</p>
            <p></p>
          </div>
          <div class="add_wallet"></div>
        </div>
        <div class="my_nft_btn" @click="goNft">
          <p>{{ $t("lang.wodeNFT") }}</p>
          <img src="./../../assets/image/right_white.png" alt="right" />
        </div>
        <div style="height: 5rem"></div>
      </div>
      <!-- close -->
      <div class="close_draw" @click="closeDraw">
        <img src="./../../assets/image/close.png" alt="" />
      </div>
      <!-- disconnect -->
      <div class="draw_disconnect" @click="disconnect">
        {{ $t("lang.duankailianjie") }}
      </div>
    </div>

    <H5Modal v-if="modalStatus" @closeModal="closeModal" :title="modalTitle">
      <!-- <p>Switch to the Ethereum NetWork</p> -->
      <div slot="content" v-if="modalType == 1">
        <p class="switchNetwork_text">{{ $t("lang.qingqiehuanwang") }}</p>
        <p class="switchNetwork_btn" @click="changeNetwork">
          {{ $t("lang.qiehuanwangluo") }}
        </p>
      </div>
      <div slot="content" v-if="modalType == 2">
        <p class="switchNetwork_text">连接请求被拒绝</p>
        <p class="switchNetwork_btn" @click="closeModal">关闭</p>
      </div>
      <div slot="content" v-if="modalType == 3">
        <div class="copyContent_phone">
          <img
            src="../../assets/image/inviteperson.png"
            alt=""
            class="invitePerson"
          />
          <div>
            <span>https://umidao.com/#/h5home?from={{ this.dealAddr() }}</span>
            <img
              src="../../assets/image/copybtn.png"
              alt=""
              @click="copyUrl"
              class="copybtn"
            />
          </div>
        </div>
        <p
          class="switchNetwork_btn"
          @click="copyUrl"
        >
          {{ $t("lang.fuzhi") }}
        </p>
      </div>
    </H5Modal>

    <!-- 没有钱包环境的弹窗 -->
    <div class="noWalletView" v-show="noWallet" @click="noWallet=false">
      <div class="noWalletContent">
        <p class="noWalletTitle">{{$t("lang.dakaiqianbao")}}</p>
        <div class="noWalletImg">
          <img src="./../../assets/image/fox.png" alt="">
          <img src="./../../assets/image/imtoken.png" alt="">
          <img src="./../../assets/image/TP.png" alt="">
        </div>
        <p class="noWalletTips">{{$t("lang.lianjiefuzhi")}}</p>
      </div>
    </div> 
  </div>
</template>

<script>
import Vue from "vue";
import Web3 from "web3";
import H5Modal from "@/components/H5Modal.vue";
export default {
  name: "h5home",
  components: {
    H5Modal,
  },
  data() {
    return {
      tab: 1, // 控制顶部导航选中状态
      showMenu: false,
      showDialog: false,
      langList: [
        { name: "English", value: "en" },
        { name: "Dansk", value: "dm" },
        { name: "Español", value: "xby" },
        { name: "Italiano", value: "ydl" },
        { name: "简体中文", value: "zh" },
        { name: "日本语", value: "jp" },
        { name: "한국어", value: "ko" },
        { name: "Português", value: "pty" },
        { name: "Pусский", value: "e" },
      ],
      langSelectValue: "",
      showLang: false,

      showConnectWallet: false, // 连接钱包弹窗
      showDraw: false, // 右侧个人中心
      modalStatus: false, // 弹窗显示
      modalType: 1, // 弹窗内容类型
      modalTitle: "", // 弹窗标题
      connecting: false, // 连接钱包loading

      noWallet: false, // 无钱包弹窗
    };
  },
  created() {
    this.langSelectValue = this.$i18n.locale;
  },
  watch: {
    showLang(val) {
      if (val) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    showDraw(val) {
      if (val) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    "$route.path"() {
      const path = this.$route.path;
      if (path == "/h5purchase") {
        this.tab = 1;
      }
      if (path == "/h5stake") {
        this.tab = 2;
      }
      if (path == "/h5nft") {
        this.tab = 3;
      }
    },
    showConnectWallet() {
      if (!this.showConnectWallet) {
        this.connecting = false;
      }
    },
  },
  mounted() {
    this.tabToRoute();
    this.linkWallet("MetaMask");
  },
  methods: {
    dealAddr() {
      const addr = this.$store.state.userAddress;
      const str = addr.slice(2,addr.length)
      if(!addr) {
        return ''
      } 
      return '0x'+str.slice(0,4)+str.slice(-4)
    },
    openInvite() {
      if (!this.$store.state.userAddress) {
        this.$emit("openLinkWallet");
        return;
      }
      this.modalTitle = this.$t("lang.yaoqinglianjie");
      this.modalStatus = true;
      this.modalType = 3;
    },
    copyUrl() {
      const Url2 = `https://umidao.com/#/h5home?from=${this.dealAddr()}`;
      var input = document.createElement("input"); // 创建input标签，只有input标签选中可以
      input.setAttribute("id", "copyInput"); // 给input一个id属性
      input.setAttribute("value", Url2); // 给input一个value属性，属性值是变量span的文本
      document.getElementsByTagName("body")[0].appendChild(input); // body里面添加input实体
      document.getElementById("copyInput").select(); // 使用js去通过id找到并执行input实体的全部选中
      document.execCommand("Copy"); //原生copy方法执行浏览器复制命令
      document.getElementById("copyInput").remove(); // 为避免下次页面添加copy方法 所以这里copy完之后要移除input实体
      this.$message({
        type: "success",
        message: this.$t("lang.fuzhichenggong"),
      });
    },

    tabToRoute() {
      const routeName = this.$route.name;
      if (routeName.includes("purchase")) {
        this.footerBg = true;
        this.tab = 1;
      } else if (routeName.includes("stake")) {
        this.footerBg = true;
        this.tab = 2;
      } else if (routeName.includes("mynft")) {
        this.tab = 4;
      } else if (routeName.includes("nft")) {
        this.tab = 3;
      } else {
        this.footerBg = true;
        this.tab = 0;
      }
    },
    clickTab(val) {
      this.tab = val;
      this.closeDraw(); // 关闭 user draw
      if (val == 1) {
        this.$router.replace("/h5purchase");
      } else if (val == 2) {
        this.$router.replace("/h5stake");
      } else if (val == 3) {
        this.$router.replace("/h5nft");
      } else if (val == 4) {
        this.$router.replace("/h5mynft");
        this.footerBg = false;
      } else {
        this.$router.replace("/h5home");
      }
    },
    openMenu() {
      this.showMenu = true;
    },
    closeMenu(e) {
      if (e.target.className == "draw_menu_view") {
        this.showMenu = false;
      }
    },
    goPage(route, value) {
      this.showMenu = false;
      if (value) {
        this.$router.push({
          path: route,
          query: { type: value },
        });
        return;
      }
      this.$router.push(route);
    },
    changeLang(item) {
      this.langSelectValue = item.value;
      this.$i18n.locale = item.value;
      this.showLang = false;
    },
    closeModal() {
      this.modalTitle = "";
      this.modalStatus = false;
    },
    changeNetwork() {
      this.$utils.switchNetwork(this.$api.chainId);
    },
    goNft() {
      this.tab = 0;
      this.$router.push("/h5mynft");
      this.closeDraw();
    },
    closeDialog(e) {
      if (e.target.className == "connect_wallet_dailog") {
        this.showConnectWallet = false;
      }
    },
    openDialog() {
      if(!window.ethereum) {
          // this.showConnectWallet = false;
          this.noWallet = true;
          // 弹窗提示 安装 钱包
          // this.$alert("No MateMask!")
          return 
        }
      this.showConnectWallet = true;
    },
    openDraw() {
      this.showDraw = true;
    },
    closeDraw() {
      this.showDraw = false;
    },
    clickDrwaBg(e) {
      if (e.target.className == "wallet_info_draw") {
        this.showDraw = false;
      }
    },
    disconnect() {
      this.showConnectWallet = false;
      this.address = "";
      sessionStorage.clear();
      this.$store.commit("setUseraddress", null);
      this.closeDraw();
    },
    linkWallet(type) {
      if (type == "MetaMask") {
        if (!window.ethereum) {
          this.showConnectWallet = false;
          this.noWallet = true;
          // 弹窗提示 安装 钱包
          // this.$alert("No MateMask!")
          return;
        }
      }
      this.connecting = true;
      this.$utils.linkWallet({
        type,
        successFn: async (data) => {
          // this.address = data[0];

          this.showConnectWallet = false;
          const web3Object = new Web3(window.ethereum);
          const chainId = await web3Object.eth.getChainId();
          if (chainId != this.$api.chainId) {
            this.showConnectWallet = false;
            this.connecting = false;
            if (type == "MetaMask") {
              this.modalType = 1;
              this.modalTitle = this.$t("lang.wanluocuowu");
              this.modalStatus = true;
            } else {
              this.$alert("Please switch wallet network", "Network Error!");
            }
            return;
          }

          Vue.prototype.web3js = web3Object;
          this.showConnectWallet = false;
          sessionStorage.setItem("walletType", type);
          const params = {
            web3js: web3Object,
            userAddr: data[0],
          };
          this.$store.commit("setUseraddress", data[0]);
          this.$store.dispatch("initContartInfo", params);
          this.connecting = false;
          // sessionStorage.getItem('',)
        },
        errorFn: () => {
          // Message
          this.showConnectWallet = false;
          this.modalType = 2;
          this.modalTitle = "连接失败";
          this.modalStatus = true;
          this.connecting = false;
        },
      });
    },
  },
};
</script>

<style scoped >
.h5Container {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(180deg, #69c89d 0%, #ffffff 100%);
  padding: 12px;
  box-sizing: border-box;
}
.header_view {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 13px;
}
.headerLogo {
  width: auto;
  height: 20px;
  flex-shrink: 0;
}
.headerRight {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 500;
  color: #26493e;
}
.headerRight p {
  margin-left: 30px;
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
}
.headerRight p:first-child {
  margin: 0;
}
.headerRight .selected {
  border-bottom: 2px solid #26493e;
}

.lang_select_bg {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.4);
}
.lang_view {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  position: relative;
}
.lang_view .btn {
  width: 100%;
  height: 100%;
}
.lang_select {
  position: fixed;
  bottom: 67px;
  right: 23px;
  width: 150px;
  background: #fff;
  border-radius: 5px;
  padding: 10px 0;
  box-sizing: border-box;
  z-index: 10;
}
.lang_select_item {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 20px;
  box-sizing: border-box;
}
.lang_select_item:active {
  background: #f0f0f0;
}
.lang_select_item p {
  flex: 1;
  color: #26493e;
  font-size: 12px;
}
.lang_select_item img {
  flex-shrink: 0;
  width: 16px;
}

.link_wallet {
  min-width: 115px;
  padding: 0 10px;
  box-sizing: border-box;
  height: 33px;
  border-radius: 61px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  color: #26493e;
}
.link_wallet img {
  width: 15px;
  height: auto;
  margin-right: 10px;
}
.link_wallet p {
  font-size: 12px;
  font-weight: 600;
  color: #26493e;
}
.router_view {
  box-sizing: border-box;
  padding-bottom: 67px;
}
.bottom_link {
  margin-top: 70px;
}
.link_img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}
.link_img a {
  width: 20px;
  margin-right: 19px;
}
.link_img a:last-child {
  margin-right: 0;
}
.link_img img {
  width: 100%;
  height: auto;
}
.bottom_link p {
  color: #93bcaf;
  font-size: 12px;
  text-align: center;
  margin-bottom: 29px;
}
.footer_bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 67px;
  background: #b4e3cd;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer_bottom_icon {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.footer_bottom_icon img {
  width: 20px;
  height: auto;
}
.wallet_view {
  margin: 0 35px;
  width: 173px;
  height: 33px;
  background: #d8f3e6;
  border-radius: 61px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.copy_phone {
  position: absolute;
  right: 0;
  top: 0;
  width: 33px;
  height: 33px;
  background: url("../../assets/image/invite1.png") no-repeat center center /
    13px 16px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.draw_menu_view {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99;
  width: 100%;
  height: 100%;
}
.draw_menu_content {
  width: calc(100% - 75px);
  height: 100%;
  background: linear-gradient(180deg, #7acea7 0%, #ffffff 100%);
  padding-top: 63px;
  box-sizing: border-box;
  overflow-y: auto;
}
.draw_logo {
  width: 135px;
  height: auto;
  margin: 0 auto;
  margin-bottom: 50px;
  display: block;
}
.draw_menu_item {
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  padding-left: 35px;
  box-sizing: border-box;
}
.draw_menu_item:active {
  background: #6fcaa0;
}
.draw_menu_item img {
  width: 20px;
  height: auto;
  margin-right: 16px;
}
.draw_menu_item p {
  font-size: 17px;
  font-weight: 500;
  color: #26493e;
}
.draw_menu_item_son {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 71px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.draw_menu_item_son:active {
  background: #6fcaa0;
}
.draw_menu_item_son p {
  font-size: 13px;
  font-weight: 400;
  color: #26493e;
}
.draw_menu_item_son p:first-child {
  margin-right: 25px;
}
.draw_menu_link_view {
  position: absolute;
  bottom: 50px;
  left: 0;
  width: calc(100% - 75px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.draw_menu_link_view img {
  width: auto;
  height: 21px;
  margin-right: 25px;
}
.draw_menu_link_view img:last-child {
  margin-right: 0;
}

.connect_wallet_dailog {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 38px;
  box-sizing: border-box;
}
.connect_wallet {
  width: 100%;
  height: 240px;
  background: #ffffff;
  border-radius: 19px;
  display: flex;
  align-items: center;
}
.connect_wallet_item {
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* .connect_wallet_item:first-child {
  border-bottom: 1px solid #E1EDE8;
  box-sizing: border-box;
} */
.connect_wallet_item img {
  width: 38px;
}
.connect_wallet_text1 {
  font-size: 17px;
  font-weight: 500;
  color: #26493e;
  line-height: 24px;
  margin: 5px 0;
}
.connect_wallet_text2 {
  font-size: 12px;
  font-weight: 500;
  color: #93bcaf;
}

.switchNetwork_text {
  font-size: 13px;
  color: #333;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}
.switchNetwork_btn {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: #6fcaa0;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  margin: auto;
  cursor: pointer;
}

.wallet_info_draw {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.draw_view {
  position: absolute;
  right: 0;
  top: 0;
  width: 80%;
  height: 100%;
  background: #ffffff;
  padding: 0 15px;
  padding-top: 20px;
  padding-bottom: 40px;
  box-sizing: border-box;
  overflow: auto;
}
.draw_title {
  font-size: 17px;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #769991;
  margin-bottom: 10px;
  padding-left: 10px;
}
.draw_dollar {
  font-size: 27px;
  height: 27px;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #26493e;
  margin-bottom: 1.5rem;
}
.draw_coin {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.draw_coin_icon {
  width: 22px;
  height: 22px;
  margin-right: 22px;
}
.draw_coin_icon img {
  height: 22px;
  width: auto;
}
.draw_coin_name {
  text-align: left;
  flex: 1;
  font-size: 13px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #26493e;
}
.draw_coin_value {
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #26493e;
  line-height: 20px;
  text-align: right;
}
.my_nft_btn {
  width: 100%;
  height: 40px;
  background: url("./../../assets/image/btn_bg.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px;
  cursor: pointer;
}
.my_nft_btn p {
  font-size: 13px;
  font-weight: bold;
  color: #fff;
}
.my_nft_btn img {
  width: 12px;
}

.close_draw {
  position: absolute;
  top: 20px;
  right: 17px;
  width: 22px;
  height: 22px;
  cursor: pointer;
}
.close_draw img {
  display: block;
  width: 20px;
  height: 20px;
  margin: 1px;
}
.draw_disconnect {
  position: absolute;
  bottom: 48px;
  left: 20%;
  width: 80%;
  height: 20px;
  font-size: 17px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #9a9a9a;
  text-align: center;
  cursor: pointer;
}

.copyContent_phone {
  width: 100%;
  box-sizing: border-box;
}
.invitePerson {
  width: 67px;
  height: 67px;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
  margin-top: 60px;
}
.copyContent_phone div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  margin-bottom: 26px;
}
.copyContent_phone span {
  font-size: 12px;
  color: #4a4a4a;
  font-weight: 400;
  text-align: left;
  padding: 0;
  margin-right: 16px;
}
.copyContent_phone .copybtn {
  width: 16px;
  height: auto;
  cursor: pointer;
}


.noWalletView {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  box-sizing: border-box;
  z-index: 999;
}
.noWalletContent {
  width: 100%;
  height: 200px;
  background: #FFFFFF;
  border-radius: 19px;
  padding-top: 13px;
  padding-bottom: 31px;
}
.noWalletTitle {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4A4A4A;
  text-align: center;
  margin-bottom: 38px;
  }
.noWalletImg {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 25px;
}
.noWalletImg img {
  width: 58px;
  height: 58px;
}
.noWalletTips {
  font-size: 11px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #93BCAF;
  text-align: center;
  padding: 0 28px;
}
</style>
